export default {
    header: {
        login: '登入',
        Logout: '登出',
        register: '註冊',
        appDownload: 'APP下載',
        headerDow: '下載APP領取贈金',
	    headerDowBtn: '下載',
    },
    loginRegister: {
        // 登入註冊
        loginLeftTitle1: '贈金福利',
        loginLeftTitle2: '最低領＄120，入金交易即返',
        loginRightTitle1: '您是否已有交易賬戶？',
        loginRightTitle2: '註冊一個賬戶',
        loginRightTitle3: '手機驗證碼登入',
        loginRightTitle4: '忘記密碼？',
        loginInput1: '交易賬號 / 郵箱賬號',
        loginInput2: '密碼',
        regLeftTitle1: '簡單幾步，完成開戶',
        regLeftTitle2: '0傭金體驗低點差高效執行的交易平臺',
        loginTitle1:'已註冊 ？',
        loginTitle2:'登錄',
        loginTitle3:'沒有賬號 ？',
        loginTitle4:'註冊',
        loginTitle5:'邀請碼（非必填）',
        loginTitle6:'我同意',
        retrievePassTitle1:'手機重置密碼',
        retrievePassTitle2:'郵箱重置密碼',
        mobileRegRightTitle1: '手機註冊/登入',
        mobileRegRightTitle2: '未註冊的手機將自動創建賬戶',
        moblieInput:'手機號',
        codeInput:'驗證碼',
        getCode:'獲取',
        resend:'重新發送',
        regLogin:'註冊/登入',
        regButtomTitle1:'或使用以下方式創建賬戶',
        regButtomTitle2:'點選註冊/登入即代錶您已同意',
        agreement:'《用戶協議》',
        emailRegRightTitle1: '信箱註冊',
        countryInput:'選擇居住國家/地區',
        emailInput:'郵箱賬號',
        passInput:'6-15位字母數字組合密碼',
        // 註冊成功
        loginSuccessful:'註冊成功',
        loginSuccTitle:'現在入金，可享高額美元贈金',
        loginSuccBtn1:'立刻入金領取',
        loginSuccBtn2:'完成身份認證',
        loginSuccEmail:'妳的信箱已註冊，請前往登入',
        // 忘記密碼
        retrievePassword:'找回密碼',
        retrievePassTab1:'信箱找回密碼',
        retrievePassTab2:'手機號找回密碼',
        resetPassword:'確認',
        resetPasswordTitle:'密碼重置成功，請重新登入',
        returnLogin:'返回登入',
        checkPrompt1:'賬號信箱不能為空',
        checkPrompt2:'密碼不能為空',
        checkPrompt3:'密碼格式不對',
        checkPrompt4:'手機號不能為空',
        checkPrompt25:'請填寫新的手機號',
        checkPrompt5:'手機號格式不對',
        checkPrompt6:'驗證碼不能為空',
        checkPrompt7:'請選擇國家地區',
        checkPrompt27:'請選擇銀行名稱',
        checkPrompt8:'信箱不能為空',
        checkPrompt26:'請填寫新的信箱',
        checkPrompt9:'信箱格式不對',
        checkPrompt10:'當前密碼不能為空',
        checkPrompt11:'當前密碼格式不對',
        checkPrompt12:'新密碼不能為空',
        checkPrompt13:'新密碼格式不對',
        checkPrompt14:'確認密碼格式不對',
        checkPrompt15:'確認密碼不能為空',
        checkPrompt16:'兩次新密碼不一致',
        checkPrompt17:'銀行代碼不能為空',
        checkPrompt18:'分行名稱不能為空',
        checkPrompt19:'銀行名稱不能為空',
        checkPrompt20:'銀行賬號不能為空',
        checkPrompt21:'銀行賬單不能為空',
        checkPrompt22:'昵稱不能為空',
        checkPrompt23:'身份證正面照片不能為空',
        checkPrompt24:'身份證反面照片不能為空',
    },
    perfectData: {
        // 完善資料
        perfectDataLeftTitle1:'激活真實賬戶',
        perfectDataLeftTitle2:'應監管要求，需完成身份認證方可進行出金操作',
        perfectData:'完善資料',
        step1Title1:'填寫個人基礎信息填寫',
        identityAuthentication:'身份認證',
        step2Title1:'根據監管要求，妳需完成以下認證：',
        step2Title2:'①身份驗證',
        step2Title3:'②添加銀行卡',
        step2Title4:'身份認證',
        step3Title1:'入金享贈金',
        step3Title2:'最高可獲得USD2400贈金',
        step3Title3:'現在入金，領取豐厚贈金獎勵',
        step3Title4:'居住國家',
        perfectDataTips:'我的現居國家/地區，國籍以及納稅國是相同的',
        nextStep:'下一步',
        //身份認證
        identityAuthenTitle1:'應監管要求，激活入金和交易功能需完成身份認證',
        identityAuthenTitle2:'點選上載身份證正面照',
        identityAuthenTitle3:'點選上載身份證反面照',
        // 個人信息確認
        informationConfirmTitle1:'個人信息確認',
        informationConfirmTitle2:'您將使用以下信息進行身份驗證，請確保信息無誤且與您本人身份證上所顯示的一致。',
        informationConfirmTitle3:'完整姓名：',
        informationConfirmTitle4:'身份證號：',
        informationConfirmTitle5:'以上信息僅在隱私政策的範圍內進行使用',
        modify:'修改',
        // 添加銀行卡
        addBankTitle1:'添加銀行賬戶/卡',
        addBankTitle2:'應監管要求，為了您的資金安全及順利出金，請添加您本人銀行賬戶/卡。',
        addBankTitle3:'請上傳該銀行帳戶的帳戶資訊或存摺',
        addBankTitle4:'需包含：銀行名稱/Logo、賬戶持有人姓名以及完整的銀行賬號',
        addBankInput1:'請輸入銀行代碼',
        addBankInput2:'請輸入銀行名稱',
        addBankInput3:'請輸入分行名稱',
        addBankInput4:'請輸入銀行賬號',
        addBankInput5:'銀行代碼',
        addBankInput6:'銀行名稱',
        addBankInput7:'分行名稱',
        addBankInput8:'銀行帳號',
        example:'例如',
        twBankName1:'國泰世華',
        twBankName2:'中國信託',
        twBankName3:'中華郵政',
        twBankData1:'國泰世華銀行',
        twBankData2:'華山分行',
        thBankName1:'開泰銀行',
        thBankName2:'泰京銀行',
        thBankName3:'盤谷銀行',
        thBankData1:'開泰銀行',
        thBankData2:'曼谷辦事處',
        toVerify:'去驗證',
        authenticationSuccessful:'認證成功',
        popPrompt: '應監管要求，請繼續完成身份認證，以免影響出金功能。',
        popPrompt1: '當前國家/地區暫無出金功能，詳細請咨詢客服',
        cancel: '取消',
        continue: '繼續',
        goLogin: '去登入',
        determine: '確定',
        authenticationFail:'認證失敗',
        authenticationFailTitle1:'很抱歉，您的身份驗證失敗，請重新上載',
        authenticationFailTitle2:'上載前請註意檢查以下2點：',
        authenticationFailTitle3:'1.確認上載的是您本人有效身份證',
        authenticationFailTitle4:'2.證件上顯示的信息清晰完整',
        returnModify: '返回修改',
        onlineConsultation: '在線咨詢',
        immediateDeposit: '立即入金',
    },
    accountCenter: {
        // 首頁
        homeTitle1: '個人資料',
        miniAccount: '迷你賬戶',
        standardAccount: '標准賬戶',
        highendAccount: '高端賬戶',
        homeTitle2: '推薦客戶賺美金',
        homeTitle3: '每成功推薦1名好友交易',
        activityRules: '活動規則',
        viewRewards: '檢視獎勵',
        activityListTitle1: '您可獲得',
        activityListTitle2: '您的好友可獲得',
        activityListTitle3: '分享您的鏈接：',
        copyLink: '復制鏈接',
        copySucc: '復制成功',
        myRewards: '我的獎勵',
        rewardTitle1: '獎勵金額',
        rewardTitle2: '好友交易賬戶',
        rewardTitle3: '好友註冊時間',
        rewardTitle4: '獎勵狀態',
        rewardState1: '已發放',
        rewardState2: '未達標',
        rewardState3: '待達標',
        accountNumber: '交易賬戶：',
        email: '電子信箱：',
        phoneNumber: '手機號碼：',
        residentialArea: '居住地區：',
        accountValue: '賬戶凈值',
        creditGift: '信用贈金',
        activityReward: '活動獎勵',
        availablePoints: '可用積分',
        deposit: '入金',
        accountTips1: '隨持倉商品訂單的盈虧而變化的實際余額（包括未平倉商品訂單的盈虧）賬戶凈值有時效性，實際凈值請登入交易平臺檢視。',
        accountTips2: '參與贈金活動得到信用贈金的總和',
        accountTips3: '用戶參與活動得到獎勵贈金的總和',
        accountTips4: '可用於兌換贈金的積分余額',
        notice: '公告',
        // 入金頁面
        withdraw: '出金',
        capitalFlow: '資金流水',
        tradeQuery: '交易查詢',
        depositTite1: '賬戶入金',
        depositTite2: '入金金額',
        depositTite3: '預計支付金額',
        depositTite4: '贈金活動',
        depositTite5: '入金方式',
        depositTite6: '賬戶介紹',
        depositTite7: '資金安全',
        depositTite8: '入金須知',
        depositTite9: '支付銀行',
        depositTite10: '請選擇銀行',
        depositTite11: '操作指引',
        depositTite12: '預計支付',
        depositTite13: '贈金需入金後聯繫客服報名活動領取!',
        depositTite14: '更多入金方式',
        depositTite15: '選擇入金方式',
        depositTite16: '7-11 超商',
        depositTite17: '全家超商',
        depositTite18: '萊爾富超商',
        subDeposit: '提交入金',
        accountType: '賬戶類型',
        accountTypeTitle1: '最低入金金額',
        accountTypeTitle2: '最低點差',
        fundSecurityList1: '0手續費',
        fundSecurityList2: '即時到賬',
        fundSecurityList3: '獨立存管',
        depositTips1: '1.最低入金金額？',
        depositTips2: '2.入金匯率是按照什麼標準換算的？',
        depositTips3: '3.入金需要開通美元賬戶嗎？',
        depositTips4: '4.入金後多久到賬？',
        depositTips10: '5.可以使用他人賬戶入金嗎？',
        depositTips11: '6.入金後的資金存放在哪裡？',
        depositTips01: '最低入金金額：',
        depositTips02: '支付金額按當天中間價匯率自動換算',
        depositTips03: '不需要，您可以使用本人銀行卡、信用卡，通過網上銀行或手機銀行轉賬匯款等方式入金，會根據您提交的入金金額（USD），按照當時的匯率換算出您所需要支付的幣種，支付成功後資金會自動存入您的交易賬戶。',
        depositTips04: '一般情況下，即時到賬；若超過10分鐘未到達交易賬戶，請您聯繫在線客服進行人工查詢。',
        depositTips010: '不行，為了您的賬戶安全，不接受來自非本人銀行賬戶的資金。',
        depositTips011: '獨立存管，並以委託保管形式持有，以保障客戶利益。',
        depositTips5: '5.更多關於入金詳細說明及操作方式，請點選此處。',
        depositTips6: '1. 轉賬金額與申請入金金額必須一致',
        depositTips7: '2. 請勿向同一收款賬戶重複匯款',
        depositTips8: '下次不再提醒',
        depositTips9: '去轉賬',
        depositSucc: '入金成功',
        depositFail: '入金失败',
        depositTime: '入金時間',
        depositGuide: '入金指南',
        dollar: 'USD',
        singleLimit: '單筆限額',
        creditCardText1: '請輸入信用卡信息',
        creditCardText2: '請輸入信用卡號碼',
        creditCardText3: '請輸入有效的信用卡號碼',
        creditCardText4: '有效日期（月/年）',
        creditCardText5: 'CVV （安全碼）',
        creditCardText6: '請輸入信用卡有效期限',
        creditCardText7: '請輸入正確 CVV 安全碼',
        creditCardText8: '持卡人姓名',
        creditCardText9: '名字',
        creditCardText10: '姓氏',
        creditCardText11: '請輸入您的名',
        creditCardText12: '請輸入您的姓',
        creditCardText13: '* 此信用卡應屬於您，並在您的名下使用。',
        creditCardText14: '下一步',
        // 入金轉賬頁面
        onlineBankTitle1: '收款賬戶信息',
        onlineBankTitle2: '您需使用本人的銀行賬戶進行匯款',
        onlineBankTitle2CSV: '您需使用本人的银行账户进行繳費',
        onlineBankTitle3: '請在',
        onlineBankTitle4: '內完成操作',
        onlineBankTitle5: '操作提示：',
        onlineBankTitle6: '1.將匯款金額、收款銀行、銀行代碼、收款賬號複製到您本人的手機銀行/網上銀行進行匯款操作，同行或跨行均可；',
        onlineBankTitle6CSV: '1.將繳費金額、繳費代碼輸入到收款超商繳費頁面；',
        onlineBankTitle7: '2.請勿保存匯款信息進行重復匯款；',
        onlineBankTitle7CSV: '2.請勿保存繳費信息進行重複繳費；',
        onlineBankTitle8: '3.若轉賬金額與申請入金金額不一致，無法匯款成功；',
        onlineBankTitle9: '4.請在15分鐘內完成匯款，否則將無法入金成功',
        onlineBankTitle9CSV: '3.請在15分鐘內完成繳費，否則將無法入金成功。',
        onlineBankTitle10: '資金於CM Trade獨立存管，請放心支付。',
        copy: '復制',
        remittanceAmount: '匯款金額：',
        remittanceAmountCSV: '繳費金額：',
        collectionBank: '收款銀行：',
        collectionBankCSV: '收款超商：',
        collectionAccount: '收款賬號：',
        collectionAccountCSV: '繳費代碼：',
        confirm: '確認',
        //出金頁面
        withdrawTitle1: '賬戶出金',
        withdrawTitle2: '可取資金',
        withdrawTitle3: '出金金額',
        withdrawTitle4: '預計到賬金額',
        withdrawTitle5: '賬戶姓名',
        withdrawTitle6: '到賬銀行賬戶',
        subWithdraw: '提交出金',
        applySucc: '申請成功',
        applyFail: '申請失敗',
        applySuccStep1: '待審核',
        applySuccStep2: '銀行受理',
        applySuccStep3: '出金完成',
        applySuccStep4: '預計一個工作日',
        cancelWithdraw: '取消出金申請',
        reminder: '溫馨提示',
        reminder1: '溫馨提示：',
        withdrawTips1: '1.若參與贈金活動，申請出金預設退出贈金活動，會扣除贈金及纍計的交易手數將無法獲得返利。',
        withdrawTips2: '2.因受部分銀行受理情況影響，可能出現延遲到賬，以實際到賬時間為准。',
        withdrawTips3: '最低取款金額',
        withdrawTips4: '最高取款金額',
        withdrawTips5: '出金金額超出可取金额',
        withdrawTips6: '可取資金=可用預付款-信用-0.4*已用預付款（0.4*已用預付款為防爆倉比例）',
        understand: '了解',
        //資金流水
        capitalFlowTitle1: '類型：',
        capitalFlowTitle2: '狀態：',
        capitalFlowTitle3: '時間：',
        timeSelect1:'近7天',
        timeSelect2:'本月',
        timeSelect3:'上個月',
        timeSelect4:'最近一年',
        orderNumber: '訂單號',
        money: '金額',
        applyTime: '申請時間',
        state: '狀態',
        type: '類型',
        home: '首頁',
        lastPage: '尾頁',
        previousPage: '上一頁',
        nextPage: '下一頁',
        Jump: '跳轉',
        totalNumber: '總條數',
        pageCount: '總頁數',
        depositFlow: '入金流水',
        withdrawFlow: '出金流水',
        completed: '已完成',
        notCompleted: '未完成',
        closed: '已關閉',
        untreated: '未處理',
        paymentTime:'支付時間',
        cancelWithdraw1:'取消申請',
        rewardTime:'獎勵發放時間',
        rewardType:'獎勵類型',
        //交易查詢
        tradeHistory: '交易歴史',
        pendingOrder: '掛單查詢',
        holdOrder: '持倉查詢',
        varieties: '品種',
        tradingVolume: '交易量',
        openingPrice: '開倉價',
        openingTime: '開倉時間',
        closingPrice: '平倉價',
        closingTime: '平倉時間',
        hangingOrderPrice: '掛單價',
        hangingOrderTime: '掛單時間',
        stopLoss: '止損',
        stopSurplus: '止盈',
        overnightInterest: '隔夜倉息',
        profit: '盈利',
        totalProfit: '總盈利：',
        totalVolume : '總交易量：',
        hand : '手',
        dataStatus1:'做多',
        dataStatus2:'做空',
        dataStatus3:'信用',
        dataStatus4:'做多止損',
        dataStatus5:'做多限價',
        dataStatus6:'做空止損',
        dataStatus7:'做空限價',
        dataStatus8:'最低入金金額',
        dataStatus9:'最高入金金額',
        dataStatus10:'積分兌換獎勵',
        dataStatus11:'贈金返利',
        dataStatus12:'EIA競猜獎勵',
        dataStatus13:'非農競猜獎勵',
        dataStatus14:'暫無您的數據',
        //邀請活動規則
        inviteActivityTitle1:'1、邀請人參與說明',
        inviteActivityTitle2:'2、被邀請人說明',
        inviteActivityTitle3:'3、注意事項',
        inviteRule1:'（1）所有註冊實盤賬號的用戶，都可分享活動成為邀請人；',
        inviteRule2:'（2）邀請人每邀請一位用戶開設實盤賬號，被邀請人在開戶後30天內激活賬戶並交易滿1標準手（虛擬貨幣除外的任意品種累計）即可獲得15美金獎勵；',
        inviteRule3:'（3）邀請人的邀請獎勵將直接發放到參與邀請活動用戶對應的實盤交易賬號中，不可更換；',
        inviteRule4:'（4）邀請人每週邀請獎勵最高可累計獲得200美元，邀請人和被邀請人獲取的獎勵可直接用於提現或交易。',
        unInviteRule1:'（1）被邀請人須是未曾在我司開設過任何實盤交易賬戶；',
        unInviteRule2:'（2）被邀請人必須通過邀請人分享的推廣QR Code/鏈接註冊開戶，在開戶後30天內激活賬戶並交易滿1標準手（虛擬貨幣除外的任意品種累計），可獲得5美金贈金；',
        noticeTip1:'（1）我司員工及其家屬不適用此活動，銷戶重開賬號不適用此活動，活動最終解釋權歸我司所有；',
        noticeTip2:'（2）如我司懷疑用戶濫用、企圖濫用、以及其他方式濫用優惠，有權取消用戶賬戶所獲獎勵。',
    },
    accountInfor: {
         //賬戶信息
         accountHome: '賬戶首頁',
         goldInGoldOut: '入金/出金',
         accountData: '賬戶信息',
         accountDateTitle1: '身份驗證',
         accountDateTitle2: '銀行賬戶/卡管理',
         accountDateTitle3: '電子信箱',
         accountDateTitle4: '手機號碼',
         accountDateTitle5: '修改密碼',
         nickname: '昵稱：',
         realName: '真實姓名：',
         notCertified: '未認證',
         underReview: '審核中',
         modifyNickname: '修改您的昵稱',
         inputNickname: '請輸入您的昵稱',
         Submit: '提交',
         bind: '綁定',
         identityTitle: '您已完成身份驗證，現入金交易享高額贈金福利哦~',
         bindBankTitle1: '您已完成銀行賬戶/卡綁定，如需修改請點選修改按鈕進行修改',
         bindBankTitle2: '銀行名稱',
         bindBankTitle3: '銀行代碼',
         bindBankTitle4: '分行名稱',
         bindBankTitle5: '銀行賬戶號碼',
         modifyUserHead: '修改頭像',
         bindEmailTitle1: '您當前未綁定電子信箱，我們會通過郵件給您發送優惠活動和行情周刊。',
         bindEmailTitle2: '電子信箱綁定',
         bindEmailTitle3: '電子信箱綁定成功',
         bindEmailTitle4: '電子信箱修改成功',
         bindEmailTitle5: '綁定電子信箱以保證您賬戶安全， 以及接收我們的服務郵件',
         bindPhoneTitle1: '您當前未綁定手機號碼，我們會通過簡訊給您發送優惠活動和服務通知',
         bindPhoneTitle2: '綁定手機號碼以保證您賬戶安全， 以及接收我們的簡訊服務通知。',
         bindPhoneTitle3: '手機號碼綁定成功',
         bindPhoneTitle4: '手機號碼修改成功',
         bindPhoneTitle5: '手機號碼綁定',
         modifyPassTips1: '輸入當前密碼',
         modifyPassTips2: '新的6-15位字母數字組合密碼',
         modifyPassTips3: '再次輸入新的密碼',
         modifyPassTips4: '密碼修改成功',
    },
    footer: {
        navText1:'關於我們',
        navText2:'客戶協議',
        navText3:'風險披露',
        navText4:'免責宣告',
        navText5:'隱私條款',
        navText6:'資金安全性',
        navText7:'幫助中心',
        copyright:'Copyright © 2023 CM Trade版權所有，不得轉載',
        suText1:'免費開戶',
        suText2:'在線諮詢'
    },
    appH5: {
        regSuccessText1:'恭喜您，註冊成功',
        regSuccessText2:'您的新手註冊獎勵金已到賬',
        regSuccessText3:'立即入金領取',
        regSuccessText4:'完成身份認證，獲取更多特權'
    }   
}