export default {
    header: {
        login: 'Log in',
        Logout: 'Log out',
        register: 'Sign up',
        appDownload: 'Download APP',
        headerDow: 'Download APP to receive bonus',
	    headerDowBtn: 'GET',
    },
    loginRegister: {
        // 登录注册
        loginLeftTitle1: 'Bonus benefit',
        loginLeftTitle2: 'Get at least＄120, enjoy it after deposit and trade',
        loginRightTitle1: 'Do you already have a trading account?',
        loginRightTitle2: 'Create a account',
        loginRightTitle3: 'Log in with phone verification code',
        loginRightTitle4: 'Forgot password?',
        loginInput1: 'Trading account / Email account',
        loginInput2: 'Password',
        regLeftTitle1: 'Start trading within minutes',
        regLeftTitle2: 'Efficient trade platform with zero commission, low spread ',
        loginTitle1:'Signed up? ',
        loginTitle2:'Log in',
        loginTitle3:'No account? ',
        loginTitle4:'Sign up',
        loginTitle5:'Invitation code（Optional）',
        loginTitle6:'I agree',
        retrievePassTitle1:'Reset password with phone',
        retrievePassTitle2:'Reset password with email',
        mobileRegRightTitle1: 'Login or register by phone',
        mobileRegRightTitle2: 'Unregistered phones will automatically create accounts',
        moblieInput:'Phone number',
        codeInput:'Verification code',
        getCode:'Send',
        resend:'Send again',
        regLogin:'Login or Register',
        regButtomTitle1:'Or use the following method to log in',
        regButtomTitle2:'By clicking the login or register button you agree',
        agreement:'《User Agreement》',
        emailRegRightTitle1: 'Register with email',
        countryInput:'Select country/region of residence',
        emailInput:'Email address',
        passInput:'6-15 letters and numbers ',
        // 注册成功
        loginSuccessful:'Successfully registered',
        loginSuccTitle:'Deposit now! Receive USD bonus ',
        loginSuccBtn1:'Deposit immediately',
        loginSuccBtn2:'Complete identity verification',
        loginSuccEmail:'Your email address is in use on an existing account, you can log in your account now',
        // 忘记密码
        retrievePassword:'Retrieve password',
        retrievePassTab1:'Retrieve password with email',
        retrievePassTab2:'Retrieve password with Phone number',
        resetPassword:'Confirm',
        resetPasswordTitle:'Password re-set successfully and log in again',
        returnLogin:'Back to login',
        checkPrompt1:'Email address cannot be empty',
        checkPrompt2:'Password cannot be empty',
        checkPrompt3:'Invaild password format ',
        checkPrompt4:'Phone number cannot be empty',
        checkPrompt25:'Please enter a new phone number',
        checkPrompt5:'Invaild phone number format',
        checkPrompt6:'Verification code cannot be empty',
        checkPrompt7:'Please select your country of residence',
        checkPrompt27:'Select a bank name',
        checkPrompt8:'Email address cannot be empty',
        checkPrompt26:'Please enter a new email address',
        checkPrompt9:'Invaild email format',
        checkPrompt10:'Password cannot be empty',
        checkPrompt11:'Invaild password format',
        checkPrompt12:'New password cannot be empty',
        checkPrompt13:'Invaild new password format',
        checkPrompt14:'Confirm password format is incorrect',
        checkPrompt15:'Confirm password cannot be empty',
        checkPrompt16:'The password didi not match the re-typed password',
        checkPrompt17:'Bank code cannot be empty',
        checkPrompt18:'Branch name cannot be empty',
        checkPrompt19:'Bank name cannot be empty',
        checkPrompt20:'Bank card number cannot be empty',
        checkPrompt21:'Bank bill cannot be empty',
        checkPrompt22:'Username cannot be empty',
        checkPrompt23:'The front photo of the ID card cannot be empty',
        checkPrompt24:'The back photo of the ID card cannot be empty',
    },
    perfectData: {
        // 完善资料
        perfectDataLeftTitle1:'Create a live account',
        perfectDataLeftTitle2:'According to regulatory terms, You need to complete identity verification before you can withdraw',
        perfectData:'Complete information',
        step1Title1:'Please enter your information',
        identityAuthentication:'Identity authentication',
        step2Title1:'According to regulatory terms, you will need to complete the following steps',
        step2Title2:'①Real name authentication',
        step2Title3:'②Add a bank card',
        step2Title4:'Identification',
        step3Title1:'Deposit to enjoy bonus',
        step3Title2:'Up to $2400 Bonus',
        step3Title3:'Deposit now and get bonus',
        step3Title4:'Country of Residence',
        perfectDataTips:'My country of residence, tax-residence and nationality are the same',
        nextStep:'Next',
        //身份认证
        identityAuthenTitle1:'Activation of deposit and trading functions requires completion of identity verification',
        identityAuthenTitle2:'Click to upload the front photo of the ID card',
        identityAuthenTitle3:'Click to upload the back photo of the ID card',
        // 个人信息确认
        informationConfirmTitle1:'Confirm personal information',
        informationConfirmTitle2:'Please make sure the information is correct and matches the information on your ID',
        informationConfirmTitle3:'Full name：',
        informationConfirmTitle4:'ID number：',
        informationConfirmTitle5:'The above information will only be used within the scope of the Privacy Policy',
        modify:'Modify',
        // 添加银行卡
        addBankTitle1:'Add a bank account',
        addBankTitle2:'Please add your own bank account for the safety and smooth withdrawal of your funds',
        addBankTitle3:'Please upload the account information or passbook for this bank account',
        addBankTitle4:'Including bank name or logo, account holder name and full bank account number',
        addBankInput1:'Please enter bank code',
        addBankInput2:'Please enter bank name',
        addBankInput3:'Please enter branch name',
        addBankInput4:'Please enter bank account',
        addBankInput5:'bank code',
        addBankInput6:'bank name',
        addBankInput7:'branch name',
        addBankInput8:'Bank account number',
        example:'Example',
        twBankName1:'Cathay United Bank',
        twBankName2:'CTBC Bank',
        twBankName3:'Chunghwa Post',
        twBankData1:'Cathay United Bank',
        twBankData2:'Huashan Branch',
        thBankName1:'Kasikorn bank',
        thBankName2:'Krung Thai Bank',
        thBankName3:'Bangkok Bank',
        thBankData1:'Kasikorn bank',
        thBankData2:'Bangkok Office',
        toVerify:'to verify',
        authenticationSuccessful:'authentication succeeds',
        popPrompt: 'Please continue to complete the identity verification',
        popPrompt1: 'There is no withdrawal function in the current country, please consult customer service for details',
        cancel: 'Cancel',
        continue: 'Continue',
        goLogin: 'Login now',
        determine: 'Determine',
        authenticationFail:'Authentication failed',
        authenticationFailTitle1:'Sorry, your authentication failed, please upload again',
        authenticationFailTitle2:'Please check the following 2 points before uploading：',
        authenticationFailTitle3:'1.Confirm that the upload is your valid ID card',
        authenticationFailTitle4:'2.The ID information is clear and complete',
        returnModify: 'Back to modify',
        onlineConsultation: 'Customer support',
        immediateDeposit: 'Deposit Now',
    },
    accountCenter: {
        // 首页
        homeTitle1: 'Personal information',
        miniAccount: 'Mini account',
        standardAccount: 'Standard account',
        highendAccount: 'VIP account',
        homeTitle2: 'Get bonus by become referrer',
        homeTitle3: 'Every successful referral 1 friend to trade',
        activityRules: 'Event rules',
        viewRewards: 'Check reward',
        activityListTitle1: 'your can get',
        activityListTitle2: 'your friends get',
        activityListTitle3: 'Share your link：',
        copyLink: 'Copy the link',
        copySucc: 'Copied successfully',
        myRewards: 'My reward',
        rewardTitle1: 'Bonus amount',
        rewardTitle2: 'Friend trading account',
        rewardTitle3: 'Friends registration time',
        rewardTitle4: 'Rewards status',
        rewardState1: 'Issued',
        rewardState2: 'Substandard',
        rewardState3: 'Under review',
        accountNumber: 'Teading account：',
        email: 'Email：',
        phoneNumber: 'Phone number：',
        residentialArea: 'Residence：',
        accountValue: 'Acount equity',
        creditGift: 'Credit bonus',
        activityReward: 'Event Bonus',
        availablePoints: 'available points',
        deposit: 'Deposit',
        accountTips1: 'Net of the actual balance(including the profit and loss of open commodity orders) that changes with the profit and loss of positions is time-sensitive, Please login to view the actual net.',
        accountTips2: 'Participate in the bonus campaign to get the sum of the credit bonus',
        accountTips3: 'Total amount of the bonuses that users get for participating in activities',
        accountTips4: 'Balance of points available for redemption',
        notice: 'Notice',
        // 入金页面
        withdraw: 'Withdraw',
        capitalFlow: 'Capital flow',
        tradeQuery: 'Transaction query',
        depositTite1: 'Fund your account',
        depositTite2: 'Deposit amount',
        depositTite3: 'Estimated payment amount',
        depositTite4: 'Bonus programmes',
        depositTite5: 'Deposit method',
        depositTite6: 'Account details',
        depositTite7: 'Financial security',
        depositTite8: 'Deposit notice',
        depositTite9: 'Paying bank',
        depositTite10: 'Choose your method of payment',
        depositTite11: 'Deposit guide',
        depositTite12: 'Estimated amount of payment',
        depositTite13: 'After depositing, you can contact customer service to claim the bonus!',
        depositTite14: 'More deposit methods',
        depositTite15: 'Select deposit mode',
        depositTite16: '7-11 supermarket',
        depositTite17: 'FamilyMart',
        depositTite18: 'Lair Fu supermarket',
        subDeposit: 'Submit',
        accountType: 'Account type',
        accountTypeTitle1: 'Minimum deposit amount',
        accountTypeTitle2: 'Minimum spread',
        fundSecurityList1: 'Zero fees',
        fundSecurityList2: 'Immediate payment',
        fundSecurityList3: 'Funds separately',
        depositTips1: "1. What's the minimum deposit amount?",
        depositTips2: "2. How's the exchange rate converted?",
        depositTips3: '3. Do I need a USD account for deposit?',
        depositTips4: '4. When the deposit funds will be into my account?',
        depositTips10: '5. Can I deposit with others bank account?',
        depositTips11: '6. Where are my deposit funds kept?',
        depositTips01: 'The minimum amount: ',
        depositTips02: 'The deposit amount is automatically converted according to the mid-price exchange rate of the day',
        depositTips03: 'No, you can use your own bank card or credit card to deposit funds via online banking or mobile bank transfer, etc. The amount you need to pay in your local currency will be converted according to the exchange rate at that time based on the deposit amount (USD) you submitted. After succsessful payment, the funds will be automatically deposited into your trading account.',
        depositTips04: "Generally, it will be credited into your account immediately. If you don't receive  the deposit funds within 10 minutes, please contact the customer service.",
        depositTips010: 'No. For your account security, it is not allowed to deposit with others bank account.',
        depositTips011: 'All funds are kept by the third-party institution to protect the interests of customers.',
        depositTips5: '5. More details about depositing and how to operate；',
        depositTips6: '1. The transfer amount needs to be consistent with the entered deposit amount.',
        depositTips7: '2. Please be careful not to duplicate your transfer.',
        depositTips8: "Don't remind me again.",
        depositTips9: 'Transfer Now',
        depositSucc: 'Deposit successfully',
        depositFail: 'Deposit failed',
        depositTime: 'Deposit time',
        depositGuide: 'Deposit guideline',
        dollar: 'USD',
        singleLimit: 'Single limit',
        creditCardText1: 'Enter Card Details',
        creditCardText2: 'Enter the card number',
        creditCardText3: 'Please enter a valid card number',
        creditCardText4: 'Expiration Date',
        creditCardText5: 'CVV Code',
        creditCardText6: 'Please insert expiration date',
        creditCardText7: 'Please enter the correct CVV code of the card',
        creditCardText8: 'Name on Card',
        creditCardText9: 'First Name',
        creditCardText10: 'Last Name',
        creditCardText11: 'Enter your first name',
        creditCardText12: 'Enter your last name',
        creditCardText13: '* This card should belong to you and be in your name.',
        creditCardText14: 'Next',
        // 入金转账页面
        onlineBankTitle1: 'Bank information',
        onlineBankTitle2: 'You should use your own bank account for remittance',
        onlineBankTitle2CSV: 'You need to use your bank account for payment',
        onlineBankTitle3: 'Please',
        onlineBankTitle4: 'complete the operation',
        onlineBankTitle5: 'Operation Tips：',
        onlineBankTitle6: '1. Copy the remittance amount, recipient bank, bank code, and recipient account number to your own mobile banking/online banking for remittance operations, either within or across banks; ',
        onlineBankTitle6CSV: '1.Enter the payment amount and the payment code into the payment page of the payment supermarket;',
        onlineBankTitle7: '2. Do not save the remittance information for repeated remittance; ',
        onlineBankTitle7CSV: '2.Do not save the payment information for repeated payment;',
        onlineBankTitle8: '3. If the transfer amount is inconsistent with the applied deposit amount, the remittance will not be successful; ',
        onlineBankTitle9: '4. Please complete the remittance within 15 minutes, otherwise you will be able to deposit successfully; ',
        onlineBankTitle9CSV: '3.Please complete the payment within 15 minutes, otherwise the deposit will not be successful.',
        onlineBankTitle10: 'The funds are kept in CM Trade independently, please rest assured to pay.',
        copy: 'Copy',
        remittanceAmount: 'Remittance amount：',
        remittanceAmountCSV: 'Payment amount：',
        collectionBank: 'Receiving bank：',
        collectionBankCSV: 'Payee supermarket：',
        collectionAccount: 'Receiving account：',
        collectionAccountCSV: 'Payment code：',
        confirm: 'Confirm',
        //出金页面
        withdrawTitle1: 'Withdraw funds',
        withdrawTitle2: 'Available funds',
        withdrawTitle3: 'Withdraw amount',
        withdrawTitle4: 'Amount transferred',
        withdrawTitle5: 'Amount name',
        withdrawTitle6: 'Collection bank account',
        subWithdraw: 'Submit withdraw',
        applySucc: 'Successful application',
        applyFail: 'Unsuccessful application',
        applySuccStep1: 'Pending',
        applySuccStep2: 'Bank acceptance',
        applySuccStep3: 'Withdraw successfully',
        applySuccStep4: 'It takes about one working day',
        cancelWithdraw: 'Cancel a withdrawl',
        reminder: 'Reminder',
        reminder1: 'Reminder: ',
        withdrawTips1: '1. If you participate in the bonus activity, the application for withdrawal will abandon from the bonus activity by default and the accumulated bonus will be deducted',
        withdrawTips2: '2. Due to the acceptance of some banks, there many be a delay in the arrival of the account, the actual arrival time shall prevail.',
        withdrawTips3: 'Minimum withdrawal amount',
        withdrawTips4: 'Maximum withdrawal amount',
        withdrawTips5: 'The withdrawal amount exceeds the available amount',
        withdrawTips6: 'Available withdrawal = available margins - credit - 0.4 used margins (0.4 used margins served as forced liquidation–proof deposit)',
        understand: 'I get it',
        //资金流水
        capitalFlowTitle1: 'Type：',
        capitalFlowTitle2: 'Status：',
        capitalFlowTitle3: 'Time：',
        timeSelect1:'Recent 7 days',
        timeSelect2:'This month',
        timeSelect3:'Last month',
        timeSelect4:'Last years',
        orderNumber: 'Order number',
        money: 'Amount',
        applyTime: 'Application data',
        state: 'State',
        type: 'Type',
        home: 'Home',
        lastPage: 'Last',
        previousPage: 'Previous',
        nextPage: 'Next',
        Jump: 'Jump',
        totalNumber: 'Total items',
        pageCount: 'PageCount',
        depositFlow: 'Deposit statements',
        withdrawFlow: 'Withdrawal staements',
        completed: 'Finished',
        notCompleted: 'Unfinished',
        closed: 'Closed',
        untreated: 'Untreated',
        paymentTime:'Payment date',
        cancelWithdraw1:'Cancellation request',
        rewardTime:'Time of rewards distribution',
        rewardType:'Types of rewards',
        //交易查询
        tradeHistory: 'trading history',
        pendingOrder: 'Inquiry pending orders',
        holdOrder: 'Inquiry position',
        varieties: 'Varieties',
        tradingVolume: 'Trading volume',
        openingPrice: 'Opening price',
        openingTime: 'Opening time',
        closingPrice: 'Closing price',
        closingTime: 'Closing time',
        hangingOrderPrice: 'Pending order price',
        hangingOrderTime: 'Pending order time',
        stopLoss: 'Stop loss',
        stopSurplus: 'Take profit',
        overnightInterest: 'Overnight interest',
        profit: 'Profit',
        totalProfit: 'Total profit：',
        totalVolume : 'Total transaction volume：',
        hand : 'lot',
        dataStatus1:'Going long',
        dataStatus2:'Going short',
        dataStatus3:'Credit',
        dataStatus4:'Go long stop loss',
        dataStatus5:'Go long and limit price',
        dataStatus6:'Go short and stop loss',
        dataStatus7:'Go short and limit price',
        dataStatus8:'Minimum deposit amount',
        dataStatus9:'Maximum deposit amount',
        dataStatus10:'Points for rewards',
        dataStatus11:'Bonus',
        dataStatus12:'EIA prizes quiz',
        dataStatus13:'Nonagricultural prizes quiz',
        dataStatus14:'Dont have your data',
        //邀请活动规则
        inviteActivityTitle1:'1. Invitation to Participate Instructions',
        inviteActivityTitle2:'2. Description of the invitee',
        inviteActivityTitle3:'3. Precautions',
        inviteRule1:'(1) All users who register for a real offer account can share activities and become inviters;',
        inviteRule2:'(2) Every time the inviter invites a user to open a real account, the invitee can get a $15 reward if he activates the account within 30 days after opening the account and trades 1 standard lot (accumulated for any variety except virtual currency);',
        inviteRule3:'(3) The inviter is invitation reward will be directly distributed to the real trading account corresponding to the user participating in the invitation activity, and cannot be replaced;',
        inviteRule4:'(4) The inviter can receive up to $200 in weekly invitation rewards, and the rewards obtained by the inviter and invitee can be directly used for cash withdrawal or trading.',
        unInviteRule1:'(1) The invitee must have never opened any firm trading account in our company;',
        unInviteRule2:'(2) The invitee must register and open an account through the promotional QR Code/link shared by the inviter, activate the account within 30 days after opening the account and trade 1 standard lot (accumulated for any variety except virtual currency), and get a $5 bonus ;',
        noticeTip1:'(1) Our employees and their families are not eligible for this activity, and account cancellation and re-opening are not applicable to this activity, and our company reserves the final interpretation right of the activity;',
        noticeTip2:'(2) If our company suspects that the user has abused, attempted to abuse, or otherwise abused the benefits, we have the right to cancel the rewards obtained from the user is account.',
    },
    accountInfor: {
         //账户信息
         accountHome: 'Account home page',
         goldInGoldOut: 'Deposits/Withdrawals',
         accountData: 'Account information',
         accountDateTitle1: 'Authentication',
         accountDateTitle2: 'Bank account management',
         accountDateTitle3: 'Email',
         accountDateTitle4: 'Phone number',
         accountDateTitle5: 'Change your password',
         nickname: 'Username: ',
         realName: 'Real name: ',
         notCertified: 'Unaccredited',
         underReview: 'Under review',
         modifyNickname: 'Change your username',
         inputNickname: 'Please enter your username',
         Submit: 'Submit',
         bind: 'To bind',
         identityTitle: 'You have completed your identity verification, and you can enjoy the benefits of high bonus for deposit and transactions',
         bindBankTitle1: 'You have finished binding your bank account. If you need to modify it, please click the Modify button.',
         bindBankTitle2: 'Bank name',
         bindBankTitle3: 'Bank code',
         bindBankTitle4: 'Branch name',
         bindBankTitle5: 'Band account number',
         modifyUserHead: 'Change your avatar',
         bindEmailTitle1: 'Bind your email, we will send you promotions and weekly market news by email ',
         bindEmailTitle2: 'Bind email',
         bindEmailTitle3: 'Email bind successful',
         bindEmailTitle4: 'Email modified successfully',
         bindEmailTitle5: 'Bind your email to ensure your account security and subscribe to us',
         bindPhoneTitle1: 'Bind your phone，we will send you promotions and weekly market news by messages',
         bindPhoneTitle2: 'Bind your email to ensure your account security and reciving our message',
         bindPhoneTitle3: 'Phone number bind successful',
         bindPhoneTitle4: 'Phone number modified successful',
         bindPhoneTitle5: 'Bind your phone number',
         modifyPassTips1: 'enter current password',
         modifyPassTips2: 'New password must be 6-15 characters',
         modifyPassTips3: 're-enter new password',
         modifyPassTips4: 'Password modified successfully ',
    },
    footer: {
        navText1:'About us',
        navText2:'Client Agreement ',
        navText3:'Risk Disclosure',
        navText4:'Disclaimer',
        navText5:'Privacy Terms',
        navText6:'Fund Security',
        navText7:'Support Center',
        copyright:'Copyright © 2023 CM Trade Copyright, All rights reserved',
        suText1:'Sign Up',
        suText2:'Live chat'
    },
    appH5: {
        regSuccessText1:'Congrats! Registration success',
        regSuccessText2:'The bonus has been credited into your account',
        regSuccessText3:'Deposit and claim it',
        regSuccessText4:'Complete authentification to get more privileges'
    }   
}