var rootDomain = 'cmtrade.com';
var host = "uc." + rootDomain;
if(process.env.NODE_ENV !== 'development'){
    host = window.location.host;
    rootDomain = host.substring(host.indexOf('.') + 1);
    document.domain = rootDomain;
}else{
    rootDomain = 'exchange6.com';
}
export const Config = {
    rootDomain: rootDomain,
    www: 'https://www.' + rootDomain,
    api: 'https://api.' + rootDomain,
    userToken: '',
    images: 'https://images.' + rootDomain
}
//headers
export const HEADERS_FORM_URLENCODED = {headers: {"content-type": "application/x-www-form-urlencoded"}}
export const HEADER_USER_TOKEN = 'X-IDENTIFY-TOKEN'

export const REG_EMAIL = /^([\.a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
export const REG_PASSWORD = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,15}$/