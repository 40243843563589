export default {
    header: {
        login: 'Login ',
        Logout: 'Logout ',
        register: 'Daftar ',
        appDownload: 'Unduh APP ',
        headerDow: 'Unduh APP dan Dapatkan Bonus ',
        headerDowBtn: 'Unduh ',
    },
    loginRegister: {
        // 登录注册
        loginLeftTitle1: 'Manfaat Bonus ',
        loginLeftTitle2: 'Bonus minimal $120 setelah deposit dan trading ',
        loginRightTitle1: 'Apakah Anda sudah punya akun trading？ ',
        loginRightTitle2: 'Daftar akun ',
        loginRightTitle3: 'Login dengan kode verifikasi HP ',
        loginRightTitle4: 'Lupa kata sandi？ ',
        loginInput1: 'Akun Trading / Akun Email',
        loginInput2: 'Kata sandi',
        regLeftTitle1: 'Buka akun dengan beberapa langkah aja ',
        regLeftTitle2: 'Platform trading dengan 0 komisi, spread rendah dan eksekusi cepat ',
        loginTitle1:'Sudah terdaftar? ',
        loginTitle2:'Login',
        loginTitle3:'Belum punya akun? ',
        loginTitle4:'Daftar',
        loginTitle5:'Kode undangan（Opsional）',
        loginTitle6:'Saya setuju',
        retrievePassTitle1:'kata sandi reset telepon',
        retrievePassTitle2:'Email atur ulang kata sandi',
        mobileRegRightTitle1: 'Daftar/Login dengan HP ',
        mobileRegRightTitle2: 'Akun akan otomatis dibuat untuk nomor HP yang belum terdaftar ',
        moblieInput: 'Nomor HP',
        codeInput: 'Kode verifikasi',
        getCode: 'Dapatkan',
        resend: 'Kirim ulang ',
        regLogin: 'Daftar/Login ',
        regButtomTitle1: 'Atau buat akun dengan cara berikut ',
        regButtomTitle2: 'Klik Daftar/Login berarti Anda sudah setuju ',
        agreement: '<<Perjanjian Pengguna>>',
        emailRegRightTitle1: 'Daftar dengan email ',
        countryInput: 'Pilih negara/wilayah tempat tinggal ',
        emailInput: 'Alamat email',
        passInput: 'Kata sandi (6-15 huruf dan angka)',
        // 注册成功
        loginSuccessful: 'Daftar berhasil ',
        loginSuccTitle: 'Deposit sekarang, nikmati bonus besar ',
        loginSuccBtn1: 'Deposit sekarang ',
        loginSuccBtn2: 'Selesaikan verifikasi identitas ',
        loginSuccEmail: 'Email Anda telah terdaftar, silakan login ',
        // 忘记密码
        retrievePassword: 'Kembalikan kata sandi ',
        retrievePassTab1: 'Kembalikan kata sandi dengan email ',
        retrievePassTab2: 'Kembalikan kata sandi dengan nomor HP ',
        resetPassword: 'Konfirmasi',
        resetPasswordTitle: 'Atur ulang kata sandi berhasil, silakan login lagi ',
        returnLogin: 'Kembali untuk login ',
        checkPrompt1: 'Akun/Email harus diisi ',
        checkPrompt2: 'Kata sandi harus diisi ',
        checkPrompt3: 'Format kata sandi salah ',
        checkPrompt4: 'Nomor HP harus diisi ',
        checkPrompt25: 'Masukkan nomor HP baru ',
        checkPrompt5: 'Format nomor HP salah ',
        checkPrompt6: 'Kode verifikasi harus diisi ',
        checkPrompt7: 'Pilih negara/wilayah ',
        checkPrompt27: 'Pilih nama bank ',
        checkPrompt8: 'Email harus diisi ',
        checkPrompt26: 'Masukkan email baru ',
        checkPrompt9: 'Format email salah ',
        checkPrompt10: 'Kata sandi saat ini harus diisi ',
        checkPrompt11: 'Format kata sandi saat ini salah ',
        checkPrompt12: 'Kata sandi baru harus diisi ',
        checkPrompt13: 'Format kata sandi baru salah ',
        checkPrompt14: 'Konfirmasi format kata sandi salah ',
        checkPrompt15: 'Konfirmasi kata sandi harus diisi ',
        checkPrompt16: 'Dua kata sandi baru tidak sesuai ',
        checkPrompt17: 'Kode bank harus diisi ',
        checkPrompt18: 'Nama cabang harus diisi ',
        checkPrompt19: 'Nama bank harus diisi ',
        checkPrompt20: 'Rekening bank harus diisi ',
        checkPrompt21: 'Rekening koran harus diisi ',
        checkPrompt22: 'Nama panggilan harus diisi ',
        checkPrompt23: 'Foto depan KTP harus diisi ',
        checkPrompt24: 'Foto belakang KTP harus diisi ',
    },
    perfectData: {
        // 完善资料
        perfectDataLeftTitle1: 'Aktifkan akun real ',
        perfectDataLeftTitle2: 'Menurut peraturan regulasi, verifikasi identitas diperlukan untuk melakukan penarikan dana ',
        perfectData: 'Lengkapi data ',
        step1Title1: 'Isikan informasi pribadi dasar ',
        identityAuthentication: 'Verifikasi identitas ',
        step2Title1: 'Menurut peraturan regulasi, Anda diminta untuk menyelesaikan verifikasi berikut: ',
        step2Title2: '①Verifikasi identitas ',
        step2Title3: '②Tambahkan kartu bank ',
        step2Title4: 'Verifikasi identitas ',
        step3Title1: 'Deposit dan dapatkan bonus ',
        step3Title2: 'Bonus hingga USD2400 ',
        step3Title3: 'Deposit sekarang, dapatkan bonus besar ',
        step3Title4: 'Negara tempat tinggal ',
        perfectDataTips: 'Negara/Wilayah tempat tinggal, kewarganegaraan dan negara pajak saya saat ini sama ',
        nextStep: 'Langkah selanjutnya ',
        //身份认证
        identityAuthenTitle1: 'Menurut peraturan regulasi, verifikasi identitas diperlukan untuk mengaktifkan fungsi deposit dan trading ',
        identityAuthenTitle2: 'Klik untuk mengunggah foto depan KTP ',
        identityAuthenTitle3: 'Klik untuk mengunggah foto belakang KTP ',
        // 个人信息确认
        informationConfirmTitle1: 'Konfirmasi informasi pribadi ',
        informationConfirmTitle2: 'Informasi berikut akan digunakan untuk otentikasi, pastikan informasinya benar dan sama dengan yang tertera pada KTP Anda. ',
        informationConfirmTitle3: 'Nama lengkap: ',
        informationConfirmTitle4: 'Nomor KTP: ',
        informationConfirmTitle5: 'Informasi di atas hanya akan digunakan dalam lingkup Kebijakan Privasi ',
        modify: 'Ubah ',
        // 添加银行卡
        addBankTitle1: 'Tambahkan rekening/kartu bank ',
        addBankTitle2: 'Menurut peraturan regulasi, mohon tambahkan rekening/kartu bank Anda untuk keamanan dana dan kelancaran penarikan dana. ',
        addBankTitle3: 'Unggah informasi rekening atau buku tabungan untuk rekening bank ',
        addBankTitle4: 'Sertakan: Nama/Logo Bank, Nama Pemilik Rekening dan Nomor Rekening Bank Lengkap ',
        addBankInput1: 'Masukkan kode bank ',
        addBankInput2: 'Masukkan nama bank ',
        addBankInput3: 'Masukkan nama cabang ',
        addBankInput4: 'Masukkan nomor rekening bank ',
        addBankInput5: 'Kode bank ',
        addBankInput6: 'Nama bank ',
        addBankInput7: 'Nama cabang ',
        addBankInput8: 'Nomor rekening bank ',
        example: 'Misalnya ',
        twBankName1: 'Cathay United Bank ',
        twBankName2: 'CTBC Bank ',
        twBankName3: 'Chunghwa Post ',
        twBankData1: 'Cathay United Bank ',
        twBankData2: 'Cabang Huashan ',
        thBankName1: 'Kasikorn Bank ',
        thBankName2: 'Krung Thai Bank ',
        thBankName3: 'Bangkok Bank ',
        thBankData1: 'Kasikorn Bank ',
        thBankData2: 'Kantor Bangkok ',
        toVerify: 'Verifikasi sekarang ',
        authenticationSuccessful: 'Verifikasi berhasil ',
        popPrompt: 'Menurut peraturan regulasi, mohon lanjutkan untuk menyelesaikan verifikasi identitas agar tidak mempengaruhi penarikan dana. ',
        popPrompt1: 'Tidak ada fungsi penarikan di negara/wilayah saat ini, untuk detailnya silakan hubungi CS ',
        cancel: 'Batalkan ',
        continue: 'Lanjutkan ',
        goLogin: 'Login sekarang ',
        determine: 'Yakin ',
        authenticationFail: 'Gagal verifikasi ',
        authenticationFailTitle1: 'Maaf, verifikasi Anda gagal, mohon unggah lagi ',
        authenticationFailTitle2: 'Silakan periksa 2 poin berikut sebelum mengunggah: ',
        authenticationFailTitle3: '1. Konfirmasikan bahwa Anda mengunggah KTP yang valid ',
        authenticationFailTitle4: '2. Informasi yang ditampilkan pada dokumen jelas dan lengkap ',
        returnModify: 'Kembali untuk mengubah ',
        onlineConsultation: 'Konsultasi online ',
        immediateDeposit: 'Deposit sekarang ',
    },
    accountCenter: {
        // 首页
        homeTitle1: 'Data pribadi ',
        miniAccount: 'Akun mini ',
        standardAccount: 'Akun standar ',
        highendAccount: 'Akun premium ',
        homeTitle2: 'Undang teman dan Dapatkan bonus ',
        homeTitle3: 'Setiap kali berhasil mengundang 1 teman untuk berdagang ',
        activityRules: 'Aturan Aktivitas ',
        viewRewards: 'Cek hadiah ',
        activityListTitle1: 'Anda mendapatkan ',
        activityListTitle2: 'Temanmu mendapatkan ',
        activityListTitle3: 'Bagikan tautan Anda: ',
        copyLink: 'Salin tautan ',
        copySucc: 'Salin berhasil ',
        myRewards: 'Bonus saya ',
        rewardTitle1: 'Jumlah bonus ',
        rewardTitle2: 'Akun trading teman ',
        rewardTitle3: 'Waktu registrasi teman ',
        rewardTitle4: 'Status bonus ',
        rewardState1: 'Telah Didistribusi ',
        rewardState2: 'Belum mencapai standar ',
        rewardState3: 'Tunggu mencapai stadar ',
        accountNumber: 'Akun trading: ',
        email: 'Email: ',
        phoneNumber: 'Nomor HP: ',
        residentialArea: 'Negara tempat tinggal: ',
        accountValue: 'Ekuitas akun ',
        creditGift: 'Bonus kredit ',
        activityReward: 'Bonus aktivitas ',
        availablePoints: 'Poin tersedia ',
        deposit: 'Deposit ',
        accountTips1: 'Saldo aktual (termasuk untung dan rugi dari pesanan komoditas terbuka) berubah dengan untung dan rugi dari pesanan komoditas terbuka, ekuitas akun bersifat sensitif terhadap waktu. Harap login platform trading untuk mengecek ekuitas sebenarnya. ',
        accountTips2: 'Total bonus kredit yang diperoleh dengan berpartisipasi dalam aktivitas bonus',
        accountTips3: 'Total bonus yang diperoleh dengan berpartisipasi dalam aktivitas ',
        accountTips4: 'Saldo poin yang tersedia untuk penukaran ',
        notice: 'Pengumuman ',
        // 入金页面
        withdraw: 'Penarikan ',
        capitalFlow: 'Rincian dana ',
        tradeQuery: 'Kueri trading ',
        depositTite1: 'Deposit akun ',
        depositTite2: 'Jumlah deposit ',
        depositTite3: 'Estimasi jumlah pembayaran ',
        depositTite4: 'Aktivitas bonus ',
        depositTite5: 'Metode deposit ',
        depositTite6: 'Pengenalan akun ',
        depositTite7: 'Keamanan dana ',
        depositTite8: 'Instruksi deposit ',
        depositTite9: 'Bank pembayaran ',
        depositTite10: 'Pilih bank ',
        depositTite11: 'Panduan operasi ',
        depositTite12: 'Estimasi pembayaran ',
        depositTite13: 'Deposit lalu berpartisipasi dalam aktivitas melalui CS sebelum dapatkan bonus! ',
        depositTite14: 'Metode deposit lain ',
        depositTite15: 'Pilih metode deposit ',
        depositTite16: '7-Eleven ',
        depositTite17: 'FamilyMart ',
        depositTite18: 'Hi-Life ',
        subDeposit: 'Submit deposit ',
        accountType: 'Jenis akun ',
        accountTypeTitle1: 'Deposit minimal ',
        accountTypeTitle2: 'Spread terendah ',
        fundSecurityList1: '0 biaya penanganan ',
        fundSecurityList2: 'Transfer instan ',
        fundSecurityList3: 'Simpan dana secara terpisah ',
        depositTips1: '1. Berapa jumlah deposit minimum?',
        depositTips2: '2. Bagaimana cara konversi kurs deposit?',
        depositTips3: '3. Apakah saya perlu membuka rekening USD untuk deposit?',
        depositTips4: '4. Berapa lama dana deposit akan masuk ke akun saya?',
        depositTips10: '5. Bolehkah saya deposit dengan rekening orang lain?',
        depositTips11: '6. Dana deposit akan disimpan di mana?',
        depositTips01: 'Jumlah deposit minimum: ',
        depositTips02: 'Jumlah pembayaran secara otomatis dikonversi sesuai dengan kurs tengah pada hari itu',
        depositTips03: 'Tidak, Anda bisa melakukan deposit dengan kartu bank/kredit Anda sendiri melalui online/mobile banking, dll. Mata uang yang perlu Anda bayarkan akan dikonversi sesuai dengan kurs pada saat itu berdasarkan jumlah deposit (USD) Anda submit. Dana akan otomatis disetorkan ke akun trading Anda setelah pembayaran berhasil.',
        depositTips04: 'Biasanya dana akan segera masuk akun; jika dananya belum masuk akun dalam 10 menit, silakan hubungi CS online untuk mengecek.',
        depositTips010: 'Tidak, untuk keamanan akun Anda, dana dari rekening bank orang lain tidak akan diterima.',
        depositTips011: 'Dana akan disimpan terpisah oleh pihak ketiga, untuk melindungi kepentingan nasabah.',
        depositTips5: '5. Untuk detail lebih lanjut tentang deposit dan cara operasinya, silakan klik di sini. ',
        depositTips6: '1. Jumlah transfer harus sesuai dengan jumlah deposit yang diajukan ',
        depositTips7: '2. Jangan transfer berulang ke rekening penerima yang sama ',
        depositTips8: 'Jangan ingatkan saya lain kali ',
        depositTips9: 'Transfer sekarang ',
        depositSucc: 'Berhasil deposit ',
        depositFail: 'Gagal deposit ',
        depositTime: 'Waktu deposit ',
        depositGuide: 'Panduan deposit ',
        dollar: 'USD ',
        singleLimit: 'Batas jumlah tunggal ',
        creditCardText1: 'Masukkan informasi kartu kredit ',
        creditCardText2: 'Masukkan nomor kartu kredit ',
        creditCardText3: 'Masukan nomor kartu kredit yang valid ',
        creditCardText4: 'Tanggal kedaluwarsa (Bulan/Tahun) ',
        creditCardText5: 'CVV ',
        creditCardText6: 'Masukkan tanggal kedaluwarsa kartu kredit ',
        creditCardText7: 'Masukkan CVV yang benar ',
        creditCardText8: 'Nama pemilik kartu ',
        creditCardText9: 'Nama depan ',
        creditCardText10: 'Nama keluarga ',
        creditCardText11: 'Masukkan nama depan Anda ',
        creditCardText12: 'Masukkan nama belakang Anda ',
        creditCardText13: '* Kartu kredit ini milik Anda dan digunakan atas nama Anda. ',
        creditCardText14: 'Langkah berikutnya ',
        // 入金转账页面
        onlineBankTitle1: 'Informasi rekening penerima ',
        onlineBankTitle2: 'Transfer uang dengan rekening bank Anda ',
        onlineBankTitle2CSV: 'Bayar dengan rekening bank Anda ',
        onlineBankTitle3: 'Silakan ',
        onlineBankTitle4: 'Selesaikan operasi dalam ',
        onlineBankTitle5: 'Panduan operasi: ',
        onlineBankTitle6: '1. Salin jumlah transfer, bank penerima, kode bank dan nomor rekening penerima ke mobile banking/online banking Anda untuk transfer uang pada bank yang sama atau antar bank； ',
        onlineBankTitle6CSV: '1. Masukkan jumlah dan kode pembayaran ke halaman pembayaran supermarket； ',
        onlineBankTitle7: '2. Jangan simpan informasi transfer untuk mencegah transfer berulang； ',
        onlineBankTitle7CSV: '2. Jangan simpan informasi pembayaran untuk mencegah bayar berulang； ',
        onlineBankTitle8: '3. Jika jumlah transfer tidak sesuai dengan jumlah yang diajukan, transfer gagal； ',
        onlineBankTitle9: '4. Selesaikan transfer dalam 15 menit, jika tidak, deposit gagal ',
        onlineBankTitle9CSV: '3.Selesaikan pembayaran dalam 15 menit, jika tidak, deposit gagal ',
        onlineBankTitle10: 'Dana disimpan terpisah di CM Trade, keamanannya terjamin. ',
        copy: 'Salin ',
        remittanceAmount: 'Jumlah transfer: ',
        remittanceAmountCSV: 'Jumlah pembayaran: ',
        collectionBank: 'Bank penerima: ',
        collectionBankCSV: 'Supermarket penerima: ',
        collectionAccount: 'Rekening penerima: ',
        collectionAccountCSV: 'Kode pembayaran: ',
        confirm: 'Konfirmasi ',
        //出金页面
        withdrawTitle1: 'Penarikan akun ',
        withdrawTitle2: 'Jumlah yang tersedia ',
        withdrawTitle3: 'Jumlah penarikan ',
        withdrawTitle4: 'Estimasi jumlah yang diterima ',
        withdrawTitle5: 'Nama rekening ',
        withdrawTitle6: 'Rekening bank penerima ',
        subWithdraw: 'Submit penarikan ',
        applySucc: 'Permintaan berhasil ',
        applyFail: 'Permintaan gagal ',
        applySuccStep1: 'Tunggu ditinjau ',
        applySuccStep2: 'Penanganan Bank ',
        applySuccStep3: 'Penarikan selesai ',
        applySuccStep4: 'Estimasi 1 hari kerja ',
        cancelWithdraw: 'Batalkan permintaan penarikan ',
        reminder: 'Catatan ',
        reminder1: 'Catatan: ',
        withdrawTips1: '1.Jika Anda berpartisipasi dalam aktivitas bonus，mengajukan penarikan berarti keluar dari aktivitasnya, maka bonus akan dipotong dan rabat untuk akumulasi lot trading tidak akan didapatkan ',
        withdrawTips2: '2.Sehubungan pengaruh penanganan beberapa bank, mungkin ada keterlambatan transfer, dan waktu transfer yang sebenarnya akan berlaku. ',
        withdrawTips3: 'Penarikan minimal ',
        withdrawTips4: 'Penarikan maksimal ',
        withdrawTips5: 'Jumlah penarikan melebihi jumlah yang tersedia ',
        withdrawTips6: 'Penarikan yang tersedia = margin bebas - kredit - 0,4  margin terpakai (0,4 margin terpakai sebagai rasio ketahanan dana)',
        understand: 'Mengerti ',
        //资金流水
        capitalFlowTitle1: 'Tipe: ',
        capitalFlowTitle2: 'Status: ',
        capitalFlowTitle3: 'Waktu: ',
        timeSelect1: '7 hari terakhir ',
        timeSelect2: 'Bulan ini ',
        timeSelect3: 'Bulan lalu ',
        timeSelect4: 'Setahun terakhir ',
        orderNumber: 'Nomor order ',
        money: 'Jumlah ',
        applyTime: 'Waktu permintaan ',
        state: 'Status ',
        type: 'Jenis ',
        home: 'Halaman pertama ',
        lastPage: 'Halaman akhir ',
        previousPage: 'Sebelumnya ',
        nextPage: 'Selanjutnya ',
        Jump: 'Lompat ',
        totalNumber: 'Total jumlah ',
        pageCount: 'Total halaman ',
        depositFlow: 'Detail deposit ',
        withdrawFlow: 'Detail penarikan ',
        completed: 'Sudah selesai ',
        notCompleted: 'Belum selesai ',
        closed: 'Tertutup ',
        untreated: 'Belum diproses ',
        paymentTime: 'Waktu pembayaran ',
        cancelWithdraw1: 'Batalkan permintaan ',
        rewardTime: 'Waktu distribusi bonus ',
        rewardType: 'Jenis bonus ',
        //交易查询
        tradeHistory: 'Riwayat trading ',
        pendingOrder: 'Kueri pending order ',
        holdOrder: 'Kueri posisi ',
        varieties: 'Jenis ',
        tradingVolume: 'Volume trading ',
        openingPrice: 'Harga buka ',
        openingTime: 'Waktu buka ',
        closingPrice: 'Harga tutup ',
        closingTime: 'Waktu tutup ',
        hangingOrderPrice: 'Harga pending order ',
        hangingOrderTime: 'Waktu pending order ',
        stopLoss: 'Stop loss ',
        stopSurplus: 'Take profit ',
        overnightInterest: 'Bunga semalam ',
        profit: 'Profit ',
        totalProfit: 'Total profit: ',
        totalVolume: 'Total volume trading: ',
        hand: 'Lot ',
        dataStatus1: 'Beli ',
        dataStatus2: 'Jual ',
        dataStatus3: 'Kredit ',
        dataStatus4: 'Beli-Stop loss ',
        dataStatus5: 'Beli-Stop limit ',
        dataStatus6: 'Jual-Stop loss ',
        dataStatus7: 'Jual-Stop limit ',
        dataStatus8: 'Deposit minimal ',
        dataStatus9: 'Deposit maksimal ',
        dataStatus10: 'Tukarkan poin dengan hadiah ',
        dataStatus11: 'Bonus rabat ',
        dataStatus12: 'Hadiah kuis EIA ',
        dataStatus13: 'Hadiah kuis non-farm ',
        dataStatus14: 'Belum ada data Anda ',
        //邀请活动规则
        inviteActivityTitle1: '1. Instruksi Undangan untuk Berpartisipasi ',
        inviteActivityTitle2: '2. Deskripsi orang yang diundang',
        inviteActivityTitle3: '3. Tindakan pencegahan',
        inviteRule1: '(1) Semua pengguna yang mendaftar akun penawaran nyata dapat berbagi aktivitas dan menjadi pengundang;',
        inviteRule2: '(2) Setiap kali pengundang mengundang pengguna untuk membuka akun nyata, orang yang diundang bisa mendapatkan hadiah $15 jika dia mengaktifkan akun dalam waktu 30 hari setelah membuka akun dan memperdagangkan 1 lot standar (diakumulasikan untuk variasi apa pun kecuali mata uang virtual);',
        inviteRule3: '(3) Hadiah undangan pengundang akan langsung didistribusikan ke akun perdagangan nyata yang sesuai dengan pengguna yang berpartisipasi dalam aktivitas undangan, dan tidak dapat diganti; ',
        inviteRule4: '(4) Pengundang dapat menerima hadiah undangan mingguan hingga $200, dan hadiah yang diperoleh oleh pengundang dan orang yang diundang dapat langsung digunakan untuk penarikan tunai atau perdagangan.',
        unInviteRule1: '(1) Orang yang diundang belum pernah membuka akun perdagangan perusahaan apa pun di perusahaan kami; ',
        unInviteRule2: '(2) Orang yang diundang harus mendaftar dan membuka akun melalui QR Code/tautan promosi yang dibagikan oleh pengundang, mengaktifkan akun dalam waktu 30 hari setelah membuka akun dan memperdagangkan 1 lot standar (diakumulasikan untuk variasi apa pun kecuali mata uang virtual), dan mendapatkan bonus $5 ;',
        noticeTip1: '(1) Karyawan kami dan keluarganya tidak memenuhi syarat untuk aktivitas ini, dan pembatalan dan pembukaan kembali akun tidak berlaku untuk aktivitas ini, dan perusahaan kami memiliki hak interpretasi akhir dari aktivitas tersebut; ',
        noticeTip2: '(2) Jika perusahaan kami mencurigai bahwa pengguna telah menyalahgunakan, mencoba menyalahgunakan, atau menyalahgunakan manfaat, kami berhak membatalkan hadiah yang diperoleh dari akun pengguna.',
    },
    accountInfor: {
        //账户信息
        accountHome: 'Beranda Akun ',
        goldInGoldOut: 'Deposit/Penarikan ',
        accountData: 'Informasi Akun ',
        accountDateTitle1: 'Verifikasi Identitas ',
        accountDateTitle2: 'Manajemen Rekening/Kartu Bank ',
        accountDateTitle3: 'Email ',
        accountDateTitle4: 'Nomor HP ',
        accountDateTitle5: 'Ubah kata sandi ',
        nickname: 'Nama panggilan: ',
        realName: 'Nama asli: ',
        notCertified: 'Belum diverifikasi ',
        underReview: 'Sedang ditinjau ',
        modifyNickname: 'Ubah nama panggilan ',
        inputNickname: 'Masukkan nama panggilan ',
        Submit: 'Submit ',
        bind: 'Mengikat ',
        identityTitle: 'Anda telah menyelesaikan verifikasi identitas, dapatkan bonus besar setelah deposit dan trading~ ',
        bindBankTitle1: 'Anda telah mengikat rekening/kartu bank, jika perlu mengubah, klik tombol ubah ',
        bindBankTitle2: 'Nama bank ',
        bindBankTitle3: 'Kode bank ',
        bindBankTitle4: 'Nama cabang ',
        bindBankTitle5: 'Nomor rekening bank ',
        modifyUserHead: 'Ubah avatar ',
        bindEmailTitle1: 'Saat ini Anda belum mengikat email, kami akan mengirimkan promosi dan berita pasar mingguan melalui email. ',
        bindEmailTitle2: 'Mengikat email ',
        bindEmailTitle3: 'Berhasil mengikat email ',
        bindEmailTitle4: 'Berhasil mengubah email ',
        bindEmailTitle5: 'Mengikat email Anda untuk menjaga keamanan akun dan menerima pemberitahuan email kami ',
        bindPhoneTitle1: 'Saat ini Anda belum mengikat nomor HP, kami akan mengirimkan promosi dan pemberitahuan melalui SMS ',
        bindPhoneTitle2: 'Mengikat nomor HP Anda untuk menjaga keamanan akun dan menerima pemberitahuan SMS kami ',
        bindPhoneTitle3: 'Berhasil mengikat nomor HP ',
        bindPhoneTitle4: 'Berhasil mengubah nomor HP ',
        bindPhoneTitle5: 'Mengikat nomor HP ',
        modifyPassTips1: 'Masukkan sandi saat ini',
        modifyPassTips2: '6-15 kombinasi alfanumerik kata sandi baru',
        modifyPassTips3: 'Masukkan kata sandi baru lagi',
        modifyPassTips4: 'Ubah kata sandi berhasil ',
    },
    footer:{
        navText1: 'Tentang kami ',
        navText2: 'Perjanjian pelanggan ',
        navText3: 'Pengungkapan risiko ',
        navText4: 'Penafian ',
        navText5: 'Kebijakan privasi ',
        navText6: 'Keamanan dana ',
        navText7: 'Pusat bantuan ',
        copyright: 'Hak Cipta © 2023 CM Trade dilindungi undang-undang, dilarang untuk direproduksi ',
        suText1: 'Buka akun gratis ',
        suText2: 'Konsultasi online ',
    },
    appH5: {
        regSuccessText1: 'Selamat, pendaftaran Anda berhasil ',
        regSuccessText2: 'Bonus pendaftaran Anda telah didistribusi ',
        regSuccessText3: 'Deposit sekarang ',
        regSuccessText4: 'Selesaikan verifikasi identitas, dapatkan lebih banyak hak eksklusif ',
    }   
}