<template>
  <!-- pc端菜单栏 -->
  <div class="hide-pc">
    <div class="left-menu">
      <router-link :to="'/'" :class="isSelect=='/'?'active':''" class="nav-list">
        <img v-if="isSelect == '/'" src="@/assets/images/account/menu1s.png" alt="">
        <img v-else src="@/assets/images/account/menu1.png" alt="">
        <p>{{$t('accountInfor.accountHome')}}</p>
      </router-link>
      <router-link :to="'/deposit'" :class="isSelect=='/deposit'?'active':''" class="nav-list">
        <img v-if="isSelect == '/deposit'" src="@/assets/images/account/menu2s.png" alt="">
        <img v-else src="@/assets/images/account/menu2.png" alt="">
        <p>{{$t('accountInfor.goldInGoldOut')}}</p>
      </router-link>
      <router-link :to="'/personalData'" :class="isSelect=='/personalData'?'active':''" class="nav-list">
        <img v-if="isSelect == '/personalData'" src="@/assets/images/account/menu3s.png" alt="">
        <img v-else src="@/assets/images/account/menu3.png" alt="">
        <p>{{$t('accountInfor.accountData')}}</p>
      </router-link>
    </div>
  </div>
  <!-- m端菜单栏 -->
  <div class="hide-m">
    <div class="footer-menu">
      <div class="nav-list-box">
        <router-link :to="'/'" :class="isSelect=='/'?'active':''" class="nav-list">
          <img v-if="isSelect == '/'" src="@/assets/images/account/menu1s.png" alt="">
          <img v-else src="@/assets/images/account/menu1.png" alt="">
          <p>{{$t('accountInfor.accountHome')}}</p>
        </router-link>
      </div>
      <div @click.stop="footerMenuState1 = !footerMenuState1,footerMenuState2 = false" class="nav-list-box">
        <div :class="isSelect=='/deposit'?'active':''" class="nav-list">
          <img v-if="isSelect == '/deposit'" src="@/assets/images/account/menu2s.png" alt="">
          <img v-else src="@/assets/images/account/menu2.png" alt="">
          <p>{{$t('accountInfor.goldInGoldOut')}}</p>
        </div>
        <div v-show="footerMenuState1" class="link-menu">
            <router-link :to="'/deposit'">{{$t('accountCenter.deposit')}}</router-link>
            <router-link :to="'/withdraw'">{{$t('accountCenter.withdraw')}}</router-link>
            <router-link :to="'/capitalFlow'">{{$t('accountCenter.capitalFlow')}}</router-link>
            <router-link :to="'/tradeHistory'">{{$t('accountCenter.tradeQuery')}}</router-link>
          </div>
      </div>
      <div @click.stop="footerMenuState2 = !footerMenuState2,footerMenuState1 = false" class="nav-list-box">
        <div :class="isSelect=='/personalData'?'active':''" class="nav-list">
          <img v-if="isSelect == '/personalData'" src="@/assets/images/account/menu3s.png" alt="">
          <img v-else src="@/assets/images/account/menu3.png" alt="">
          <p>{{$t('accountInfor.accountData')}}</p>
        </div>
        <div v-show="footerMenuState2" class="link-menu">
            <router-link :to="'/personalData'">{{$t('accountCenter.homeTitle1')}}</router-link>
            <router-link :to="'/authentication'">{{$t('accountInfor.accountDateTitle1')}}</router-link>
            <router-link :to="'/bankCardVerification'">{{$t('accountInfor.accountDateTitle2')}}</router-link>
            <router-link :to="'/mailbox'">{{$t('accountInfor.accountDateTitle3')}}</router-link>
            <router-link :to="'/phoneNumber'">{{$t('accountInfor.accountDateTitle4')}}</router-link>
            <router-link :to="'/modifyPassword'">{{$t('accountInfor.accountDateTitle5')}}</router-link>
          </div>
      </div>
      
    </div>
  </div>
</template>

<script>


export default{
  data() {
    return {
      isSelect: '',  
      footerMenuState1:false,
      footerMenuState2:false  
    }
  },
  created() {
      const _path = this.$route.path;
      if(_path == '/'){
        this.isSelect = '/'; 
      } 
      if(_path == '/personalData' || _path == '/authentication' || _path == '/bankCardVerification' || _path == '/mailbox' || _path == '/phoneNumber' || _path == '/modifyPassword'){
        this.isSelect = '/personalData'; 
      }
      if(_path == '/deposit' || _path == '/withdraw' || _path == '/capitalFlow' || _path == '/tradeHistory' || _path == '/onlineBank'){
        this.isSelect = '/deposit'; 
      } 
  },
  methods: {

    // m端底部导航菜单
    footerMenu(){
      this.footerMenuState2 = false
      this.footerMenuState1 = false
    }
  },
  mounted () {
    document.addEventListener('click', this.footerMenu)
  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .left-menu{width: 200px;min-width: 200px; height: 1050px;background: #313238;display: flex;flex-direction: column;justify-content: flex-start;align-items: center;}
  .left-menu .nav-list{width:100%;height: 125px;display: flex;flex-direction: column;justify-content: center;align-items: center;}
  .left-menu .nav-list.router-link-active{background: #3d3e45;}
  .left-menu .nav-list.active{background: #3d3e45;}
  .left-menu .nav-list img{width:32px;height: 32px;}
  .left-menu .nav-list p{font-size: 18px;color: #b2b2b2;margin-top: 10px;}
  .left-menu .nav-list.router-link-active p{color: #f9d44c;}
  .left-menu .nav-list.active p{color: #f9d44c;}

  @media screen and (max-width: 768px) {
  .footer-menu{position: fixed;bottom: 0;left: 0; width: 100%;min-width: 100%; height: 4.5rem;z-index: 9; background: #313238;display: flex;flex-direction: row;justify-content: space-between;align-items: center;}
  .footer-menu .nav-list-box{width: 33%;height: 100%;position: relative;}
  .footer-menu .nav-list-box .link-menu{padding: 1rem 0; position: absolute;bottom: 4.2rem; left: 0;width: 100%;background: #313238;display: flex;flex-direction: column;justify-content: center;align-items: center;}
  .footer-menu .nav-list-box .link-menu a{font-size: 0.7rem;color: #b2b2b2;line-height: 0.9rem; margin-top: 0.7rem;text-align: center;}
  .footer-menu .nav-list-box .link-menu .router-link-active{color: #f9d44c;}
  .footer-menu .nav-list{width:100%;height: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;}
  .footer-menu .nav-list.router-link-active{background: #3d3e45;}
  .footer-menu .nav-list.active{background: #3d3e45;}
  .footer-menu .nav-list img{width:1.2rem;height: 1.2rem;}
  .footer-menu .nav-list p{font-size: 0.7rem;color: #b2b2b2;margin-top: 0.5rem;line-height: 0.9rem;text-align: center;}
  .footer-menu .nav-list.active p{color: #f9d44c;}

  }
</style>
