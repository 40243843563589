<template>
  
  <router-view/>
</template>

<script>

import {getCookie, getQueryString, setCookie} from '@/util/cookie';
import {Config, HEADER_USER_TOKEN} from '@/util/const.js';
export default{
  created() {
    Config.userToken = getCookie(HEADER_USER_TOKEN);
    let source = getQueryString('utm_source');
    if (source){
      setCookie('loadUrl',window.location.href,7);
      setCookie('utm_source',source,7);
    }
  },
  methods: {
    
  }
  
}
</script>

<style>
   @import url("./assets/css/base.css");
</style>
