export default {
    header: {
        login: 'Đăng nhập ',
        Logout: 'Đăng xuất ',
        register: 'Đăng ký ',
        appDownload: 'Cài đặt ứng dụng ',
        headerDow: 'tải APP lãnh thưởng tiền ',
	    headerDowBtn: 'Tải xuống',
    },
    loginRegister: {
        // 登录注册
        loginLeftTitle1: 'phúc lợi nhận thưởng ',
        loginLeftTitle2: 'tiền thưởng tối thiểu là＄120, đối với giao dịch trở lại đầu tư ',
        loginRightTitle1: 'Bạn đã có tài khoản giao dịch chưa? ',
        loginRightTitle2: 'đăng ký một tài khoản ',
        loginRightTitle3: 'mã xác nhận đăng nhập bằng số điện thoại ',
        loginRightTitle4: 'quên mật khẩu rồi sao ? ',
        loginInput1: 'Tài khoản giao dịch / Tài khoản email',
        loginInput2: 'Mật khẩu',
        regLeftTitle1: 'Các bước đơn giản, hoàn thành mở tài khoản ',
        regLeftTitle2: 'Tiền hoa hồng ở mức chênh lệch thấp hiệu suất cao trong nền tảng giao dịch ',
        loginTitle1:'Đã đăng ký chưa ? ',
        loginTitle2:'Đăng nhập',
        loginTitle3:'Vẫn chưa có tài khoản ? ',
        loginTitle4:'Đăng ký',
        loginTitle5:'Mã mời（Bắt buộc）',
        loginTitle6:'Tôi đồng ý',
        retrievePassTitle1:'Điện thoại đặt lại mật khẩu',
        retrievePassTitle2:'Đặt lại mật khẩu hộp thư',
        mobileRegRightTitle1: 'Đăng ký/ đăng nhập mật khẩu ',
        mobileRegRightTitle2: 'điện thoại chưa đăng ký vẫn tự động tạo tài khoản ',
        moblieInput:'Số điện thoại di động',
        codeInput:'Mã xác nhận',
        getCode:'Nhận được',
        resend:'gửi lại ',
        regLogin:'Đăng ký/Đăng nhập ',
        regButtomTitle1:'hoặc sử dụng bằng cách thiết lập tài khoản ',
        regButtomTitle2:'Nhấp vào đăng ký/đăng nhập có nghĩa là bạn đã đồng ý ',
        agreement:'<<Thỏa thuận người dùng>>',
        emailRegRightTitle1: 'đăng ký email ',
        countryInput:'Chọn quốc gia/khu vực cư trú ',
        emailInput:'Tài khoản email',
        passInput:'Mật khẩu phải bao gồm 6-15 chữ cái số thứ tự',
        // 注册成功
        loginSuccessful:'đăng ký thành công ',
        loginSuccTitle:'Nạp tiền ngay, để hưởng ngay quyền ưu đãi tổng giá trị cao tiền thưởng đô la mỹ ',
        loginSuccBtn1:'Lập tức gửi tiền và nhận ngay ',
        loginSuccBtn2:'Xác nhận xong danh tính ',
        loginSuccEmail:'email của bạn đã đăng ký, vui lòng đăng nhập trước ',
        // 忘记密码
        retrievePassword:'lặp lại mật khẩu   ',
        retrievePassTab1:'lặp lại mật khẩu email ',
        retrievePassTab2:'Đặt lại mật khẩu số điện thoại  ',
        resetPassword:'Xác nhận',
        resetPasswordTitle:'cài lại mật khẩu thành công, đăng nhập lại ',
        returnLogin:'Đăng nhập lại ',
        checkPrompt1:'tài khoản email không thể để trống ',
        checkPrompt2:'mật khẩu không được để trống ',
        checkPrompt3:'Hình thức mật khẩu hiện tại không đúng ',
        checkPrompt4:'Số điện thoại không được để trống ',
        checkPrompt25:'Hãy điền số điện thoại mới  ',
        checkPrompt5:'Hình thức số điện thoại không đúng ',
        checkPrompt6:'mã xác minh không được để trống ',
        checkPrompt7:'Hãy chọn khu vực quốc gia ',
        checkPrompt27:'Hãy chọn tên ngân hàng ',
        checkPrompt8:'email không được để trống ',
        checkPrompt26:'Hãy điền địa chỉ email mới ',
        checkPrompt9:'hình thức email không đúng ',
        checkPrompt10:'Mật khẩu hiện tại không được để trống ',
        checkPrompt11:'Hình thức mật khẩu hiện tại không chính xác ',
        checkPrompt12:'Mật khẩu mới không được để trống ',
        checkPrompt13:'Hình thức mật khẩu không đúng ',
        checkPrompt14:'Xác nhận hình thức mật khẩu không đúng ',
        checkPrompt15:'Xác nhận mật khẩu không để trống ',
        checkPrompt16:'mật khẩu mới 2 lần không đúng ',
        checkPrompt17:'số tài khoản ngân hàng không được để trống ',
        checkPrompt18:'Tên chi nhánh không được bỏ trống ',
        checkPrompt19:'tên ngân hàng không được để trống ',
        checkPrompt20:'số tài khoản ngân hàng không được để trống ',
        checkPrompt21:'hóa đơn ngân hàng không để trống ',
        checkPrompt22:'biệt danh không thể để trống ',
        checkPrompt23:'Bức ảnh mặt trước chứng minh thư không được để trống ',
        checkPrompt24:'Bức ảnh mặt sau chứng minh thư không được để trống ',
    },
    perfectData: {
        // 完善资料
        perfectDataLeftTitle1:'Kích hoạt tài khoản thực ',
        perfectDataLeftTitle2:'Nên giám sát yêu cầu, cần hoàn thành chứng thực danh tính mới có thể thực hiện thao tác rút tiền ',
        perfectData:'Hoàn chỉnh tư liệu ',
        step1Title1:'Điền thông tin cá nhân  ',
        identityAuthentication:'Xác nhận thân phận ',
        step2Title1:'Theo yêu cầu giám sát, bạn cần phải hoàn thành các xác thực sau: ',
        step2Title2:'①Xác thực ',
        step2Title3:'② Thêm thẻ ngân hàng ',
        step2Title4:'Xác nhận thân phận ',
        step3Title1:'Nạp tiền để nhận tiền thưởng ',
        step3Title2:'Số tiền có thể đa có thể nhận là USD2400 ',
        step3Title3:'Nạp tiền ngay, để các phần quà tiền thưởng hấp dẫn ',
        step3Title4:'khu vực quốc gia ',
        perfectDataTips:'Quốc gia/khu vực, quốc tịch và thuế quốc của tôi tương đồng  ',
        nextStep:'bước tiếp theo ',
        //身份认证
        identityAuthenTitle1:'Nên giám sát yêu cầu, kích hoạt gửi tiền bà chức năng giao dịch hoán thành xác nhận danh tính. ',
        identityAuthenTitle2:'Nhấp để tải ảnh chứng minh thư mặt trước ',
        identityAuthenTitle3:'Nhấp để tải ảnh chứng minh thư mặt sau ',
        // 个人信息确认
        informationConfirmTitle1:'Xác nhận thông tin cá nhân ',
        informationConfirmTitle2:'bạn có thể sử dụng tiến hành xác thực thông tin cá nhân, vui lòng đảm bảo rằng thông tin là chính xác và phù hợp với những gì được hiển thị trên thẻ cá nhân của bạn. ',
        informationConfirmTitle3:'Hoàn chỉnh tên: ',
        informationConfirmTitle4:'Số chứng minh thư: ',
        informationConfirmTitle5:'quyền thông tin tiến hành sử dụng nội dung phạm vi của chính sách riêng tư  ',
        modify:'Thay đổi ',
        // 添加银行卡
        addBankTitle1:'Thêm thẻ hoặc tài khoản ngân hàng ',
        addBankTitle2:'Nên giám sát yêu cầu, vì để thuận lợi và an toàn số tiền của bạn khi rút ra, vui lòng thêm tài khoản/thẻ ngân hàng của bạn. ',
        addBankTitle3:'Vui lòng tải lên sổ tiết kiêmh hoặc thôn tin tài khoản ngân hàng ',
        addBankTitle4:'Yêu cầu bao gồm: Logo/Tên ngân hàng, tài khoản giữ họ tên và hoàn thành số tài khoản ngân hàng ',
        addBankInput1:'Hãy nhập số tài khoản ngân hang ',
        addBankInput2:'Hãy nhập vào tên thẻ ngân hàng ',
        addBankInput3:'Hãy nhập tên chi nhánh ',
        addBankInput4:'Hãy nhập số tài khoản ngân hàng ',
        addBankInput5:'số tài khoản ngân hàng  ',
        addBankInput6:'tên ngân hàng ',
        addBankInput7:'Tên chi nhánh ',
        addBankInput8:'tài khoản ngân hàng ',
        example:'ví dụ ',
        twBankName1:'Quốc Thái Thế Hoa ',
        twBankName2:'Trung Quốc ủy thác ',
        twBankName3:'Hòm thư của Trung Quốc ',
        twBankData1:'Ngân hàng Quốc Thái Thế Hoa ',
        twBankData2:'Chi nhánh ở Hoa Sơn ',
        thBankName1:'Ngân hàng giao dịch Thái Lan ',
        thBankName2:'Ngân hàng Thái Lan ',
        thBankName3:'Ngân hàng Bangkok Đại chúng trách nhiệm hữu hạn ',
        thBankData1:'Ngân hàng giao dịch Thái Lan ',
        thBankData2:'văn phòng bangkok ',
        toVerify:'Đi xác minh ',
        authenticationSuccessful:'Xác nhận thành công ',
        popPrompt: 'Nên giám sát yêu cầu,vui lòng tiếp tục hoàn thành chứng thực danh tính, để không ảnh hưởng chức năng rút tiền. ',
        popPrompt1: 'Hiện tại tạm thời không hỗ trợ rút tiền tại khu vực/quốc gia, vui lòng liên hệ dịch vụ khách hàng để được tư vấn thêm ',
        cancel: 'Hủy bỏ ',
        continue: 'Tiếp tục ',
        goLogin: 'Đi đăng nhập ',
        determine: 'Xác định ',
        authenticationFail:'Xác nhận thất bại ',
        authenticationFailTitle1:'Thành thật xin lỗi, Xác thực danh tích của bạn không thành công, bạn vui lòng tải lại ',
        authenticationFailTitle2:'Trước khi tải lên hãy chú ý kiểm tra 2 điểm sau: ',
        authenticationFailTitle3:'1. Đảm bảo rằng chứng minh nhân dân của bạn tải lên là hợp lệ ',
        authenticationFailTitle4:'2. Giấy tờ hiện lên cần phải ghi rõ đầy đủ thông tin  ',
        returnModify: 'Sửa đổi lại ',
        onlineConsultation: 'Trò chuyện ',
        immediateDeposit: 'Lập tức gửi tiền ',
    },
    accountCenter: {
        // 首页
        homeTitle1: 'Dữ liệu cá nhân ',
        miniAccount: 'tài khoản phụ ',
        standardAccount: 'Tài khoản tiêu chuẩn ',
        highendAccount: 'Tài khoản cao cấp ',
        homeTitle2: 'kiến nghị khách hàng kiếm tiền đô la mỹ ',
        homeTitle3: 'đối với mỗi lần giới thiệu cho 1 bạn bè giao dịch thành công  ',
        activityRules: 'Nguyên tắc hoạt động ',
        viewRewards: 'Xem xét khen thưởng ',
        activityListTitle1: 'bạn có thể nhận được ',
        activityListTitle2: 'bạn bè của bạn có thể nhận được ',
        activityListTitle3: 'Chia sẻ liên kết của bạn: ',
        copyLink: 'Sao chép liên kết ',
        copySucc: 'Sao chép thành công ',
        myRewards: 'phần thưởng của tôi ',
        rewardTitle1: 'Giá trị phần thưởng ',
        rewardTitle2: 'Tài khoản giao dịch bạn bè ',
        rewardTitle3: 'Thời gian đăng ký bạn bè ',
        rewardTitle4: 'Trạng thái nhận thưởng ',
        rewardState1: 'đã xử lý ',
        rewardState2: 'chưa đạt mục tiêu ',
        rewardState3: 'Gần đạt tiêu chuẩn ',
        accountNumber: 'Tài khoản giao dịch: ',
        email: 'Email: ',
        phoneNumber: 'Số điện thoại： ',
        residentialArea: 'địa chỉ khu vực: ',
        accountValue: 'giá trị ròng tài khoản ',
        creditGift: 'Tiền thưởng tín dụng ',
        activityReward: 'Hoạt động nhận thưởng ',
        availablePoints: 'điểm tích lũy khả dụng ',
        deposit: 'Nạp tiền ',
        accountTips1: 'Số dư thực tế thay đổi và lãi lỗ của nắm giữ cổ phiếu đặt hàng và tài khoản có giá trị ròng vẫn còn thời gian hiệu lực（bao gồm lãi lỗ của các đơn hàng chưa mở vị thế ）,vui lòng đăng nhập nền tảng giao dịch để kiểm tra xem giá trị ròng thực tế. ',
        accountTips2: 'Tham gia hoạt động nhận thưởng có thể có tổng tiền tín dụng  ',
        accountTips3: 'tổng hợp tài khoản người dùng tham gia hoạt động tiền nhận thưởng  ',
        accountTips4: 'Điểm số dư để đổi lấy tiền thưởng ',
        notice: 'Thông báo ',
        // 入金页面
        withdraw: 'Rút tiền  ',
        capitalFlow: 'Dòng lưu tiền ',
        tradeQuery: 'Kiểm tra giao dịch ',
        depositTite1: 'Nạp tiền vào tài khoản ',
        depositTite2: 'Số tiền nạp vào ',
        depositTite3: 'dự tính tổng tiền thanh toán ',
        depositTite4: 'hoạt động nhận thưởng ',
        depositTite5: 'hình thức nạp tiền ',
        depositTite6: 'Giới thiệu tài khoản ',
        depositTite7: 'bảo mật số iền ',
        depositTite8: 'Những điều cần biết khi nạp tiền ',
        depositTite9: 'ngân hàng thanh toán ',
        depositTite10: 'Hãy chọn ngân hàng ',
        depositTite11: 'Thao tác chỉ dẫn ',
        depositTite12: 'dự tính thanh toán ',
        depositTite13: 'Sau khi tiền thưởng cần nạp vào liên hệ dịch vụ đăng ký nhận hoạt động ! ',
        depositTite14: 'Càng nhiều phương thức nạp tiền ',
        depositTite15: 'Chọn phương thức nộp tiền ',
        depositTite16: '7-11 cửa hàng  ',
        depositTite17: 'Toàn bộ cửa hàng ',
        depositTite18: 'mô hình kinh doạn Laerfu ',
        subDeposit: 'Nạp tiền ',
        accountType: 'loại tài khoản ',
        accountTypeTitle1: 'Số tiền đầu tư tối thiểu ',
        accountTypeTitle2: 'chênh lệch tối thiểu ',
        fundSecurityList1: 'Lệ phí thủ tục ',
        fundSecurityList2: 'Chuyển khoản kịp thời ',
        fundSecurityList3: 'Lưu ký độc lập ',
        depositTips1: '1. Số tiền gửi tối thiểu là bao nhiêu? ',
        depositTips2: '2. Tỷ giá tiền gửi quy đổi theo tiêu chuẩn nào? ',
        depositTips3: '3. Tôi có cần mở tài khoản USD để gửi tiền không? ',
        depositTips4: '4. Mất bao lâu để tài khoản đến sau khi gửi tiền? ',
        depositTips10: '5. Tôi có thể sử dụng tài khoản của người khác để gửi tiền không? ',
        depositTips11: '6. Tiền được gửi ở đâu sau khi gửi tiền? ',
        depositTips01: 'Số tiền gửi tối thiểu: ',
        depositTips02: 'dựa tổng tiền thanh toán ngày hôm nay tự động chuyển đổi tỉ giá hối đoái trong gian ',
        depositTips03: 'Không, bạn có thể sử dụng thẻ ngân hàng hoặc thẻ tín dụng của chính mình để gửi tiền thông qua ngân hàng trực tuyến hoặc chuyển khoản ngân hàng di động, v.v. Loại tiền bạn cần thanh toán sẽ được quy đổi theo tỷ giá hối đoái tại thời điểm đó dựa trên số tiền gửi (USD) bạn đã gửi và thanh toán sẽ được thực hiện. Tiền sẽ được tự động gửi vào tài khoản giao dịch của bạn sau khi thành công. ',
        depositTips04: 'Trong trường hợp bình thường, tài khoản sẽ được ghi có ngay lập tức; nếu tài khoản giao dịch không đến trong vòng 10 phút, vui lòng liên hệ với dịch vụ khách hàng trực tuyến để được yêu cầu thủ công. ',
        depositTips010: 'Không, để bảo mật tài khoản của bạn, tiền từ tài khoản ngân hàng phi cá nhân không được chấp nhận. ',
        depositTips011: 'Lưu ký độc lập, và được lưu giữ dưới hình thức ủy thác lưu ký, nhằm bảo vệ quyền lợi của khách hàng. ',
        depositTips5: '5. Để hiểu rõ hơn nữa cách thức nạp tiền, bạn vui lòng nhấp vào đay. ',
        depositTips6: '1. Số tiền chuyển khoản phải cùng giá tiền gửi đã đăng ký ',
        depositTips7: '2. Vui lòng không chuyển khoản lại nhiều lần vào cùng một tài khoản ',
        depositTips8: 'lần sau không cần nhắc nữa ',
        depositTips9: 'Đi chuyển khoản ',
        depositSucc: 'nạp tiền thành công ',
        depositFail: 'Nạp tiền thất bại ',
        depositTime: 'Thời gian nạp tiền ',
        depositGuide: 'Hướng dẫn nạp tiền ',
        dollar: 'đô ',
        singleLimit: 'Giới hạn giao dịch đơn lẻ ',
        creditCardText1: 'Hãy nhập thông tin thẻ tín dụng ',
        creditCardText2: 'Hãy nhập mã thẻ tín dụng ',
        creditCardText3: 'Hãy nhập số thẻ tín dụng còn hiệu lực ',
        creditCardText4: 'thời gian còn hạn (tháng/năm) ',
        creditCardText5: 'Mã bảo mật CVV ',
        creditCardText6: 'Hãy nhập thẻ tín dụng còn hiệu lực ',
        creditCardText7: 'Hãy nhập chính xác mã an toàn CVV ',
        creditCardText8: 'Tên khách hàng trong thẻ chức vụ ',
        creditCardText9: 'tên ',
        creditCardText10: 'Họ Thị ',
        creditCardText11: 'Hãy nhập tên của bạn ',
        creditCardText12: 'Hãy nhập họ của bạn ',
        creditCardText13: '*Thẻ tín dụng này phải là của bạn, đồng thời mang tên của chủ thẻ ',
        creditCardText14: 'bước tiếp theo ',
        // 入金转账页面
        onlineBankTitle1: 'Thông tin số tài khoản nhận tiền ',
        onlineBankTitle2: 'Bạn cần sử dụng tài khoản ngân hàng của mình để chuyển tiền ',
        onlineBankTitle2CSV: 'Bạn cần sử dụng tài khoản ngân hàng của mình để thanh toán ',
        onlineBankTitle3: 'Xin vui lòng ',
        onlineBankTitle4: 'hoàn thành thao tác nội bộ ',
        onlineBankTitle5: 'Hệ thống vận hàng ',
        onlineBankTitle6: '1. Hãy sao chép số tiền chuyển khoản, ngân hàng thụ hưởng, mã ngân hàng, người thụ hưởng vào ngân hàng số/ngân hàng điện của bạn để thực hiện thao tác chuyển khoản trong cùng một ngân hàng hay liên ngân hàng; ',
        onlineBankTitle6CSV: '1. Hãy nhập vào số tiền và mã thanh toán vào trang cửa hàng để được thanh toán; ',
        onlineBankTitle7: '2. Vui lòng không lưu lại thông tin khi đang tiến hành thanh toán nhiều lần; ',
        onlineBankTitle7CSV: '2. Vui lòng không lưu lại thông tin chuyển khoản khi đang tiến hành thanh toán nhiều lần; ',
        onlineBankTitle8: '3. Nếu số tiền chuyển khoản không cùng với số tiền đăng ký nạp vào trong tài khoản, thì chuyển khoản sẽ không thành công; ',
        onlineBankTitle9: '4. Vui lòng hoàn thành chuyển khoản trong vòng 15 phút, nếu không việc nạp tiền của bạn sẽ không thành công ',
        onlineBankTitle9CSV: '3. Vui lòng hoàn thành việc thanh toán trong vòng 15 phút, nếu không sẽ nạp tiền không thành công ',
        onlineBankTitle10: 'Tiền gửi riêng trong CM Trade, bạn có thể yên tâm thanh toán ',
        copy: 'Sao chép ',
        remittanceAmount: 'Giá trị chuyển khoản ',
        remittanceAmountCSV: 'Tiền thanh toán: ',
        collectionBank: 'Nhận tiền ngân hàng: ',
        collectionBankCSV: 'Nhận tiền cửa hàng: ',
        collectionAccount: 'Số tài khoản nhận tiền: ',
        collectionAccountCSV: 'Mã thanh toán: ',
        confirm: 'Xác nhận ',
        //出金页面
        withdrawTitle1: 'rút tiền tài khoản ',
        withdrawTitle2: 'Có thể lãnh tiền ',
        withdrawTitle3: 'Giá trị tiền rút ',
        withdrawTitle4: 'dự tính tiền đến tài khoản ',
        withdrawTitle5: 'họ và tên tài khoản ',
        withdrawTitle6: 'Tài khoản ngân hàng tiếp nhận ',
        subWithdraw: 'Rút tiền đã nộp ',
        applySucc: 'Đăng ký thành công ',
        applyFail: 'Đăng ký thất bại  ',
        applySuccStep1: 'Chờ phê duyệt ',
        applySuccStep2: 'chấp nhận ngân hàng ',
        applySuccStep3: 'Hoàn thành rút tiền ',
        applySuccStep4: 'dự tính một ngày làm việc ',
        cancelWithdraw: 'Hủy bỏ đăng ký rút tiền ',
        reminder: 'Xin lưu ý ',
        reminder1: 'Xin lưu ý: ',
        withdrawTips1: '1. Nếu tham gia hoạt động nhận thưởng, khi đăng ký rút tiền mặc định bạn sẽ không được tham gia hoạt động này, lô lũy kế và tiền thưởng sẽ bị khấu trừ bạn, không nhận được chiết khấu. ',
        withdrawTips2: '2. Do chịu ảnh hưởng của các ngân hàng, khi chuyển tiền sẽ xuất hiện tình trạng thanh toán chậm trễ hơn so với thời gian thực tế. ',
        withdrawTips3: 'số tiền rút tối thiểu ',
        withdrawTips4: 'Số tiền rút tôi đa ',
        withdrawTips5: 'Tiền rút ra vượt quá tiền thu ',
        withdrawTips6: 'Vốn khả dụng=khoản thanh toán tạm ứng khả dụng-tín dụng- 0,4 *thanh toán tạm ứng đã sử dụng (0,4 *thanh toán tạm ứng đã sử dụng là tỷ lệ kho chống lửa có vốn khả dụng ) ',
        understand: 'Tìm hiểu ',
        //资金流水
        capitalFlowTitle1: 'Loại hình: ',
        capitalFlowTitle2: 'trạng thái: ',
        capitalFlowTitle3: 'Thời gian: ',
        timeSelect1:'Gần 7 ngày ',
        timeSelect2:'Tháng này ',
        timeSelect3:'Tháng trước ',
        timeSelect4:'Gần một năm ',
        orderNumber: 'ID Vị thế ',
        money: 'Số tiền ',
        applyTime: 'Thời gian đăng ký  ',
        state: 'trạng thái ',
        type: 'Loại hình ',
        home: 'Trang chủ ',
        lastPage: 'trang cuối ',
        previousPage: 'Trang trước ',
        nextPage: 'trang tiếp theo ',
        Jump: 'Chuyển qua ',
        totalNumber: 'tổng số điều kiện ',
        pageCount: 'tổng số trang ',
        depositFlow: 'Lượng tiền gửi ',
        withdrawFlow: 'Mức tiền rút ra  ',
        completed: 'đã hoàn thành ',
        notCompleted: 'chưa hoàn thành ',
        closed: 'đã đóng ',
        untreated: 'chưa giải quyết ',
        paymentTime:'thời gian thanh toán ',
        cancelWithdraw1:'Đăng ký rút tiền ',
        rewardTime:'Thời gian phát thưởng ',
        rewardType:'Loại hình phần thưởng ',
        //交易查询
        tradeHistory: 'Lịch sử giao dịch ',
        pendingOrder: 'Truy vấn xử lý đơn hàng  ',
        holdOrder: 'Truy vấn đơn vị ',
        varieties: 'đa dạng ',
        tradingVolume: 'Lượng giao dịch ',
        openingPrice: 'Giá vị thế mở ',
        openingTime: 'Thời gian vị thế mở ',
        closingPrice: 'Giá đóng ',
        closingTime: 'Thời gian đóng cửa ',
        hangingOrderPrice: 'Giá trị đơn hàng ',
        hangingOrderTime: 'Thời gian lệnh chờ ',
        stopLoss: 'Dừng lỗ ',
        stopSurplus: 'Chốt lời ',
        overnightInterest: 'Kho lưu trữ qua đêm ',
        profit: 'Lợi nhuận  ',
        totalProfit: 'tổng lợi nhuận: ',
        totalVolume : 'tổng lượng giao dịch: ',
        hand : 'Lô ',
        dataStatus1:'đầu tư dài hạn ',
        dataStatus2:'Bán khống ',
        dataStatus3:'Tín dụng ',
        dataStatus4:'ngăn đầu tư dài hạn lỗ ',
        dataStatus5:'đầu tư dài hạn có thời hạn ',
        dataStatus6:'ngăn lỗ giá bán khống ',
        dataStatus7:'giá hạn chế bán khống ',
        dataStatus8:'Số tiền đầu tư tối thiểu ',
        dataStatus9:'Số tiền gửi tối đa ',
        dataStatus10:'Tổng tiền tích lũy đổi lấy phần thưởng ',
        dataStatus11:'hoàn lại nhận thưởng  ',
        dataStatus12:'Phần thưởng trò chơi EIA ',
        dataStatus13:'Phần thưởng câu đố về Phi nông nghiệp ',
        dataStatus14:'tạm thời không nhận số liệu ',
        //邀请活动规则
        inviteActivityTitle1:'1, Hướng dẫn phương thức tham gia cho người mời ',
        inviteActivityTitle2:'2, Hướng dẫn phương thức tham gia cho người mời ',
        inviteActivityTitle3:'Lưu ý hạng mục ',
        inviteRule1:'Khi tất cả khách hàng đăng ký tài khoản giao dịch thực tại CM Trade-CM, đều có thể chia sẽ hoạt động này và trở thành người mời;  ',
        inviteRule2:'（2）Người mời mỗi lần mời được khách hàng mở tài khoản thực trên sàn giao dịch và kích hoạt tài khoản CM Trade, bạn sẽ nhận được 1 lot tiêu chuẩn  (nhưng do tích lũy theo bất kỳ loại giao dịch) và bạn có thể nhận được phần thưởng $15  ',
        inviteRule3:'（3）Phần thưởng sẽ được cấp trực tiếp vào trong tài khoản giao dịch thực tương đương với hoạt động tham gia, không thể thay đổi; ',
        inviteRule4:'（4）Người mời có thể nhận được số tiền thưởng hàng tuần qua lời mời lên đến $200, người mời và người được mời cũng sẽ nhận được phần thưởng và số tiền đó có thể trực tiếp rút hoặc giao dịch. ',
        unInviteRule1:'Khách hàng được mời phải là người chưa từng đăng ký bất kỳ tài khoản trên sàn giao dịch CM Trade-CM; ',
        unInviteRule2:'Khách hàng được mời nhất định phải đăng ký tài khoản thông qua mã QR/liên kết được chia sẽ, đồng thời sau khi mở kích hoạt tài khoản trong vòng 30 ngày và 1 lot tiêu chuẩn   ',
        unInviteRule3:'（3）Sau khi người mời mở tài khoản và kích hoạt đồng thời trên sàn giao dịch sẽ nhận được 1 lot tiêu chuẩn, có thể nhận được tiền thưởng $5 (vừa có thể rút và giao dịch) ',
        noticeTip1:'（1）Nhân viên CM Trade và gia đình không nhận được ưu đãi lân này, khách hàng khi đăng ký lại tài khoản cũng sẽ không áp dụng ưu đãi này, hoạt động; ',
        noticeTip2:'（2）Nếu như CM Trade nghi ngờ người dùng đang lạm dụng, cố gắng lạm dụng và các phương thức khác để lạm dụng khuyến mãi, CM Trade sẽ có quyền hủy bỏ phần thưởng mà tài khoản nhận được. ',
    },
    accountInfor: {
         //账户信息
        accountHome: 'trang đầu tài khoản ',
        goldInGoldOut: 'nạp tiền / rút tiền ',
        accountData: 'thông tin tài khoản ',
        accountDateTitle1: 'Xác minh thân phận ',
        accountDateTitle2: 'tài khoản ngân hàng/thẻ quản lý ',
        accountDateTitle3: 'Email ',
        accountDateTitle4: 'Số điện thoại ',
        accountDateTitle5: 'Thay đổi mật khẩu ',
        nickname: 'biệt danh: ',
        realName: 'họ tên thực: ',
        notCertified: 'chưa xác nhận ',
        underReview: 'Đang xét duyệt ',
        modifyNickname: 'Thay đổi biệt danh ',
        inputNickname: 'Hãy nhập biệt danh của bạn ',
        Submit: 'Nộp ',
        bind: 'Liên kết ',
        identityTitle: 'Bạn đã hoàn thành xác minh danh tính và bạn đã có thể thực hiện các giao dịch gửi tiền mặt~ ',
        bindBankTitle1: 'Bạn đã hoàn thành việc liên kết tài khoản ngân hàng/thẻ, nếu cần sửa đổi vui lòng bấm vào nút sửa đổi để sửa đổi ',
        bindBankTitle2: 'tên ngân hàng ',
        bindBankTitle3: 'số tài khoản ngân hàng  ',
        bindBankTitle4: 'Tên chi nhánh ',
        bindBankTitle5: 'số tài khoản ngân hàng ',
        modifyUserHead: 'Thay đổi ảnh đại diện ',
        bindEmailTitle1: 'chưa liên kết được email hiện nay của bạn, chúng tôi sẽ gửi cho bạn các chương trình khuyến mãi và bản tin thị trường hàng tuần qua email. ',
        bindEmailTitle2: 'Liên kết email ',
        bindEmailTitle3: 'Liên kết email thành công ',
        bindEmailTitle4: 'Thay đổi email thành công ',
        bindEmailTitle5: 'Liên kết địa chỉ email của bạn và đảm bảo an toàn cho tài khoản của bạn, nhận dịch vụ email ',
        bindPhoneTitle1: 'chưa liên kết được số điện thoại hiện nay của bạn, chúng tôi sẽ gửi cho bạn các chương trình khuyến mãi và thông báo dịch vụ qua số điện thoại cho bạn ',
        bindPhoneTitle2: 'Liên kết số điện thoại để đảm bảo an toàn tài khoản của bạn, để nhận thêm dịch vụ hỗ trợ thông báo qua SMS ',
        bindPhoneTitle3: 'Liên kết thành công số điện thoại ',
        bindPhoneTitle4: 'Thay đổi thành công số điện thoại ',
        bindPhoneTitle5: 'Liên kết số điện thoại ',
        modifyPassTips1: 'Nhập lại mật khẩu mới',
        modifyPassTips2: 'Mật khẩu kết hợp 6-15 chữ và số mới',
        modifyPassTips3: 'Nhập lại mật khẩu mới',
        modifyPassTips4: 'thay đổi mật khẩu không thành công ',
    },
    footer: {
        navText1:'Giới thiệu ',
        navText2:'thỏa hiệp khách hàng ',
        navText3:'Cảnh báo rủi ro ',
        navText4:'miễn phí công văn ',
        navText5:'chính sách bảo mật ',
        navText6:'tính bảo mật số tiền ',
        navText7:'Trung tâm trợ giúp ',
        copyright:'Bản quyền © 2023 CM Trade Bảo lưu quyền sở hữu, không được sao chép ',
        suText1:'miễn phí mở tài khoản ',
        suText2:'Trò chuyện ',
    },
    appH5: {
        regSuccessText1:'Chúc mừng bạn đã đăng ký thành công ',
        regSuccessText2:'tiền thưởng đăng ký mới của bạn đã được chuyển đến tài khoản ',
        regSuccessText3:'Lập tức gửi tiền và nhận ngay ',
        regSuccessText4:'Xác nhận xong danh tính, nhận càng nhiều đặc quyền ',
    }   
}