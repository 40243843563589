<template>
  <div class="page page-s">
    <Header :loginState="loginState" />
    <div class="account-page">
      <left-menu />
      <div class="account-center">
        <div class="center-box">
          <div class="hide-pc">
            <div class="title-box">           
              <img src="@/assets/images/account/data-icon.png" alt="">
              <p>{{$t('accountCenter.homeTitle1')}}</p>
            </div>
          </div>       
          <div class="account-data">
            <div class="account-fl">
              <img v-if="!findUserInfo.avatar" class="user-img" src="@/assets/images/account/user-logo.png" alt="" />
              <img v-else class="user-img" :src="findUserInfo.avatar" alt="">
              <div class="data-list-box">
                <div class="data-list">
                  <p class="p1">{{$t('accountInfor.nickname')}}</p>
                  <p class="p2">{{findUserInfo.nickName}}</p>
                </div>
                <div class="data-list">
                  <p class="p1">{{$t('accountCenter.accountNumber')}}</p>
                  <p class="p2">{{findUserInfo.spNumber}}</p>
                  <div class="zhlx-box">
                    <img src="@/assets/images/account/hg-icon.png" alt="">
                    <p>{{findUserInfo.accountLevel}}</p>
                  </div>
                </div>
                <div class="hide-pc">
                  <div class="data-list">
                    <p class="p1">{{$t('accountCenter.email')}}</p>
                    <p v-if="findUserInfo.emailIsVer == 1" class="p2">{{findUserInfo.email}}</p>
                    <p v-if="findUserInfo.emailIsVer == 0" class="p2">--</p>
                    <img @click="emailEdit" src="@/assets/images/account/edit-icon.png" alt="">
                  </div>
                </div>              
                <div class="data-list">
                  <p class="p1">{{$t('accountCenter.phoneNumber')}}</p>
                  <p v-if="findUserInfo.mobileIsVer == 1" class="p2">{{findUserInfo.mobile}}</p>
                  <p v-if="findUserInfo.mobileIsVer == 0" class="p2">--</p>
                  <img @click="phoneEdit" src="@/assets/images/account/edit-icon.png" alt="">
                </div>
                <div class="hide-pc">
                  <div class="data-list">
                    <p class="p1">{{$t('accountCenter.residentialArea')}}</p>
                    <p v-if="countryCurrent == '' || countryCurrent == null || countryCurrent == undefined" class="p2">--</p>
                    <p v-else class="p2">{{countryCurrent.displayName}}</p>
                  </div>
                </div>
                <div v-if="findUserInfo.identityResult == 0" class="hide-pc">
                  <button 
                    @click="perfectData()"
                    class="rj-btn">
                      <img src="@/assets/images/account/wszl-icon.png" alt="">
                      <span>{{$t('perfectData.perfectData')}}</span>
                  </button>
                </div>
                
              </div>
            </div>
            <div class="account-fr">
              <div class="data-list-box">
                <div class="data-list jz-data">
                  <div class="p1">
                    <span>{{$t('accountCenter.accountValue')}}</span>
                    <div class="tips-btn">
                      <img src="@/assets/images/account/wh-s-icon.png" alt="">
                      <div class="tips-box">
                        <p class="tips-text">{{$t('accountCenter.accountTips1')}}</p>
                      </div>
                    </div>                 
                  </div>
                  <p class="p2">{{transactionInfo.equity}}</p>
                </div>
                <div class="data-list">
                  <div class="p1">
                    <span>{{$t('accountCenter.creditGift')}}</span>
                    <div class="tips-btn">
                      <img src="@/assets/images/account/wh-icon.png" alt="">
                      <div class="tips-box">
                        <p class="tips-text">{{$t('accountCenter.accountTips2')}}</p>
                      </div>
                    </div> 
                  </div>
                  <p class="p2">{{transactionInfo.credit}}</p>
                </div>
                <div class="data-list">
                  <div class="p1">
                    <span>{{$t('accountCenter.activityReward')}}</span>
                    <div class="tips-btn">
                      <img src="@/assets/images/account/wh-icon.png" alt="">
                      <div class="tips-box">
                        <p class="tips-text">{{$t('accountCenter.accountTips3')}}</p>
                      </div>
                    </div> 
                  </div>
                  <p class="p2">{{findUserInfo.activityRecord}}</p>
                </div>
                <div class="data-list">
                  <div class="p1">
                    <span>{{$t('accountCenter.availablePoints')}}</span>
                    <div class="tips-btn">
                      <img src="@/assets/images/account/wh-icon.png" alt="">
                      <div class="tips-box">
                        <p class="tips-text">{{$t('accountCenter.accountTips4')}}</p>
                      </div>
                    </div> 
                  </div>
                  <p class="p2">{{findUserInfo.availableScore}}</p>
                </div>
                <div class="hide-pc">
                  <a href="/deposit" class="rj-btn">
                    <img src="@/assets/images/account/rj-icon.png" alt="">
                    <span>{{$t('accountCenter.deposit')}}</span>
                  </a>
                </div>
                <div class="hide-m">
                  <div class="btn-list-box">
                    <button 
                      @click="perfectData()"
                      v-if="findUserInfo.identityResult == 0"
                      class="rj-btn">
                        <img src="@/assets/images/account/wszl-icon.png" alt="">
                        <span>{{$t('perfectData.perfectData')}}</span>
                    </button>
                    <a href="/deposit" class="rj-btn">
                      <img src="@/assets/images/account/rj-icon.png" alt="">
                      <span>{{$t('accountCenter.deposit')}}</span>
                    </a>                 
                  </div>
                </div>           
              </div>  
            </div>
          </div>
        </div>
        <!-- <div class="notice-box">
          <div class="notice-box-fl">
            <div class="title">
              <img src="@/assets/images/account/notice-icon.png" alt="">
              <p>{{$t('accountCenter.notice')}}</p>
            </div>
            <div class="hide-pc">
              <div class="notice-center">
                <a v-for="(item,index) in noticeData" :key="index" :href="'https://www.change555.com'+item.href" class="notice-list">
                  <p class="notice-title dian1">{{item.title}}</p>
                </a>
              </div>
            </div>
            <div class="hide-m">
              <div class="notice-center">
                <a v-for="(item,index) in noticeData" :key="index" :href="'https://www.change555.com'+item.href" class="notice-list">
                  <p class="notice-title dian1">{{item.title}}</p>
                </a>
              </div>
            </div>
          </div>      
          <a class="more-btn" href="">more ></a>
        </div> -->
        <div class="activity-box">
          <div class="title-box">
            <div class="title-fl">
              <img src="@/assets/images/account/lb-icon.png" alt="">
              <p>{{$t('accountCenter.homeTitle2')}}</p>
            </div>           
            <div class="title-fr">
              <p @click="activityRulesShow">{{$t('accountCenter.activityRules')}}></p>
            </div>
          </div>
          <div class="activity-center">
            <div class="activity-title">
              <p>{{$t('accountCenter.homeTitle3')}}</p>
              <button @click="rewardShow">{{$t('accountCenter.viewRewards')}}</button>
            </div>
            <div class="activity-img">
              <div class="activity-list">
                <p class="numb"><span>$</span>{{inviteRule.inviteMoney}}</p>
                <p class="p-text">{{$t('accountCenter.activityListTitle1')}}</p>
              </div>
              <div class="activity-list">
                <p class="numb"><span>$</span>{{inviteRule.uninviteMoney}}</p>
                <p class="p-text">{{$t('accountCenter.activityListTitle2')}}</p>
              </div>
            </div>
            <p class="hide-m m-fx-text">{{$t('accountCenter.activityListTitle3')}}</p>
            <div class="ag_link_box">
                <p class="hide-pc">{{$t('accountCenter.activityListTitle3')}}</p>
                <span class="ag_link" id="zk_link">{{links}}</span>
                <div @click="copyLink('zk_link')" class="ag_clop-btn">
                  <span>{{$t('accountCenter.copyLink')}}</span>   
                  <p class="prompt" id="prompt">{{$t('accountCenter.copySucc')}}</p>
                </div>
            </div>
          </div>

          <!-- 查看奖励弹窗 -->
          <div class="reward-data-modal">
            <div class="mask" v-if="showModal1" @click="rewarHide"></div>
            <div class="pop" v-if="showModal1">
              <div class="data-box">
                <img @click="rewarHide" class="close-btn" src="@/assets/images/register/close.png" alt="">
                <h2>{{$t('accountCenter.myRewards')}}</h2>
                <div class="reward-box scrollbarchange">
                  <div class="reward-data">
                    <div class="body-tlt">
                      <a href="javascript:void();">{{$t('accountCenter.rewardTitle1')}}</a><a href="javascript:void();">{{$t('accountCenter.rewardTitle2')}}</a><a href="javascript:void();">{{$t('accountCenter.rewardTitle3')}}</a><a href="javascript:void();">{{$t('accountCenter.rewardTitle4')}}</a>
                    </div>
                    <ul class="jl-cont scrollbarchange">
                      <p v-if="myInviteReward == '' || myInviteReward == null" class="null-data">{{$t('accountCenter.dataStatus14')}}</p>
                      <li v-for="(item,index) in myInviteReward" :key="index">
                        <span>{{item.applyMoney}}</span><span>{{item.accountNumber}}</span>
                        <span>{{item.createTime}}</span>
                        <span>
                          <a v-if="item.auditStatus == 1" class="d" href="javascript:void();">{{$t('accountCenter.rewardState3')}}</a>
                          <a v-if="item.auditStatus == 2" class="y" href="javascript:void();">{{$t('accountCenter.rewardState1')}}</a>
                          <a v-if="item.auditStatus == 3" href="javascript:void();">{{$t('accountCenter.rewardState2')}}</a>
                        </span>
                      </li>
                    </ul>
                  </div>                 
                </div>                     
              </div>     
            </div>          
          </div>

          <!-- 活动规则 -->
          <div class="reward-data-modal">
            <div class="mask" v-if="showModal2" @click="activityRulesHide"></div>
            <div class="pop" v-if="showModal2">
              <div class="data-box">
                <img @click="activityRulesHide" class="close-btn" src="@/assets/images/register/close.png" alt="">
                <h2>{{$t('accountCenter.activityRules')}}</h2>
                <div class="reward-box activity-rules">
                  <div class="reward-data">
          <p class="conter-text">{{$t('accountCenter.inviteActivityTitle1')}}</p>
					<p class="content-text">{{$t('accountCenter.inviteRule1')}}</p>
					<p class="content-text">{{$t('accountCenter.inviteRule2')}}</p>
					<p class="content-text">{{$t('accountCenter.inviteRule3')}}</p>
					<p class="content-text">{{$t('accountCenter.inviteRule4')}}</p>
					<p class="conter-text">{{$t('accountCenter.inviteActivityTitle2')}}</p>
					<p class="content-text">{{$t('accountCenter.unInviteRule1')}}</p>
					<p class="content-text">{{$t('accountCenter.unInviteRule2')}}</p>
					<p class="conter-text">{{$t('accountCenter.inviteActivityTitle3')}}</p>
					<p class="content-text">{{$t('accountCenter.noticeTip1')}}</p>
					<p class="content-text">{{$t('accountCenter.noticeTip2')}}</p>
                  </div>               
                </div>                     
              </div>     
            </div>          
          </div>

        </div> 
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import LeftMenu from '@/components/LeftMenu.vue';
import { getCookie } from '@/util/cookie';
import account from '@/api/account.js';
import axios from "axios";
import {Config, HEADER_USER_TOKEN} from "@/util/const";
export default {
  name: "Index",
  components: {
    Header,
    LeftMenu
  },
  data() {
    return {
      loginState:2,
      links:"",
      showModal1:false,
      showModal2:false,
      findUserInfo:[],
      countryList: {
        countrys: [],
        countryActive:'',
      },
      countryCurrent:[],
      transactionInfo:[],
      myInviteReward:[],
      inviteRule:{},
      noticeData:[]
    }
  },
  created() {
    // 查询用户基本资料
      const userToken = getCookie(HEADER_USER_TOKEN);
      let lang = getCookie('LOCALE');
      if(lang == "zh_CN"){
          lang = "ch";
      }else if(lang == "zh_TW"){
          lang = "tw"
      }else if(lang == "en_US"){
          lang = "en"
      }else if(lang == "id_ID"){
          lang = "id"
      }else if(lang == "th_TH"){
          lang = "th"
      }else if(lang == "hi_IN"){
          lang = "hi"
      }
      console.log(userToken);
      if(!userToken){
        this.$router.push({ path: '/login' })
      }else{
        account.findUserInfo().then((res)=>{
          if(res.data.success == 1){
                this.findUserInfo = res.data.value;
                let domain= window.location.host.split(".").length > 2 ? window.location.host.split(".")[1]+"."+window.location.host.split(".")[2] : window.location.host ;
                this.links = window.location.protocol +"//www."+domain+"/app/"+lang+"/invitation?u="+this.findUserInfo.userId;
                // 获取邮箱注册的国家下拉列表数据
                account.getSupportCountry().then((result)=>{
                  this.countryList.countrys = result.data.value
                  let countryCurrent = this.countryList.countrys.filter(it => it.countryId == res.data.value.countryId);
                  this.countryCurrent = countryCurrent[0];
                }).catch(err=>{
                  console.log(err);
                })
            }else{
                console.log(res.data.message);
            }
        }).catch(err=>{
          console.log(err.message);
        })
      //获取交易账户信息
      account.queryTradingAccount().then((res)=>{
        if(res.data.success == 1){
              this.transactionInfo = res.data.value
          }else{
              console.log(res.data.message);
          }
      }).catch(err=>{
        console.log(err.message);
      })
      //获取邀请奖励规则
      account.getInviteRule().then(res =>{
        if(res.data.success == 1){
              this.inviteRule = res.data.value.resultList && res.data.value.resultList.length > 0 ? res.data.value.resultList[0]:{inviteMoney:15,uninviteMoney:5};
        }else{
              console.log(res.data.message);
        }
      }).catch(err=>{
        console.log(err.message);
      })
      //查询邀请奖励记录
      account.findMyInviteRewardDetail().then((res)=>{
        if(res.data.code == "1"){
              let array = res.data.rows;
              for (let i = 0; i < array.length; i++) {
                let createTime = array[i].createTime;                            
                array[i].createTime =  this.timestampToTime(createTime);
              }
              this.myInviteReward = array;
              console.log(this.myInviteReward)
          }else{
              console.log(res.data.message);
          }
      }).catch(err=>{
        console.log(err.message);
      })
    }
    
    // 调用公告方法
    this.noticeQueryData();
  },
  methods: {
    //公告查询  
    noticeQueryData(){ 
      let la = getCookie('LOCALE');
      var lang;
      if(la = "zh_CN"){
        lang = "ch";
      }
      axios.get(Config.www + '/api/v1/messages/4/page/1/count/2/'+lang,{
          }).then((res)=>{
            this.noticeData = res.data.messages;    
          }).catch(err=>{
              console.log(err.message);
          })
      },

    // 点击完善资料
    perfectData(){
      const that = this;
        that.$router.push({
        path: 'authentication'         
      })
    },
    //点击修改邮箱，跳转到邮箱修改页面
    emailEdit(){
      this.$router.push({ path: '/mailbox' })
    },
    //点击修改手机号，跳转到手机号修改页面
    phoneEdit(){
      this.$router.push({ path: '/phoneNumber' })
    },

    // 查看奖励弹窗
    rewardShow(){
      this.showModal1 = true
    },
    // 关闭查看奖励弹窗
    rewarHide(){
      this.showModal1 = false
    },
    // 查看活动规则弹窗
    activityRulesShow(){
      this.showModal2 = true
    },
    // 关闭活动规则弹窗
    activityRulesHide(){
      this.showModal2 = false
    },

    //点击复制按钮,复制文本
    copyLink($id) {
        //var $id = $(dom).prev().attr('data_id');
        this.selectText($id);
        document.execCommand('copy');
    },
    // 将时间戳转换为日期格式
    timestampToTime(timestamp) {
        var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        var D = (date.getDate() < 10 ? '0'+ date.getDate(): date.getDate()) + ' ';
        var h = (date.getHours() < 10 ? '0'+ date.getHours(): date.getHours()) + ':';
        var m = (date.getMinutes() < 10 ? '0'+ date.getMinutes(): date.getMinutes()) + ':';
        var s = (date.getSeconds() < 10 ? '0'+ date.getSeconds(): date.getSeconds());
        return Y+M+D+h+m+s;
    },
    //选中文本
    selectText(element) {
        var text = document.getElementById(element);
        //做下兼容
        if (document.body.createTextRange) {  //如果支持
            var range = document.body.createTextRange(); //获取range
            range.moveToElementText(text); //光标移上去
            range.select();  //选择
        } else if (window.getSelection) {
            var selection = window.getSelection(); //获取selection
            var range = document.createRange(); //创建range
            range.selectNodeContents(text);  //选择节点内容
            selection.removeAllRanges(); //移除所有range
            selection.addRange(range);  //添加range
            /*if(selection.setBaseAndExtent){
             selection.setBaseAndExtent(text, 0, text, 1);
             }*/
             document.getElementById('prompt').style.display='block';
            setTimeout("document.getElementById('prompt').style.display='none'",1000);               
        } else {
            this.copyState = false
        }
    }
  },
};
</script>
<style>
@import url("../assets/css/public.css");
</style>
