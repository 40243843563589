import {createI18n} from 'vue-i18n/index';//引用i18n插件
import zh_CN from '@/language/zh_CN';//引用简体语言包文件
import zh_TW from '@/language/zh_TW';//引用繁体语言包文件
import en_US from '@/language/en_US';//引用英文语言包文件
import th_TH from '@/language/th_TH';//引用泰文语言包文件
import id_ID from '@/language/id_ID';//引用印尼语言包文件
import vi_VN from '@/language/vi_VN';//引用越南语言包文件
import {getCookie} from "@/util/cookie";

const i18n = createI18n({
    // legacy: false,
    // globalInjection: true,
    locale: getCookie('LOCALE') || localStorage.getItem('LOCALE') || 'zh_TW',//传入对应的语言
    messages: {
        'zh_CN': zh_CN,
        'zh_TW': zh_TW,
        'en_US': en_US,
        'th_TH': th_TH,
        'id_ID': id_ID,
        'vi_VN': vi_VN
    }
})

export default i18n;
