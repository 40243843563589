<template>
  <!-- pc端头部 -->
  <div class="hide-pc">
    <div class="header">
      <div class="w1400 header-box" :class="[loginState == 2 ? 'max-width' : '']">
        <div class="header-fl">
          <a :href="links1"><img src="@/assets/images/logo.png" alt=""></a>
        </div>
        <div class="header-fr">
          <a v-if="loginState == 0" href="/mobileReg" class="jump-btn">{{$t('header.register')}}</a>
          <a v-if="loginState == 1" href="/login" class="jump-btn">{{$t('header.login')}}</a>
          <a v-if="loginState == 2" @click="Logout" href="javascript:;" class="jump-btn">{{$t('header.Logout')}}</a>
          <a :href="links2" class="header-fr-list dow-btn">
            <img src="@/assets/images/phone-icon.png" alt="">
            <span>{{$t('header.appDownload')}}</span>
          </a>
          <a :href="serviceLink" class="header-fr-list service" target="_blank"><img src="@/assets/images/service.png" alt=""></a>
          <div class="header-fr-list country-box">
            <div @click.stop="countryState = !countryState" class="country"><img :src="require('@/assets/images/flag/'+language+'.png')" alt=""></div>
            <ul v-show="countryState" class="country-menu">
              <li @click="changeLocale(option.languageCode)" v-for="(option, index) in options" :key="index"><img :src="require('@/assets/images/flag/'+option.languageCode+'.png')" alt=""><span class="dian1" :class="{'active': language == option.languageCode}" :title="option.description">{{option.description}}</span></li>
            </ul>          
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- m端头部 -->
  <div class="hide-m">
    <div class="header">
      <div class="w1400 header-box">
        <div class="header-fl">
          <a v-if="loginState == 0" href="/mobileReg" class="jump-btn">{{$t('header.register')}}</a>
          <a v-if="loginState == 1" href="/login" class="jump-btn">{{$t('header.login')}}</a>
          <img v-if="loginState == 2" @click="Logout" class="logout-icon" src="@/assets/images/mobile/logout-icon.png" alt="">
        </div>
        <div class="header-fr">
          <a :href="links3" class="dow-btn"><img src="@/assets/images/app-logo.png" alt=""><span>{{$t('header.appDownload')}}</span></a>
          <a :href="serviceLink" class="header-fr-list service" target="_blank"><img src="@/assets/images/service.png" alt=""></a>
          <div class="header-fr-list country-box">
            <div @click.stop="countryState = !countryState" class="country"><img :src="require('@/assets/images/flag/'+language+'.png')" alt=""></div>
            <ul v-show="countryState" class="country-menu">
              <li @click="changeLocale(option.languageCode)" v-for="(option, index) in options" :key="index"><img :src="require('@/assets/images/flag/'+option.languageCode+'.png')" alt=""><span class="dian1" :class="{'active': language == option.languageCode}" :title="option.description">{{option.description}}</span></li>
            </ul>          
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import {defineComponent, ref} from 'vue';
import { setCookie,delCookie,getCookie } from '@/util/cookie';
import {Config, HEADER_USER_TOKEN} from '@/util/const.js';
import account from '@/api/account.js';

export default{
  props:["loginState"],
  data() {
    return {
      language : 'zh_CN',
      countryState : false,
      options: [],
      links1:'',
      links2:'',
      links3:'',
      serviceLink:'',
    }
  },
  created() {
    account.getLanguage().then((res) => {
      this.options = res.data.value;
    }).catch(err=>{
      console.log(err);
    })
    this.language = this.$i18n.locale;
    setCookie('LOCALE', this.$i18n.locale, 7) //把选中的语言存到cookie
    console.log(this.countryState);
    if (Config.userToken){
      account.loginStatus().then(res => {
        if (res.data){
          if (res.data.success){
          }else {
            delCookie(HEADER_USER_TOKEN);
            Config.userToken = null;
            this.$router.push({ path: '/login' })
          }
        }
      })
    }

    let lang1 = getCookie('LOCALE');
    if(lang1 == "zh_CN"){
          lang1 = "zh";
      }else if(lang1 == "zh_TW"){
          lang1 = "zh_TW"
      }else if(lang1 == "en_US"){
          lang1 = "en"
      }else if(lang1 == "id_ID"){
          lang1 = "in"
      }else if(lang1 == "th_TH"){
          lang1 = "th"
      }else if(lang1 == "hi_IN"){
          lang1 = "hi"
      }
    account.findUserInfo().then((res)=>{
        if(res.data.success == 1){
              var userInfoValue;
              if(res.data.value.identityResult ==0){
                  var _data1 = "userId="+res.data.value.spNumber+"&name="+res.data.value.spNumber+"&memo="+res.data.value.spNumber;
                  userInfoValue = urlencode(_data1);
              }else{
                var _data2 = "userId="+res.data.value.spNumber+"&name="+res.data.value.name+"&memo="+res.data.value.spNumber;
                userInfoValue = urlencode(_data2);
              }           
              this.serviceLink = "https://chat10.live800.com/live800/chatClient/chatbox.jsp?companyID=1524200&configID=239203&jid=5772502639&s=1&lan="+lang1+"&info="+userInfoValue;
          }else{
            this.serviceLink = "https://chat10.live800.com/live800/chatClient/chatbox.jsp?companyID=1524200&configID=239203&jid=5772502639&s=1&lan="+lang1;
              console.log(res.data.message);
          }
      }).catch(err=>{
        console.log(err.message);
      })

      function urlencode (str) {  
          str = (str + '').toString();   
          return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').  
          replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');  
      }

      let lang = getCookie('LOCALE');
      if(lang == "zh_CN"){
          lang = "ch";
      }else if(lang == "zh_TW"){
          lang = "tw"
      }else if(lang == "en_US"){
          lang = "en"
      }else if(lang == "id_ID"){
          lang = "id"
      }else if(lang == "th_TH"){
          lang = "th"
      }else if(lang == "hi_IN"){
          lang = "hi"
      }

    //动态域名链接  
    // let domain= window.location.host.split(".").length > 2 ? window.location.host.split(".")[1]+"."+window.location.host.split(".")[2] : window.location.host ;
    // this.links1 = window.location.protocol +"//www."+domain+"/"+lang+"/home";
    // this.links2 = window.location.protocol +"//www."+domain+"/"+lang+"/down-app";

    //写死域名链接
    this.links1 = window.location.protocol +"//www.cmtrade.com/"+lang+"/home";
    
    //app下载sem配置参数来源
    const utm_source = this.$route.query.utm_source;
    const utm_campaign = this.$route.query.utm_campaign;
    const utm_adgroup = this.$route.query.utm_adgroup;
    const utm_adgroupid = this.$route.query.utm_adgroupid;
    const utm_campaignid = this.$route.query.utm_campaignid;
    const utm_creative = this.$route.query.utm_creative;
    const utm_content =this.$route.query.utm_content;
    const utm_term = this.$route.query.utm_term;
    var APP_ID;
    if(utm_source){      
				if(/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)){
					APP_ID = "id1615878597";
				}
				if(/android/i.test(navigator.userAgent)){
					APP_ID = "com.cmtrade.app.droid";
				}			
		  var oneLinkURL = "https://app.appsflyer.com/"+APP_ID+"?pid="+utm_source+"&c="+utm_campaign+"&af_adset="+utm_adgroup+"&af_adset_id="+utm_adgroupid+"&af_c_id="+utm_campaignid+"&af_ad="+utm_creative+"&af_ad_type="+utm_content+"&af_keywords="+utm_term;
      if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))){
          this.links2 = oneLinkURL;
        } else{
          this.links2 = window.location.protocol +"//www.cmtrade.com/"+lang+"/down-app";
        }      
      this.links3 = oneLinkURL;
    }else{
      this.links2 = window.location.protocol +"//www.cmtrade.com/"+lang+"/down-app";
      this.links3 = "https://cmtrade.onelink.me/PzuJ/4t57jx7s";
    }
    
  },
  methods: {
    countryShow(){   
        this.countryState = false;
    },
    changeLocale(language) {
        this.language = language;
        localStorage.setItem('LOCALE', language);
        this.$i18n.locale = localStorage.getItem('LOCALE');
        setCookie('LOCALE', language, 7) //把选中的语言存到cookie
        this.countryState = false;
        location.reload();
    },
    Logout(){
      account.logout().then((res) => {
        if(res.data.success == 1){
          delCookie(HEADER_USER_TOKEN);
          Config.userToken = '';        
          this.$router.push({ path: '/login' })
        }else{
          console.log(err.data.message);
        }
      }).catch(err=>{
        console.log(err.message);
      })
    }
  },
  mounted () {
    document.addEventListener('click', this.countryShow)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header{width: 100%;height: 80px;box-shadow:4px 4px 5px #e8e8e8;background: #fff;display: flex;align-items: center;position: fixed;top: 0; left:0;z-index: 99;}
.header-box{min-width: 1000px;display: flex;flex-direction: row;justify-content: space-between;align-items: center;}
.max-width{width: 96%;}
.header-fl{width: 224px;height: 49px;}
.header-fr{display: flex;flex-direction: row;justify-content: space-between;align-items: center;}
.header-fr-list{margin-left: 15px;}
.header-fr .jump-btn{padding: 5px 20px; border-radius: 30px;background: #ffd94c;font-size: 14px;color: #000;}
.header-fr .jump-btn:hover{background: #ffe27b;}
.header-fr .dow-btn{padding: 5px 20px; border-radius: 30px;border: 1px solid #ffd94c; display: flex;flex-direction: row;justify-content: space-around;align-items: center;}
.header-fr .dow-btn:hover{background: #ffd94c;} 
.header-fr .dow-btn img{width: 10px;height: 16px;margin-right: 10px;}
.header-fr .dow-btn span{font-size: 14px;color: #000;}
.header-fr .service{width: 29px;height: 27px;display: block;}
.country-box{position: relative;}
.country-box .country{width: 27px;height: 20px;cursor: pointer;}
.country-box .country img{width: 100%;height: 100%;border: 1px solid #f2f2f2;}
.country-menu{width: 110px; position:absolute;top: 50px;right: 0;padding: 10px;z-index: 999; background: #fff;box-shadow:4px 1px 10px #e8e8e8;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;}
.country-menu li{margin: 10px 0;cursor: pointer; display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
.country-menu li img{width: 20px;height: 13px;margin-right: 10px;}
.country-menu li span{font-size: 12px;color: #000;}
.country-menu li span.active{color: #ffd94c;}
.country-menu li span:hover{color: #ffd94c;}

@media screen and (max-width: 768px) {
.header{width: 100%;height: 3.3rem;box-shadow:4px 4px 5px #e8e8e8;background: #fff;display: flex;align-items: center;position: fixed;top: 0; left:0;}
.header-box{min-width: 90%;height: 3.3rem; display: flex;flex-direction: row;justify-content: space-between;align-items: center;}
.header-box img{width: 100%;height: 100%;}
.max-width{width: 90%;}
.header-fl{width: auto;height: 100%;display: flex;align-items: center;}
.header-fl img{width: 1.1rem;height: 1.2rem;}
.header-fl .jump-btn{padding: 0.3rem 1.5rem; border-radius: 1.5rem;background: #ffd94c;font-size: 0.6rem;color: #000;}
.header-fl .jump-btn:hover{background: #ffe27b;}
.header-fr{display: flex;flex-direction: row;justify-content: space-between;align-items: center;}
.header-fr-list{margin-left: 1rem;}
.header-fr .dow-btn{padding: 0.15rem 0.5rem; border-radius: 30px;border: 1px solid #ffda00;background: #fffae6; display: flex;flex-direction: row;justify-content: space-around;align-items: center;}
.header-fr .dow-btn:hover{background: #ffd94c;} 
.header-fr .dow-btn img{width: 1rem;height: 1rem;margin-right: 0.3rem;}
.header-fr .dow-btn span{font-size: 0.6rem;color: #000;}
.header-fr .service{display: block;}
.country-box{position: relative;}
.country-box .country{cursor: pointer;}
.country-menu{width: 6rem; position:absolute;top: 2.1rem;right: -0.4rem;padding: 0.5rem;background: #fff;box-shadow:4px 1px 10px #e8e8e8;}
.country-menu li{margin: 0.6rem 0;cursor: pointer; display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
.country-menu li img{width: 1.2rem;height: 0.8rem;margin-right: 0.5rem;}
.country-menu li span{font-size: 0.6rem;color: #000;}
.country-menu li span:hover{color: #ffd94c;}
}
@media screen and (max-width: 414px) {}
@media screen and (max-width: 375px) {}
@media screen and (max-width: 360px) {}
@media screen and (max-width: 320px) {}
</style>
