import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/personalData',
    name: 'PersonalData',
    component: () => import('../views/PersonalData.vue')
  },
  {
    path: '/authentication',
    name: 'Authentication',
    component: () => import('../views/Authentication.vue')
  },
  {
    path: '/bankCardVerification',
    name: 'BankCardVerification',
    component: () => import('../views/BankCardVerification.vue')
  },
  {
    path: '/mailbox',
    name: 'Mailbox',
    component: () => import('../views/Mailbox.vue')
  },
  {
    path: '/phoneNumber',
    name: 'PhoneNumber',
    component: () => import('../views/PhoneNumber.vue')
  },
  {
    path: '/modifyPassword',
    name: 'ModifyPassword',
    component: () => import('../views/ModifyPassword.vue')
  },
  {
    path: '/deposit',
    name: 'Deposit',
    component: () => import('../views/Deposit.vue')
  },
  {
    path: '/depositApp',
    name: 'DepositApp',
    component: () => import('../views/DepositApp.vue')
  },
  {
    path: '/onlineBank',
    name: 'OnlineBank',
    component: () => import('../views/OnlineBank.vue')
  },
  {
    path: '/depositCallback',
    name: 'depositCallback',
    component: () => import('../views/DepositCallback.vue')
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    component: () => import('../views/Withdraw.vue')
  },
  {
    path: '/capitalFlow',
    name: 'CapitalFlow',
    component: () => import('../views/CapitalFlow.vue')
  },
  {
    path: '/tradeHistory',
    name: 'TradeHistory',
    component: () => import('../views/TradeHistory.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/mobileReg',
    name: 'MobileReg',
    component: () => import('../views/MobileReg.vue')
  },
  {
    path: '/emailReg',
    name: 'EmailReg',
    component: () => import('../views/EmailReg.vue')
  },
  {
    path: '/retrievePassword',
    name: 'RetrievePassword',
    component: () => import('../views/RetrievePassword.vue')
  },
  {
    path: '/perfectData',
    name: 'PerfectData',
    component: () => import('../views/PerfectData.vue')
  },
  {
    path: '/addBankCard',
    name: 'AddBankCard',
    component: () => import('../views/AddBankCard.vue')
  },
  {
    path: '/appRegisteredSuccessfully',
    name: 'AppRegisteredSuccessfully',
    component: () => import('../views/AppRegisteredSuccessfully.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
