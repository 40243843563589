import {Config} from "@/util/const";

//获取cookie、
export function getCookie(name) {
 var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
 if (arr = document.cookie.match(reg))
  return unescape(arr[2]);
 else
  return null;
}
//设置cookie,增加到vue实例方便全局调用
export function setCookie (c_name, value, expiredays) {
 var exdate = new Date();
 exdate.setDate(exdate.getDate() + expiredays);
 document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=") + exdate.toGMTString() + (process.env.NODE_ENV !== 'development' ? ";domain=."+Config.rootDomain+";path=/" : '');
};
 
//删除cookie
export function delCookie (name) {
 var exp = new Date();
 exp.setTime(exp.getTime() - 1);
 var cval = getCookie(name);
 if (cval != null)
  document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString() + (process.env.NODE_ENV !== 'development' ? ";domain=."+Config.rootDomain+";path=/" : '');
};


export function getQueryStringArgs(key) {
 // 獲取查詢字符串參數，去除該字符串問號開關符號
 var qs = location.search.length > 0 ? location.search.substring(1) : "",
     args = {},//存放所有查詢字符串參數對
     items = qs.split("&"),
     len = items.length,
     name = null,
     value = null;
 if (qs.length == 0) {
  return (key == undefined) ? [] : "";
 }
 for (var i = 0; i < len; i++) {
  var item = items[i].split("=");
  name = decodeURIComponent(item[0]);
  value = decodeURIComponent(item[1]);
  args[name] = value;
 }
 if (key == undefined) {
  return args;
 }
 var ret = args[key];
 ret = (ret == undefined) ? "" : ret;
 return ret;
};

export function getQueryString(name) {
 var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
 var r = window.location.search.substr(1).match(reg);
 if (r != null) {
  return unescape(r[2]);
 }
 return null;
}