/**
 * 账号相关API：登录、注册、短信验证码等
 */
import axios from "axios";
import {Config} from '@/util/const'

axios.defaults.baseURL = Config.api;
axios.defaults.withCredentials = true;
axios.defaults.timeout = 60000;

const account = {
    //获取国家、区号等列表
    //  getCountry: (payload) => axios.get('/api/v2/account/getCountry',payload,HEADERS_FORM_URLENCODED),
    getSupportCountry: (payload) => axios({
        method: 'get',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/account/getSupportCountry',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded"}
    }),
    //根据当前国家ip地址，获取当前国家id
    getCurrentCountryByIp: (payload) => axios({
        method: 'get',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/user/info/getCurrentCountryByIp',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded"}
    }),
    //修改国家地区    
    updateCountry: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/user/info/update/countryId',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //获取语言列表    
    getLanguage: (payload) => axios({
        method: 'get',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/account/getLanguage',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded"}
    }),
    //获取手机验证码    
    phoneCode: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/account/sms/code',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded"}
    }),
    //第三方注册/登录    
    loginByThird: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/account/login_by_third',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded"}
    }),
    //手机号注册/登录    
    phoneReg: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/account/login-by-sms',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded"}
    }),
    //获取邮箱验证码   
    emailCode: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/account/mail/send',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded"}
    }),
    //邮箱注册    
    emailReg: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/account/register/email',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded"}
    }),
    //查询用户基本资料
    loginStatus: (payload) => axios({
        method: 'get',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/login-status',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //登录    
    login: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/account/login',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded"}
    }),
    //重置/忘记密码    
    resetPassword: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/user/password/resetPassword',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded"}
    }),
    //登出    
    logout: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/account/logout',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded"}
    }),
    //完善资料    
    addCustomerEmailOrMobile: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/user/info/addCustomerEmailOrMobile',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //查询用户基本资料    
    findUserInfo: (payload) => axios({
        method: 'get',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/user/info/findUserInfo',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //查询邀请奖励记录    
    findMyInviteRewardDetail: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v3/user/attendScore/invite/findMyInviteRewardDetail',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //获取交易账户信息    
    queryTradingAccount: (payload) => axios({
        method: 'get',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/trade/queryTradingAccount',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //获取用户身份验证信息    
    identity: (payload) => axios({
        method: 'get',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/user/info/identity',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //修改/绑定邮箱    
    updateEmail: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        url: '/api/v2/user/info/updateEmail',
        withCredentials: true,
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //修改/绑定手机号码    
    updateMobile: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/user/info/updateMobile',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //修改密码    
    setPassword: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/user/password/setPassword',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //修改昵称    
    updateNickname: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/user/info/updateNickname',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //修改头像    
    uploadAvatar: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/upload/upload-avatar',
        data: payload,
        headers: {"Content-Type": "multipart/form-data", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //上传身份证照片    
    uploadIdImg: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/upload/upload-id-img',
        data: payload,
        headers: {"Content-Type": "multipart/form-data", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //上传银行转账照片    
    uploadBankImg: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/upload/upload-full',
        data: payload,
        headers: {"Content-Type": "multipart/form-data", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //获取银行列表    
    getAllBankByList: (payload) => axios({
        method: 'get',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/withdraw/getAllBankByCountryId',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //提交银行信息，去验证   
    updateBankCardInfo: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/user/info/updateBankCardInfo',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //获取身份证信息    
    getIDCardInfo: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/user/info/getIDCardInfo',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //实名认证    
    realname: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/user/info/identity/realname',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //资金流水信息    
    cashflow: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v3/payment/offset/cashflow',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //交易历史    
    queryHistoryOrder: (payload) => axios({
        method: 'get',
        baseURL: Config.api,
        url: '/api/v3/trade/history',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //挂单查询    
    queryLimitOrder: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v3/trade/queryLimitOrder',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //持仓查询    
    queryOpenOrder: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v3/trade/queryOpenOrder',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //取消出金申请    
    cancelWithdraw: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/withdraw/cancel',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //手机号注册
    //  phoneRegister: (payload) => axios.get(Config.api+'/api/v2/account/getCountry',payload,HEADERS_FORM_URLENCODED),
    //邮箱注册
    //  emailRegister: (payload) => axios.post('/api/tw/user/register',payload,HEADERS_FORM_URLENCODED),
    //账号登录
    //  loginByAccount: (payload) => axios.post('/api/tw/user/login',payload,HEADERS_FORM_URLENCODED),
    //手机号登录
    //  loginByPhone: (payload) => axios.post('/api/tw/user/free-login',payload,HEADERS_FORM_URLENCODED),
    //短信验证码
    //  fetchVerCode: (payload) => axios.post('/api/tw/sms/captcha/code',payload,HEADERS_FORM_URLENCODED)
    //入金 - 金额列表
    getPaymentSetting: (payload) => axios({
        method: 'get',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/payment/settings/getPayMoneySetting',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //入金 - 渠道列表
    getPaymentChannels: (payload) => axios({
        method: 'get',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/payment/deposit/get-payments',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //信用卡入金 - 客户信息
    getQueryCreditCard: (payload) => axios({
        method: 'get',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/user/info/queryCreditCard',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //信用卡入金提交
    creditCardDepositApplySubmit: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/payment/deposit/credit_card',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //入金申请
    depositApply: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/payment/deposit/apply',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //入金确认
    depositConfirm: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/payment/deposit/confirm',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //入金提交
    depositApplySubmit: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/payment/deposit/submit',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //入金通道提示接口
    depositApplyTips: (payload) => axios({
        method: 'get',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/payment/deposit/dialog',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //查询订单
    queryPaymentOrder: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/payment/deposit/query/payment/order',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //查询订单信息
    queryOrderByOrder: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v3/payment/deposit/getOrderByOrderId',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //入金测试通道
    testpay: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/payment/deposit/testpay-complete',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //是否允许出金
    isWithdrawHoliday: (payload) => axios({
        method: 'get',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/withdraw/holiday',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //出金配置信息
    getWithdrawConfig: (payload) => axios({
        method: 'get',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/withdraw/get-config',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //出金申请
    withdrawApply: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/withdraw/apply',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //取消出金申请
    withdrawCancel: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/withdraw/cancel',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //获取邀请奖励
    getInviteRule: (payload) => axios({
        method: 'post',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/user/attendScore/invite/getInviteRewardRuleList',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //app 注册成功的h5页面广告
    getAdvertiseByCustomer: (payload) => axios({
        method: 'get',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/account/getAdvertiseByCustomer',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    }),
    //首次入金进入入金页面，判断ip地址是否与所选地区一致
    checkCountryByIp: (payload) => axios({
        method: 'get',
        baseURL: Config.api,
        withCredentials: true,
        url: '/api/v2/user/info/checkCountryByIp',
        params: payload,
        headers: {"Content-Type": "application/x-www-form-urlencoded", "X-IDENTIFY-TOKEN": Config.userToken}
    })
}

export default account