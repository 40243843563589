export default {
    header: {
        login: 'ล็อกอิน ',
        Logout: 'ออกจากระบบ ',
        register: 'ลงทะเบียน ',
        appDownload: 'ดาวน์โหลดAPP ',
        headerDow: 'ดาวน์โหลดแอปเพื่อรับโบนัส ',
        headerDowBtn: 'ดาวน์โหลด ',
    },
    loginRegister: {
        // 登录注册 
        loginLeftTitle1: 'สวัสดิการโบนัส ',
        loginLeftTitle2: 'รับขั้นต่ำ＄120 ฝากเงินรับคืนทันที ',
        loginRightTitle1: 'คุณมีบัญชีซื้อขายใช่หรือไม่ ',
        loginRightTitle2: 'ลงทะเบียนบัญชี ',
        loginRightTitle3: 'ล็อกอินโดยรหัสยืนยันมือถือ ',
        loginRightTitle4: 'ลืมรหัสผ่าน ',
        loginInput1: 'บัญชีซื้อขาย / บัญชีอีเมล',
        loginInput2: 'รหัสผ่าน',
        regLeftTitle1: 'เพียงไม่กี่ขั้นตอนก็เสร็จสิ้นการเปิดบัญชี ',
        regLeftTitle2: '0ค่าคอมมิชชันสัมผัสกับแพลตฟอร์มการซื้อขายที่มีสเปรดต่ำและการดำเนินการที่มีประสิทธิภาพ ',
        loginTitle1:'เคยลงทะเบียนเเล้ว ？ ',
        loginTitle2:'เข้าสู่ระบบ',
        loginTitle3:'ไม่มีบัญชี ? ',
        loginTitle4:'ลงทะเบียน',
        loginTitle5:'รหัสการเชิญ（ไม่จำเป็น）',
        loginTitle6:'ฉันเห็นด้วย',
        retrievePassTitle1:'โทรศัพท์รีเซ็ตรหัสผ่าน',
        retrievePassTitle2:'อีเมลรีเซ็ตรหัสผ่าน',
        mobileRegRightTitle1: 'ลงทะเบียน/ล็อกอินบนมือถือ ',
        mobileRegRightTitle2: 'หมายเลขโทรศัพท์ที่ไม่ได้ลงทะเบียนจะสร้างบัญชีโดยอัตโนมัติ ',
        moblieInput: 'หมายเลขโทรศัพท์',
        codeInput: 'รหัสยืนยัน',
        getCode: 'รับ',
        resend: 'ส่งใหม่ ',
        regLogin: 'ลงทะเบียน/ล็อกอิน ',
        regButtomTitle1: 'หรือสร้างบัญชีด้วยวิธีต่อไปนี้ ',
        regButtomTitle2: 'คลิกลงทะเบียน/ล็อกอินแสดงว่าคุณยอมรับ ',
        agreement: '<<ข้อตกลงผู้ใช้>>',
        emailRegRightTitle1: 'การลงทะเบียนด้วยอีเมล ',
        countryInput: 'เลือกประเทศที่พำนัก/ภูมิภาค ',
        emailInput: 'บัญชีอีเมล',
        passInput: 'รหัสผ่านผสมตัวเลขและตัวอักษร 6-15 ตัว',
        // 注册成功
        loginSuccessful: 'ลงทะเบียนสำเร็จ ',
        loginSuccTitle: 'ฝากเงินตอนนี้ จะได้รับโบนัสเงินดอลล่าร์สูงสุด ',
        loginSuccBtn1: 'ฝากเงินทันทีเพื่อรับ ',
        loginSuccBtn2: 'ยืนยันตัวตนสำเร็จ ',
        loginSuccEmail: 'อีเมลของคุณได้รับการลงทะเบียนแล้ว กรุณาล็อกอิน ',
        // 忘记密码
        retrievePassword: 'เรียกคืนรหัสผ่าน ',
        retrievePassTab1: 'เรียกคืนรหัสผ่านทางอีเมล ',
        retrievePassTab2: 'เรียกคืนรหัสผ่านทางหมายเลขโทรศัพท์ ',
        resetPassword: 'ยืนยัน ',
        resetPasswordTitle: 'รีเซ็ตรหัสผ่านสำเร็จ กรุณาล็อกอินใหม่ ',
        returnLogin: 'กลับไปยังหน้าล็อกอิน ',
        checkPrompt1: 'อีเมลบัญชีไม่สามารถเว้นว่างได้ ',
        checkPrompt2: 'รหัสผ่านไม่สามารถเว้นว่างได้ ',
        checkPrompt3: 'รูปแบบรหัสผ่านไม่ถูกต้อง ',
        checkPrompt4: 'หมายเลขโทรศัพท์ไม่สามารถเว้นว่างได้ ',
        checkPrompt25: 'กรุณากรอกหมายเลขโทรศัพท์ใหม่ ',
        checkPrompt5: 'รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง ',
        checkPrompt6: 'รหัสยืนยันไม่สามารถเว้นว่างได้ ',
        checkPrompt7: 'กรุณาเลือกประเทศ ',
        checkPrompt27: 'โปรดเลือกชื่อธนาคาร ',
        checkPrompt8: 'อีเมลไม่สามารถเว้นว่างได้ ',
        checkPrompt26: 'กรุณากรอกอีเมลใหม่ ',
        checkPrompt9: 'รูปแบบอีเมลไม่ถูกต้อง ',
        checkPrompt10: 'รหัสผ่านปัจจุบันไม่สามารถเว้นว่างได้ ',
        checkPrompt11: 'รูปแบบรหัสผ่านปัจจุบันไม่ถูกต้อง ',
        checkPrompt12: 'รหัสผ่านใหม่ไม่สามารถเว้นว่างได้ ',
        checkPrompt13: 'รูปแบบรหัสผ่านใหม่ไม่ถูกต้อง ',
        checkPrompt14: 'ยืนยันรูปแบบรหัสผ่านไม่ถูกต้อง ',
        checkPrompt15: 'ยืนยันรหัสผ่านไม่สามารถเว้นว่างได้ ',
        checkPrompt16: 'รหัสผ่านใหม่ทั้งสองไม่ตรงกัน ',
        checkPrompt17: 'รหัสธนาคารไม่สามารถเว้นว่างได้ ',
        checkPrompt18: 'ชื่อสาขาไม่สามารถเว้นว่างได้ ',
        checkPrompt19: 'ชื่อธนาคารไม่สามารถเว้นว่างได้ ',
        checkPrompt20: 'บัญชีธนาคารไม่สามารถเว้นว่างได้ ',
        checkPrompt21: 'รายการเดินบัญชีธนาคารไม่สามารถเว้นว่างได้ ',
        checkPrompt22: 'ชื่อไม่สามารถเว้นว่างได้ ',
        checkPrompt23: 'รูปถ่ายหน้าบัตรประชาชนไม่สามารถเว้นว่างได้ ',
        checkPrompt24: 'รูปถ่ายหลังบัตรประชาชนไม่สามารถเว้นว่างได้ ',
    },
    perfectData: {
        // 完善资料 
        perfectDataLeftTitle1: 'เปิดใช้งานบัญชีจริง ',
        perfectDataLeftTitle2: 'เพื่อตอบสนองเงื่อนไขการดูแลจัดการ ต้องยืนยันตัวตนสำเร็จถึงจะดำเนินการถอนเงินได้ ',
        perfectData: 'ข้อมูลครบถ้วน ',
        step1Title1: 'กรอกข้อมูลส่วนตัวเบื้องต้น ',
        identityAuthentication: 'ยืนยันบัตรประจำตัว ',
        step2Title1: 'ตามเงื่อนไขการดูแลจัดการ กรุณากรอกข้อมูลยืนยันดังต่อไปนี้： ',
        step2Title2: '①ยืนยันบัตรประจำตัว ',
        step2Title3: '②เพิ่มบัตรธนาคาร ',
        step2Title4: 'ยืนยันตัวตน ',
        step3Title1: 'ฝากเงินเพื่อรับโบนัส ',
        step3Title2: 'จะได้รับโบนัสสูงสุดถึงUSD2400 ',
        step3Title3: 'ฝากเงินตอนนี้ ได้รับรางวัลโบนัสมากมาย ',
        step3Title4: 'ประเทศที่พำนักอาศัย ',
        perfectDataTips: 'ประเทศ/พื้นที่ที่ฉันอาศัยอยู่ สัญชาติและประเทศที่จ่ายภาษีเป็นประเทศเดียวกัน ',
        nextStep: 'ขั้นตอนต่อไป ',
        //身份认证
        identityAuthenTitle1: 'เพื่อตอบสนองเงื่อนไขการดูแลจัดการ เปิดใช้งานฟังก์ชั่นฝากเงินและซื้อขายต้องยืนยันตัวตนก่อน ',
        identityAuthenTitle2: 'คลิกเพื่ออัพโหลดรูปหน้าบัตรประชาชน ',
        identityAuthenTitle3: 'คลิกเพื่ออัพโหลดรูปหลังบัตรประชาชน ',
        // 个人信息确认 
        informationConfirmTitle1: 'ยืนยันข้อมูลส่วนตัว ',
        informationConfirmTitle2: 'คุณจะใช้ข้อมูลต่อไปนี้เพื่อยืนยันตัวตน กรุณาตรวจสอบให้แน่ใจว่าข้อมูลถูกต้องและตรงกับที่ปรากฏบนบัตรประจำตัวของคุณ ',
        informationConfirmTitle3: 'ชื่อ-นามสกุลเต็ม： ',
        informationConfirmTitle4: 'หมายเลขบัตรประชาชน： ',
        informationConfirmTitle5: 'ข้อมูลข้างต้นจะใช้ภายในขอบเขตของนโยบายความเป็นส่วนตัวเท่านั้น ',
        modify: 'แก้ไข ',
        // 添加银行卡
        addBankTitle1: 'เพิ่มบัญชีธนาคาร/บัตร ',
        addBankTitle2: 'เพื่อตอบสนองเงื่อนไขการดูแลจัดการ เพื่อความปลอดภัยและการถอนเงินของคุณอย่างราบรื่น กรุณาเพิ่มบัญชีธนาคาร/บัตรธนาคารของคุณ ',
        addBankTitle3: 'กรุณาอัพโหลดข้อมูลบัญชีธนาคารหรือสมุดบัญชีธนาคาร ',
        addBankTitle4: 'รวมถึง: ชื่อธนาคาร/โลโก้ ชื่อเจ้าของบัญชีและหมายเลขบัญชีธนาคารแบบสมบูรณ์ ',
        addBankInput1: 'กรุณาป้อนรหัสธนาคาร ',
        addBankInput2: 'กรุณาป้อนชื่อธนาคาร ',
        addBankInput3: 'กรุณาป้อนชื่อสาขา ',
        addBankInput4: 'กรุณาป้อนบัญชีธนาคาร ',
        addBankInput5: 'รหัสธนาคาร ',
        addBankInput6: 'ชื่อธนาคาร ',
        addBankInput7: 'ชื่อสาขาธนาคาร ',
        addBankInput8: 'หมายเลขธนาคาร ',
        example: 'ตัวอย่าง ',
        twBankName1: 'Cathay United Bank ',
        twBankName2: 'CTBC Bank ',
        twBankName3: 'Chunghwa Post ',
        twBankData1: 'Cathay United Bank ',
        twBankData2: 'Huashan Branch ',
        thBankName1: 'ธนาคารกสิกรไทย ',
        thBankName2: 'ธนาคาร กรุง ไทย ',
        thBankName3: 'ธนาคารกรุงเทพ ',
        thBankData1: 'ธนาคารกสิกรไทย ',
        thBankData2: 'สำนักงานกรุงเทพฯ ',
        toVerify: 'ไปยืนยัน ',
        authenticationSuccessful: 'ยืนยันสำเร็จ ',
        popPrompt: 'เพื่อตอบสนองเงื่อนไขการดูแลจัดการ กรุณายืนยันตัวตนสำเร็จก่อน เพื่อหลีกเลี่ยงผลกระทบต่อฟังก์ชั่นการถอนเงิน ',
        popPrompt1: 'ประเทศ/ภูมิภาคปัจจุบันยังไม่มีฟังก์ชั่นการถอนเงิน กรุณาติดต่อฝ่ายบริการลูกค้าเพื่อสอบถามรายละเอียด ',
        cancel: 'ยกเลิก ',
        continue: 'ต่อไป ',
        goLogin: 'ไปล็อกอิน ',
        determine: 'ยืนยัน ',
        authenticationFail: 'ยืนยันล้มเหลว ',
        authenticationFailTitle1: 'ขออภัย การยืนยันตัวตนของคุณล้มเหลว กรุณาอัพโหลดอีกครั้ง ',
        authenticationFailTitle2: 'กรุณาตรวจสอบ 2 ข้อต่อไปนี้ก่อนอัพโหลด： ',
        authenticationFailTitle3: '1.ยืนยันว่าการอัปโหลดเป็นบัตรประชาชนที่ถูกต้องของคุณ ',
        authenticationFailTitle4: '2. ข้อมูลที่แสดงบนบัตรประชาชนชัดเจนและครบถ้วน ',
        returnModify: 'กลับไปแก้ไข ',
        onlineConsultation: 'สอบถามออนไลน์ ',
        immediateDeposit: 'ฝากเงินทันที ',
    },
    accountCenter: {
        // 首页
        homeTitle1: 'ข้อมูลส่วนตัว ',
        miniAccount: 'บัญชีมินิ ',
        standardAccount: 'บัญชีมาตรฐาน ',
        highendAccount: 'บัญชีวีไอพี ',
        homeTitle2: 'รับเงินดอลลาร์โดยการแนะนำลูกค้า ',
        homeTitle3: 'ทุกๆครั้งที่แนะนำเพื่อนซื้อขายสำเร็จ 1 คน ',
        activityRules: 'กฎของกิจกรรม ',
        viewRewards: 'ดูรางวัล ',
        activityListTitle1: 'คุณจะได้รับ ',
        activityListTitle2: 'เพื่อนของคุณจะได้รับ ',
        activityListTitle3: 'แบ่งปันลิ้งค์ของคุณ： ',
        copyLink: 'คัดลอกลิ้งค์ ',
        copySucc: 'คัดลอกสำเร็จ ',
        myRewards: 'รางวัลของฉัน ',
        rewardTitle1: 'จำนวนรางวัล ',
        rewardTitle2: 'บัญชีซื้อขายของเพื่อน ',
        rewardTitle3: 'เวลาลงทะเบียนของเพื่อน ',
        rewardTitle4: 'สถานะรางวัล ',
        rewardState1: 'แจกแล้ว ',
        rewardState2: 'ไม่ได้มาตรฐาน ',
        rewardState3: 'รอคอยได้มาตรฐาน ',
        accountNumber: 'บัญชีซื้อขาย： ',
        email: 'อีเมล： ',
        phoneNumber: 'หมายเลขโทรศัพท์： ',
        residentialArea: 'ที่อยู่อาศัย： ',
        accountValue: 'บัญชีค่าสุทธิ ',
        creditGift: 'โบนัสเครดิต ',
        activityReward: 'รางวัลกิจกรรม ',
        availablePoints: 'คะแนนสะสม ',
        deposit: 'ฝากเงิน ',
        accountTips1: 'ยอดคงเหลือจริงได้เปลี่ยนแปลงตามกำไร-ขาดทุนของการถือครองคำสั่งซื้อสินค้า(รวมถึงคำสั่งซื้อสินค้าที่ไม่ได้ปิดสถานะกำไร-ขาดทุน)บัญชีค่าสุทธิมีผลในระยะเวลาที่กำหนด กรุณาล็อกอินเข้าสู่แพลตฟอร์มการซื้อขายเพื่อดูค่าสุทธิที่เเท้จริง ',
        accountTips2: 'เข้าร่วมแคมเปญโบนัสเพื่อรับยอดรวมเครดิตโบนัส ',
        accountTips3: 'ผลรวมของรางวัลและโบนัสที่ผู้ใช้ได้รับจากการเข้าร่วมกิจกรรม ',
        accountTips4: 'คะแนนสะสมที่สามารถแลกได้ ',
        notice: 'ประกาศ ',
        // 入金页面
        withdraw: 'ถอนเงิน ',
        capitalFlow: 'กระแสบัญชี ',
        tradeQuery: 'ค้นหาการซื้อขาย ',
        depositTite1: 'บัญชีเงินฝาก ',
        depositTite2: 'จำนวนเงินฝาก ',
        depositTite3: 'จำนวนเงินที่ชำระโดยประมาณ ',
        depositTite4: 'กิจกรรมโบนัส ',
        depositTite5: 'วิธีฝากเงิน ',
        depositTite6: 'แนะนำบัญชี ',
        depositTite7: 'ความปลอดภัยทางการเงิน ',
        depositTite8: 'แจ้งการฝากเงิน ',
        depositTite9: 'ธนาคารชำระเงิน ',
        depositTite10: 'กรุณาเลือกธนาคาร ',
        depositTite11: 'คู่มือการใช้งาน ',
        depositTite12: 'การชำระเงินโดยประมาณ ',
        depositTite13: 'หลังฝากเงินติดต่อฝ่ายบริการลูกค้าสมัครกิจกรรมเพื่อรับโบนัส! ',
        depositTite14: 'ช่องทางการฝากเงินเพิ่มเติม ',
        depositTite15: 'เลือกโหมดการฝากเงิน ',
        depositTite16: '7-11 ซูเปอร์มาร์เก็ต ',
        depositTite17: 'แฟมิลี่มาร์ท ',
        depositTite18: 'Lair Fu ซูเปอร์มาร์เก็ต ',
        subDeposit: 'ส่งการฝากเงิน ',
        accountType: 'ประเภทบัญชี ',
        accountTypeTitle1: 'จำนวนเงินฝากขั้นต่ำ ',
        accountTypeTitle2: 'สเปรดขั้นต่ำ ',
        fundSecurityList1: 'ค่าธรรมเนียม 0 ',
        fundSecurityList2: 'เข้าบัญชีทันที ',
        fundSecurityList3: 'การจัดการเงินฝากอิสระ ',
        depositTips1: '1. จำนวนเงินฝากขั้นต่ำ?',
        depositTips2: '2. อัตราแลกเปลี่ยนเงินฝากตามมาตรฐานการแปลงใด?',
        depositTips3: '3. จำเป็นต้องเปิดบัญชี USD เพื่อการฝากเงินหรือไม่?',
        depositTips4: '4. ใช้เวลานานเท่าใดกว่าจะมาถึงบัญชีหลังจากการฝากเงิน?',
        depositTips10: '5. สามารถใช้บัญชีของผู้อื่นเพื่อฝากเงินไหม?',
        depositTips11: '6. เงินทุนจะเก็บไว้ที่ไหนหลังจากการฝากเงิน?',
        depositTips01: 'จำนวนเงินฝากขั้นต่ำ: ',
        depositTips02: 'จำนวนเงินที่ชำระจะถูกแปลงโดยอัตโนมัติตามอัตราแลกเปลี่ยนราคากลางของวัน',
        depositTips03: 'ไม่จำเป็น คุณสามารถใช้บัตรธนาคาร บัตรเครดิต ด้วยธนาคารออนไลน์หรือธนาคารบนมือถือ ฯลฯ เพื่อโอนเงินสำหรับการฝากเงิน และสกุลเงินที่คุณต้องชำระจะถูกแปลงตามอัตราแลกเปลี่ยน ณ เวลานั้นตามจำนวนเงินฝาก (USD) ที่คุณโอน หลังจากการชำระเงินสำเร็จ เงินทุนจะถูกโอนเข้าบัญชีซื้อขายของคุณโดยอัตโนมัติ',
        depositTips04: 'โดยทั่วไป จะมาถึงบัญชีทันที หากยังไม่มาถึงบัญชีซื้อขายภายใน 10 นาที โปรดติดต่อฝ่ายบริการลูกค้าออนไลน์เพื่อสอบถาม',
        depositTips010: 'ไม่ได้ เพื่อความปลอดภัยในบัญชีของคุณ ทางเราไม่รับเงินทุนจากบัญชีธนาคารที่ไม่ใช่บัญชีส่วนตัว',
        depositTips011: 'การจัดเก็บที่เป็นอิสระ และถูกจัดขึ้นในรูปแบบของการดูแลที่ได้รับมอบหมาย เพื่อปกป้องผลประโยชน์ของลูกค้า',
        depositTips5: '5.สำหรับคำแนะนำฝากเงินอย่างละเอียดและวิธีการดำเนินการ โปรดคลิกที่นี่ ',
        depositTips6: '1. จำนวนเงินที่โอนต้องสอดคล้องกับจำนวนเงินฝากที่กรอก ',
        depositTips7: '2. โปรดระวังอย่าทำการโอนเงินซ้ำ ',
        depositTips8: 'อย่าแจ้งเตือนฉันในครั้งต่อไป ',
        depositTips9: 'โอนเดียวนี้ ',
        depositSucc: 'ฝากเงินสำเร็จ ',
        depositFail: 'ฝากเงินล้มเหลว ',
        depositTime: 'เวลาฝากเงิน ',
        depositGuide: 'คู่มือการฝากเงิน ',
        dollar: 'USD ',
        singleLimit: 'จำกัดจำนวนเงินสำหรับคำสั่งซื้อเดียว ',
        creditCardText1: 'กรุณากรอกข้อมูลบัตรเครดิต ',
        creditCardText2: 'กรุณากรอกหมายเลขบัตรเครดิต ',
        creditCardText3: 'กรุณากรอกหมายเลขบัตรเครดิตที่ถูกต้อง ',
        creditCardText4: 'วันหมดอายุ(เดือน/ปี) ',
        creditCardText5: 'CVV ',
        creditCardText6: 'กรุณากรอกวันหมดอายุบัตรเครดิต ',
        creditCardText7: 'กรุณากรอกเลขความปลอดภัยที่ถูกต้อง ',
        creditCardText8: 'ชื่อผู้ถือบัตร ',
        creditCardText9: 'ชื่อ ',
        creditCardText10: 'นามสกุล ',
        creditCardText11: 'กรุณากรอกชื่อ ',
        creditCardText12: 'กรุณากรอกนามสกุล ',
        creditCardText13: '* บัตรเครดิตนี้ควรเป็นของคุณและใช้ในชื่อของคุณ ',
        creditCardText14: 'ต่อไป ',
        // 入金转账页面
        onlineBankTitle1: 'ข้อมูลบัญชีผู้รับ ',
        onlineBankTitle2: 'คุณต้องใช้บัญชีธนาคารของคุณเองในการโอนเงิน ',
        onlineBankTitle2CSV: 'คุณต้องใช้บัญชีธนาคารของคุณสำหรับการชำระเงิน ',
        onlineBankTitle3: 'กรุณา ',
        onlineBankTitle4: 'ดำเนินการให้เสร็จสิ้นภายใน ',
        onlineBankTitle5: 'คำแนะนำการใช้งาน： ',
        onlineBankTitle6: '1. คัดลอกจำนวนเงินที่โอน ธนาคารผู้รับเงิน รหัสธนาคาร และหมายเลขบัญชีผู้รับเงินไปยังธนาคารบนมือถือ/ธนาคารออนไลน์ของคุณเองเพื่อดำเนินการโอนเงิน ทั้งในธนาคารเดียวกันหรือข้ามธนาคาร； ',
        onlineBankTitle6CSV: '1.ป้อนจำนวนเงินที่ชำระและรหัสการชำระเงินลงในหน้าการชำระเงินของซุปเปอร์มาร์เก็ตการชำระเงิน； ',
        onlineBankTitle7: '2.อย่าบันทึกข้อมูลการโอนเงินเพื่อโอนเงินซ้ำ； ',
        onlineBankTitle7CSV: '2.อย่าบันทึกข้อมูลการชำระเงินสำหรับการชำระเงินซ้ำ； ',
        onlineBankTitle8: '3.หากยอดโอนไม่ตรงกับยอดฝากเงินที่ยื่นคำร้อง จะโอนเงินไม่สำเร็จ； ',
        onlineBankTitle9: '4.กรุณาทำการโอนเงินให้เสร็จสิ้นภายใน 15 นาที มิฉะนั้นจะฝากเงินไม่สำเร็จ ',
        onlineBankTitle9CSV: '3.กรุณาชำระเงินให้เสร็จสิ้นภายใน 15 นาที มิฉะนั้น การฝากเงินจะไม่สำเร็จ。 ',
        onlineBankTitle10: 'เงินจะถูกเก็บไว้ใน CM Trade อย่างอิสระ โปรดวางใจที่จะชำระ ',
        copy: 'คัดลอก ',
        remittanceAmount: 'จำนวนเงินโอน： ',
        remittanceAmountCSV: 'จำนวนเงินที่ชำระ： ',
        collectionBank: 'ธนาคารผู้รับ： ',
        collectionBankCSV: 'ผู้รับเงิน： ',
        collectionAccount: 'เลขที่บัญชีผู้รับ： ',
        collectionAccountCSV: 'รหัสการชำระเงิน： ',
        confirm: 'ยืนยัน ',
        //出金页面
        withdrawTitle1: 'บัญชีถอนเงิน ',
        withdrawTitle2: 'เงินทุนที่ถอนได้ ',
        withdrawTitle3: 'จำนวนเงินถอน ',
        withdrawTitle4: 'จำนวนเงินโดยประมาณที่จะได้รับ ',
        withdrawTitle5: 'ชื่อ นามสกุลบัญชี ',
        withdrawTitle6: 'เข้าบัญชีธนาคาร ',
        subWithdraw: 'ส่งการถอนเงิน ',
        applySucc: 'สมัครสำเร็จ ',
        applyFail: 'การสมัครล้มเหลว ',
        applySuccStep1: 'รอรับการตรวจสอบ ',
        applySuccStep2: 'การยอมรับของธนาคาร ',
        applySuccStep3: 'การถอนเงินเสร็จสิ้น ',
        applySuccStep4: 'ประมาณหนึ่งวันทำการ ',
        cancelWithdraw: 'ยกเลิกคำขอถอนเงิน ',
        reminder: 'เคล็ดลับชนิด ',
        reminder1: 'เคล็ดลับชนิด: ',
        withdrawTips1: '1.หากเข้าร่วมกิจกรรมเงินโบนัส ยื่นคำร้องถอนเงินถือว่ายอมรับการออกจากกิจกรรมเงินโบนัส จะถูกหักเงินโบนัสและปริมาณการซื้อขายที่สะสมมาและจะไม่ได้รับผลกำไร ',
        withdrawTips2: '2. เนื่องจากอิทธิพลของการยอมรับของธนาคารบางแห่ง อาจมีความล่าช้าในการเข้าถึงบัญชี และเวลาที่เข้าถึงจริงจะมีผลเหนือกว่า ',
        withdrawTips3: 'จำนวนเงินการถอนขั้นตํ่าสุด ',
        withdrawTips4: 'จำนวนเงินการถอนสูงสุด ',
        withdrawTips5: 'จำนวนเงินที่ถอนเกินจำนวนที่มีอยู่ ',
        withdrawTips6: 'เงินทุนที่สามารถถอนได้=เงินจ่ายล่วงหน้าที่ใช้ได้-เครดิต-0.4*เงินจ่ายล่วงหน้าที่ใช้แล้ว（0.4*เงินจ่ายล่วงหน้าที่ใช้แล้วเป็นอัตราการป้องกันBlow up）',
        understand: 'เข้าใจ ',
        //资金流水
        capitalFlowTitle1: 'ประเภท: ',
        capitalFlowTitle2: 'สถานะ: ',
        capitalFlowTitle3: 'เวลา: ',
        timeSelect1: '7 วันที่ผ่านมา ',
        timeSelect2: 'เดือนนี้ ',
        timeSelect3: 'เดือนที่แล้ว ',
        timeSelect4: 'ปีที่แล้ว ',
        orderNumber: 'หมายเลขคำสั่งซื้อ ',
        money: 'จำนวนเงิน ',
        applyTime: 'เวลาสมัคร ',
        state: 'สถานะ ',
        type: 'ประเภท ',
        home: 'หน้าแรก ',
        lastPage: 'หน้าสุดท้าย ',
        previousPage: 'หน้าก่อน ',
        nextPage: 'หน้าต่อไป ',
        Jump: 'กระโดด ',
        totalNumber: 'จำนวนทั้งหมด ',
        pageCount: 'หน้าทั้งหมด ',
        depositFlow: 'การไหลของเงินฝาก ',
        withdrawFlow: 'การถอนเงิน ',
        completed: 'สำเร็จ ',
        notCompleted: 'ยังไม่สำเร็จ ',
        closed: 'ปิด ',
        untreated: 'ยังไม่ได้จัดการ ',
        paymentTime: 'เวลาชำระ ',
        cancelWithdraw1: 'ยกเลิกคำร้อง ',
        rewardTime: 'เวลาแจกรางวัล ',
        rewardType: 'ประเภทรางวัล ',
        //交易查询
        tradeHistory: 'ประวัติการซื้อขาย ',
        pendingOrder: 'ตรสจสอบคำสั่งซื้อขายที่รอดำเนินการ ',
        holdOrder: 'ตรวจสอบการถือครอง ',
        varieties: 'ประเภท ',
        tradingVolume: 'ปริมาณการซื้อขาย ',
        openingPrice: 'ราคาเปิดสถานะ ',
        openingTime: 'เวลาเปิดสถานะ ',
        closingPrice: 'ราคาปิดสถานะ ',
        closingTime: 'เวลลาปิดสถานะ ',
        hangingOrderPrice: 'ราคาคำสั่งซื้อขายที่รอดำเนินการ ',
        hangingOrderTime: 'เวลาคำสั่งซื้อขายที่รอดำเนินการ ',
        stopLoss: 'หยุดการขาดทุน ',
        stopSurplus: 'หยุดทำกำไร ',
        overnightInterest: 'ดอกเบี้ยค้างคืน ',
        profit: 'กำไร ',
        totalProfit: 'กำไรทั้งหมด： ',
        totalVolume: 'จำนวนการซื้อขายทั้งหมด： ',
        hand: 'หน่วย ',
        dataStatus1: 'ซื้อ ',
        dataStatus2: 'ขาย ',
        dataStatus3: 'เครดิต ',
        dataStatus4: 'ซื้อเพื่อหยุดการขาดทุน ',
        dataStatus5: 'จำกัดการซื้อ ',
        dataStatus6: 'ขายเพื่อหยุดการขาดทุน ',
        dataStatus7: 'จำกัดการขาย ',
        dataStatus8: 'จำนวนเงินฝากขั้นต่ำสุด ',
        dataStatus9: 'จำนวนเงินฝากสูงสุด ',
        dataStatus10: 'แลกคะแนนเพื่อรับรางวัล ',
        dataStatus11: 'โบนัสเงินคืน ',
        dataStatus12: 'รางวัลแบบทดสอบ EIA ',
        dataStatus13: 'รางวัลแบบทดสอบนอกภาคเกษตร ',
        dataStatus14: 'ยังไม่มีข้อมูลสำหรับคุณ ',
        //邀请活动规则 
        inviteActivityTitle1: '1. คำเชิญให้เข้าร่วมคำแนะนำ',
        inviteActivityTitle2: '2. คำอธิบายของผู้ได้รับเชิญ',
        inviteActivityTitle3: '3. ข้อควรระวัง',
        inviteRule1: '(1) ผู้ใช้ทุกคนที่ลงทะเบียนสำหรับบัญชีข้อเสนอจริงสามารถแบ่งปันกิจกรรมและเป็นผู้เชิญ',
        inviteRule2: '(2) ทุกครั้งที่ผู้เชิญเชิญผู้ใช้ให้เปิดบัญชีจริง ผู้รับเชิญจะได้รับรางวัล $15 หากเปิดใช้งานบัญชีภายใน 30 วันหลังจากเปิดบัญชีและซื้อขาย 1 ล็อตมาตรฐาน (สะสมสำหรับความหลากหลายใดๆ ยกเว้นสกุลเงินเสมือนจริง)',
        inviteRule3: '(3) รางวัลคำเชิญของผู้เชิญจะถูกแจกจ่ายโดยตรงไปยังบัญชีซื้อขายจริงที่เกี่ยวข้องกับผู้ใช้ที่เข้าร่วมในกิจกรรมคำเชิญ และไม่สามารถแทนที่ได้',
        inviteRule4: '(4) ผู้เชิญสามารถรับรางวัลสูงถึง $200 ในรางวัลเชิญทุกสัปดาห์ และรางวัลที่ได้รับจากผู้เชิญและผู้เชิญสามารถนำไปใช้โดยตรงสำหรับการถอนเงินสดหรือการซื้อขาย',
        unInviteRule1: '(1) ผู้รับเชิญจะต้องไม่เคยเปิดบัญชีซื้อขายของบริษัทใดๆ ในบริษัทของเรา',
        unInviteRule2: '(2) ผู้ได้รับเชิญต้องลงทะเบียนและเปิดบัญชีผ่านรหัส QR/ลิงก์ส่งเสริมการขายที่ผู้เชิญแบ่งปัน เปิดใช้งานบัญชีภายใน 30 วันหลังจากเปิดบัญชีและซื้อขาย 1 ล็อตมาตรฐาน (สะสมสำหรับความหลากหลายใดๆ ยกเว้นสกุลเงินเสมือน) และรับ โบนัส $5 ;',
        noticeTip1: '(1) พนักงานของเราและครอบครัวของพวกเขาไม่มีสิทธิ์สำหรับกิจกรรมนี้ และการยกเลิกบัญชีและการเปิดบัญชีใหม่จะใช้ไม่ได้กับกิจกรรมนี้ และบริษัทของเราขอสงวนสิทธิ์ในการตีความขั้นสุดท้ายของกิจกรรม ',
        noticeTip2: '(2) หากบริษัทของเราสงสัยว่าผู้ใช้ใช้ในทางที่ผิด พยายามที่จะละเมิด หรือใช้ประโยชน์จากผลประโยชน์ในทางที่ผิด เรามีสิทธิ์ที่จะยกเลิกรางวัลที่ได้รับจากบัญชีของผู้ใช้',
    },
    accountInfor: {
        //账户信息
        accountHome: 'หน้าแรกของบัญชี ',
        goldInGoldOut: 'ฝาก/ถอน ',
        accountData: 'ข้อมูลบัญชี ',
        accountDateTitle1: 'ยืนยันตัวตน ',
        accountDateTitle2: 'การจัดการบัญชี/บัตรธนาคาร ',
        accountDateTitle3: 'อีเมล ',
        accountDateTitle4: 'หมายเลขโทรศัพท์ ',
        accountDateTitle5: 'แก้ไขรหัสผ่าน ',
        nickname: 'ชื่อ ',
        realName: 'ชื่อจริง ',
        notCertified: 'ยังไม่ได้ยืนยัน ',
        underReview: 'กำลังตรวจสอบ ',
        modifyNickname: 'แก้ไขชื่อของคุณ ',
        inputNickname: 'กรุณาป้อนชื่อของคุณ ',
        Submit: 'ส่ง ',
        bind: 'ผูก ',
        identityTitle: 'คุณทำการยืนยันตัวตนเสร็จเรียบร้อยแล้ว สามารถเพลิดเพลินกับสิทธิประโยชน์โบนัสสูงสำหรับทำธุรกรรมการฝากเงิน ',
        bindBankTitle1: 'คุณได้ทำการผูกบัญชีธนาคาร/บัตรเรียบร้อยแล้ว หากคุณต้องการแก้ไข กรุณาคลิกปุ่มแก้ไขเพื่อแก้ไข ',
        bindBankTitle2: 'ชื่อธนาคาร ',
        bindBankTitle3: 'รหัสธนาคาร ',
        bindBankTitle4: 'ชื่อสาขา ',
        bindBankTitle5: 'หมายเลขบัญชีธนาคาร ',
        modifyUserHead: 'แก้ไขรูปโปร์ไฟล์ ',
        bindEmailTitle1: 'ปัจจุบันคุณยังไม่ได้ผูกกับอีเมล เราจะส่งโปรโมชั่นและข่าวสารสภาวะการตลาดรายสัปดาห์ให้คุณทางอีเมล ',
        bindEmailTitle2: 'ผูกอีเมล ',
        bindEmailTitle3: 'ผูกอีเมลสำเร็จ ',
        bindEmailTitle4: 'แก้ไขอีเมลสำเร็จ ',
        bindEmailTitle5: 'ผูกอีเมลเพื่อรักษาบัญชีของคุณให้ปลอดภัย และรับอีเมลจากบริการของเรา ',
        bindPhoneTitle1: 'ขณะนี้หมายเลขโทรศัพท์ของคุณไม่ได้ถูกผูกไว้ ทางเราจะส่งโปรโมชั่นและการแจ้งเตือนการบริการให้คุณด้วยทางข้อความ ',
        bindPhoneTitle2: 'ผูกหมายเลขโทรศัพท์เพื่อรักษาความปลอดภัยบัญชีของคุณ และรับการแจ้งเตือนจากบริการ SMS ของเรา ',
        bindPhoneTitle3: 'ผูกหมายเลขโทรศัพท์สำเร็จ ',
        bindPhoneTitle4: 'เเก้ไขหมายเลขโทรศัพท์สำเร็จ ',
        bindPhoneTitle5: 'ผูกหมายเลขโทรศัพท์ ',
        modifyPassTips1: 'ใส่รหัสผ่านใหม่อีกครั้ง',
        modifyPassTips2: 'ใหม่ 6-15 รหัสผ่านผสมตัวเลขและตัวอักษร',
        modifyPassTips3: 'กรุณาใส่รหัสผ่านใหม่อีกครั้ง',
        modifyPassTips4: 'รีเซ็ตรหัสผ่านสำเร็จ ',
    },
    footer: {
        navText1: 'เกี่ยวกับเรา ',
        navText2: 'ข้อตกลงลูกค้า ',
        navText3: 'การเปิดเผยความเสี่ยง ',
        navText4: 'ข้อจำกัดความรับผิดชอบ ',
        navText5: 'นโยบายความเป็นส่วนตัว ',
        navText6: 'ความปลอดภัยทางการเงิน ',
        navText7: 'ศูนย์การช่วยเหลือ ',
        copyright: 'ลิขสิทธิ์ © 2023 CM Trade สงวนลิขสิทธิ์ ห้ามตีพิมพ์ ',
        suText1: 'เปิดบัญชีฟรี ',
        suText2: 'ปรึกษาออนไลน์ ',
    },
    appH5: {
        regSuccessText1: 'ยินดีด้วย ลงทะเบียนสำเร็จแล้ว ',
        regSuccessText2: 'โบนัสการลงทะเบียนของคุณได้เข้าบัญชีแล้ว ',
        regSuccessText3: 'ฝากทันทีเพื่อรับ ',
        regSuccessText4: 'ยืนยันตัวตนให้สมบูรณ์เพื่อรับสิทธิ์เพิ่มเติม ',
    }   
}