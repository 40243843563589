export default {
    header: {
        login: '登录',
        Logout: '登出',
        register: '注册',
        appDownload: 'APP下载',
        headerDow: '下载APP领取赠金',
	    headerDowBtn: '下载',
    },
    loginRegister: {
        // 登录注册       
        loginLeftTitle1: '赠金福利',
        loginLeftTitle2: '最低领＄120，入金交易即返',
        loginRightTitle1: '您是否已有交易账户？',
        loginRightTitle2: '注册一个账户',
        loginRightTitle3: '手机验证码登录',
        loginRightTitle4: '忘记密码？',
        loginInput1: '交易账号 / 邮箱账号',
        loginInput2: '密码',
        regLeftTitle1: '简单几步，完成开户',
        regLeftTitle2: '0佣金体验低点差高效执行的交易平台',
        loginTitle1:'已注册 ？',
        loginTitle2:'登录',
        loginTitle3:'没有账号 ？',
        loginTitle4:'注册',
        loginTitle5:'邀请码（非必填）',
        loginTitle6:'我同意',
        retrievePassTitle1:'手机重置密码',
        retrievePassTitle2:'邮箱重置密码',
        mobileRegRightTitle1: '手机注册/登录',
        mobileRegRightTitle2: '未注册的手机将自动创建账户',
        moblieInput:'手机号',
        codeInput:'验证码',
        getCode:'获取',
        resend:'重新发送',
        regLogin:'注册/登录',
        regButtomTitle1:'或使用以下方式创建账户',
        regButtomTitle2:'点击注册/登录即代表您已同意',
        agreement:'《用户协议》',
        emailRegRightTitle1: '邮箱注册',
        countryInput:'选择居住国家/地区',
        emailInput:'邮箱账号',
        passInput:'6-15位字母数字组合密码',
        // 注册成功
        loginSuccessful:'注册成功',
        loginSuccTitle:'现在入金，可享高额美元赠金',
        loginSuccBtn1:'立刻入金领取',
        loginSuccBtn2:'完成身份认证',
        loginSuccEmail:'你的邮箱已注册，请前往登录',
        // 忘记密码
        retrievePassword:'找回密码',
        retrievePassTab1:'邮箱找回密码',
        retrievePassTab2:'手机号找回密码',
        resetPassword:'确认',
        resetPasswordTitle:'密码重置成功，请重新登录',
        returnLogin:'返回登录',
        checkPrompt1:'账号邮箱不能为空',
        checkPrompt2:'密码不能为空',
        checkPrompt3:'密码格式不对',
        checkPrompt4:'手机号不能为空',
        checkPrompt25:'请填写新的手机号',
        checkPrompt5:'手机号格式不对',
        checkPrompt6:'验证码不能为空',
        checkPrompt7:'请选择国家地区',
        checkPrompt27:'请选择银行名称',
        checkPrompt8:'邮箱不能为空',
        checkPrompt26:'请填写新的邮箱',
        checkPrompt9:'邮箱格式不对',
        checkPrompt10:'当前密码不能为空',
        checkPrompt11:'当前密码格式不对',
        checkPrompt12:'新密码不能为空',
        checkPrompt13:'新密码格式不对',
        checkPrompt14:'确认密码格式不对',
        checkPrompt15:'确认密码不能为空',
        checkPrompt16:'两次新密码不一致',
        checkPrompt17:'银行代码不能为空',
        checkPrompt18:'分行名称不能为空',
        checkPrompt19:'银行名称不能为空',
        checkPrompt20:'银行账号不能为空',
        checkPrompt21:'银行账单不能为空',
        checkPrompt22:'昵称不能为空',
        checkPrompt23:'身份证正面照片不能为空',
        checkPrompt24:'身份证反面照片不能为空',
    },
    perfectData: {
        // 完善资料
        perfectDataLeftTitle1:'激活真实账户',
        perfectDataLeftTitle2:'应监管要求，需完成身份认证方可进行出金操作',
        perfectData:'完善资料',
        step1Title1:'填写个人基础信息填写',
        identityAuthentication:'身份认证',
        step2Title1:'根据监管要求，你需完成以下认证：',
        step2Title2:'①身份验证',
        step2Title3:'②添加银行卡',
        step2Title4:'身份认证',
        step3Title1:'入金享赠金',
        step3Title2:'最高可获得USD2400赠金',
        step3Title3:'现在入金，领取丰厚赠金奖励',
        step3Title4:'居住国家',
        perfectDataTips:'我的现居国家/地区，国籍以及纳税国是相同的',
        nextStep:'下一步',
        //身份认证
        identityAuthenTitle1:'应监管要求，激活入金和交易功能需完成身份认证',
        identityAuthenTitle2:'点击上传身份证正面照',
        identityAuthenTitle3:'点击上传身份证反面照',
        // 个人信息确认
        informationConfirmTitle1:'个人信息确认',
        informationConfirmTitle2:'您将使用以下信息进行身份验证，请确保信息无误且与您本人身份证上所显示的一致。',
        informationConfirmTitle3:'完整姓名：',
        informationConfirmTitle4:'身份证号：',
        informationConfirmTitle5:'以上信息仅在隐私政策的范围内进行使用',
        modify:'修改',
        // 添加银行卡
        addBankTitle1:'添加银行账户/卡',
        addBankTitle2:'应监管要求，为了您的资金安全及顺利出金，请添加您本人银行账户/卡。',
        addBankTitle3:'请上传该银行帐户的帐户资讯或存折',
        addBankTitle4:'需包含：銀行名稱/Logo、賬戶持有人姓名以及完整的銀行賬號',
        addBankInput1:'请输入银行代码',
        addBankInput2:'请输入银行名称',
        addBankInput3:'请输入分行名称',
        addBankInput4:'请输入银行账号',
        addBankInput5:'银行代码',
        addBankInput6:'银行名称',
        addBankInput7:'分行名称',
        addBankInput8:'银行帐号',
        example:'例如',
        twBankName1:'国泰世华',
        twBankName2:'中国信托',
        twBankName3:'中华邮政',
        twBankData1:'国泰世华银行',
        twBankData2:'华山分行',
        thBankName1:'开泰银行',
        thBankName2:'泰京银行',
        thBankName3:'盘谷银行',
        thBankData1:'开泰银行',
        thBankData2:'曼谷办事处',
        toVerify:'去验证',
        authenticationSuccessful:'认证成功',
        popPrompt: '应监管要求，请继续完成身份认证，以免影响出金功能。',
        popPrompt1: '当前国家/地区暂无出金功能，详细请咨询客服',
        cancel: '取消',
        continue: '继续',
        goLogin: '去登录',
        determine: '确定',
        authenticationFail:'认证失败',
        authenticationFailTitle1:'很抱歉，您的身份验证失败，请重新上传',
        authenticationFailTitle2:'上传前请注意检查以下2点：',
        authenticationFailTitle3:'1.确认上传的是您本人有效身份证',
        authenticationFailTitle4:'2.证件上显示的信息清晰完整',
        returnModify: '返回修改',
        onlineConsultation: '在线咨询',
        immediateDeposit: '立即入金',
    },
    accountCenter: {
        // 首页
        homeTitle1: '个人资料',
        miniAccount: '迷你账户',
        standardAccount: '标准账户',
        highendAccount: '高端账户',
        homeTitle2: '推荐客户赚美金',
        homeTitle3: '每成功推荐1名好友交易',
        activityRules: '活动规则',
        viewRewards: '查看奖励',
        activityListTitle1: '您可获得',
        activityListTitle2: '您的好友可获得',
        activityListTitle3: '分享您的链接：',
        copyLink: '复制链接',
        copySucc: '复制成功',
        myRewards: '我的奖励',
        rewardTitle1: '奖励金额',
        rewardTitle2: '好友交易账户',
        rewardTitle3: '好友注册时间',
        rewardTitle4: '奖励状态',
        rewardState1: '已发放',
        rewardState2: '未达标',
        rewardState3: '待达标',
        accountNumber: '交易账户：',
        email: '电子邮箱：',
        phoneNumber: '手机号码：',
        residentialArea: '居住地区：',
        accountValue: '账户净值',
        creditGift: '信用赠金',
        activityReward: '活动奖励',
        availablePoints: '可用积分',
        deposit: '入金',
        accountTips1: '随持仓商品订单的盈亏而变化的实际余额（包括未平仓商品订单的盈亏）账户净值有时效性，实际净值请登录交易平台查看。',
        accountTips2: '参与赠金活动得到信用赠金的总和',
        accountTips3: '用户参与活动得到奖励赠金的总和',
        accountTips4: '可用于兑换赠金的积分余额',
        notice: '公告',
        // 入金页面
        withdraw: '出金',
        capitalFlow: '资金流水',
        tradeQuery: '交易查询',
        depositTite1: '账户入金',
        depositTite2: '入金金额',
        depositTite3: '预计支付金额',
        depositTite4: '赠金活动',
        depositTite5: '入金方式',
        depositTite6: '账户介绍',
        depositTite7: '资金安全',
        depositTite8: '入金须知',
        depositTite9: '支付银行',
        depositTite10: '请选择银行',
        depositTite11: '操作指引',
        depositTite12: '预计支付',
        depositTite13: '赠金需入金后联系客服报名活动领取!',
        depositTite14: '更多入金方式',
        depositTite15: '选择入金方式',
        depositTite16: '7-11 超商',
        depositTite17: '全家超商',
        depositTite18: '莱尔富超商',
        subDeposit: '提交入金',
        accountType: '账户类型',
        accountTypeTitle1: '最低入金金额',
        accountTypeTitle2: '最低点差',
        fundSecurityList1: '0手续费',
        fundSecurityList2: '即时到账',
        fundSecurityList3: '独立存管',
        depositTips1: '1.最低入金金额？',
        depositTips2: '2.入金汇率是按照什么标准换算的？',
        depositTips3: '3.入金需要开通美元账户吗？',
        depositTips4: '4.入金后多久到账？',
        depositTips10: '5.可以使用他人账户入金吗？',
        depositTips11: '6.入金后的资金存放在哪里？',
        depositTips01: '最低入金金额：',
        depositTips02: '支付金额按当天中间价汇率自动换算',
        depositTips03: '不需要，您可以使用本人银行卡、信用卡，通过网上银行或手机银行转账汇款等方式入金，会根据您提交的入金金额（USD），按照当时的汇率换算出您所需要支付的币种，支付成功后资金会自动存入您的交易账户。',
        depositTips04: '一般情况下，即时到账；若超过10分钟未到达交易账户，请您联系在线客服进行人工查询。',
        depositTips010: '不行，为了您的账户安全，不接受来自非本人银行账户的资金。',
        depositTips011: '独立存管，并以委托保管形式持有，以保障客户利益。',
        depositTips5: '5.更多关于入金详细说明及操作方式，请点击此处。',
        depositTips6: '1. 转账金额与申请入金金额必须一致',
        depositTips7: '2. 请勿向同一收款账户重复汇款',
        depositTips8: '下次不再提醒',
        depositTips9: '去转账',
        depositSucc: '入金成功',
        depositFail: '入金失败',
        depositTime: '入金时间',
        depositGuide: '入金指南',
        dollar: 'USD',
        singleLimit: '单笔限额',
        creditCardText1: '请输入信用卡信息',
        creditCardText2: '请输入信用卡号码',
        creditCardText3: '请输入有效的信用卡号码',
        creditCardText4: '有效日期（月/年）',
        creditCardText5: 'CVV （安全碼）',
        creditCardText6: '请输入信用卡有效期限',
        creditCardText7: '请输入正确 CVV 安全码',
        creditCardText8: '持卡人姓名',
        creditCardText9: '名字',
        creditCardText10: '姓氏',
        creditCardText11: '请输入您的名',
        creditCardText12: '请输入您的姓',
        creditCardText13: '* 此信用卡应属于您，并在您的名下使用。',
        creditCardText14: '下一步',
        // 入金转账页面
        onlineBankTitle1: '收款账户信息',
        onlineBankTitle2: '您需使用本人的银行账户进行汇款',
        onlineBankTitle2CSV: '您需使用本人的银行账户进行缴费',
        onlineBankTitle3: '请在',
        onlineBankTitle4: '内完成操作',
        onlineBankTitle5: '操作提示：',
        onlineBankTitle6: '1.将汇款金额、收款银行、银行代码、收款账号复制到您本人的手机银行/网上银行进行汇款操作，同行或跨行均可；',
        onlineBankTitle6CSV: '1.将缴费金额、缴费代码输入到收款超商缴费页面；',
        onlineBankTitle7: '2.请勿保存汇款信息进行重复汇款；',
        onlineBankTitle7CSV: '2.请勿保存缴费信息进行重复缴费；',
        onlineBankTitle8: '3.若转账金额与申请入金金额不一致，无法汇款成功；',
        onlineBankTitle9: '4.请在15分钟内完成汇款，否则将无法入金成功。',
        onlineBankTitle9CSV: '3.请在15分钟内完成缴费，否则将无法入金成功。',
        onlineBankTitle10: '资金于CM Trade独立存管，请放心支付。',
        copy: '复制',
        remittanceAmount: '汇款金额：',
        remittanceAmountCSV: '缴费金额：',
        collectionBank: '收款银行：',
        collectionBankCSV: '收款超商：',
        collectionAccount: '收款账号：',
        collectionAccountCSV: '缴费代码：',
        confirm: '确认',
        //出金页面
        withdrawTitle1: '账户出金',
        withdrawTitle2: '可取资金',
        withdrawTitle3: '出金金额',
        withdrawTitle4: '预计到账金额',
        withdrawTitle5: '账户姓名',
        withdrawTitle6: '到账银行账户',
        subWithdraw: '提交出金',
        applySucc: '申请成功',
        applyFail: '申请失败',
        applySuccStep1: '待审核',
        applySuccStep2: '银行受理',
        applySuccStep3: '出金完成',
        applySuccStep4: '预计一个工作日',
        cancelWithdraw: '取消出金申请',
        reminder: '温馨提示',
        reminder1: '温馨提示：',
        withdrawTips1: '1.若参与赠金活动，申请出金默认退出赠金活动，会扣除赠金及累计的交易手数将无法获得返利。',
        withdrawTips2: '2.因受部分银行受理情况影响，可能出现延迟到账，以实际到账时间为准。',
        withdrawTips3: '最低出金金额',
        withdrawTips4: '最高出金金额',
        withdrawTips5: '出金金額超出可取金额',
        withdrawTips6: '可取资金=可用预付款-信用-0.4*已用预付款（0.4*已用预付款为防爆仓比例）',
        understand: '了解',
        //资金流水
        capitalFlowTitle1: '类型：',
        capitalFlowTitle2: '状态：',
        capitalFlowTitle3: '时间：',
        timeSelect1:'近7天',
        timeSelect2:'本月',
        timeSelect3:'上个月',
        timeSelect4:'最近一年',
        orderNumber: '订单号',
        money: '金额',
        applyTime: '申请时间',
        state: '状态',
        type: '类型',
        home: '首页',
        lastPage: '尾页',
        previousPage: '上一页',
        nextPage: '下一页',
        Jump: '跳转',
        totalNumber: '总条数',
        pageCount: '总页数',
        depositFlow: '入金流水',
        withdrawFlow: '出金流水',
        completed: '已完成',
        notCompleted: '未完成',
        closed: '已关闭',
        untreated: '未处理',
        paymentTime:'支付时间',
        cancelWithdraw1:'取消申请',
        rewardTime:'奖励发放时间',
        rewardType:'奖励类型',
        //交易查询
        tradeHistory: '交易历史',
        pendingOrder: '挂单查询',
        holdOrder: '持仓查询',
        varieties: '品种',
        tradingVolume: '交易量',
        openingPrice: '开仓价',
        openingTime: '开仓时间',
        closingPrice: '平仓价',
        closingTime: '平仓时间',
        hangingOrderPrice: '挂单价',
        hangingOrderTime: '挂单时间',
        stopLoss: '止损',
        stopSurplus: '止盈',
        overnightInterest: '隔夜仓息',
        profit: '盈利',
        totalProfit: '总盈利：',
        totalVolume : '总交易量：',
        hand : '手',
        dataStatus1:'做多',
        dataStatus2:'做空',
        dataStatus3:'信用',
        dataStatus4:'做多止损',
        dataStatus5:'做多限价',
        dataStatus6:'做空止损',
        dataStatus7:'做空限价',
        dataStatus8:'最低入金金额',
        dataStatus9:'最高入金金額',
        dataStatus10:'积分兑换奖励',
        dataStatus11:'赠金返利',
        dataStatus12:'EIA竞猜奖励',
        dataStatus13:'非农竞猜奖励',
        dataStatus14:'暂无您的数据',
        //邀请活动规则
        inviteActivityTitle1:'1、邀请人参与说明',
        inviteActivityTitle2:'2、被邀请人说明',
        inviteActivityTitle3:'3、注意事项',
        inviteRule1:'（1）所有注册实盘账号的用户，都可分享活动成为邀请人；',
        inviteRule2:'（2）邀请人每邀请一位用户开设实盘账号，被邀请人在开户后30天内激活账户并交易满1标准手（虚拟货币除外的任意品种累计）即可获得15美金奖励；',
        inviteRule3:'（3）邀请人的邀请奖励将直接发放到参与邀请活动用户对应的实盘交易账号中，不可更换；',
        inviteRule4:'（4）邀请人每周邀请奖励最高可累计获得200美元，邀请人和被邀请人获取的奖励可直接用于提现或交易。',
        unInviteRule1:'（1）被邀请人须是未曾在我司开设过任何实盘交易账户；',
        unInviteRule2:'（2）被邀请人必须通过邀请人分享的推广QR Code/链接注册开户，在开户后30天内激活账户并交易满1标准手（虚拟货币除外的任意品种累计），可获得5美金赠金；',
        // unInviteRule3:'（3）被邀请人开户激活并交易满1标准手后，可获得5美金赠金（可提现可交易）。',
        noticeTip1:'（1）我司员工及其家属不适用此活动，销户重开账号不适用此活动，活动最终解释权归我司所有；',
        noticeTip2:'（2）如我司怀疑用户滥用、企图滥用、以及其他方式滥用优惠，有权取消用户账户所获奖励。',
    },
    accountInfor: {
         //账户信息
         accountHome: '账户首页',
         goldInGoldOut: '入金/出金',
         accountData: '账户信息',
         accountDateTitle1: '身份验证',
         accountDateTitle2: '银行账户/卡管理',
         accountDateTitle3: '电子邮箱',
         accountDateTitle4: '手机号码',
         accountDateTitle5: '修改密码',
         nickname: '昵称：',
         realName: '真实姓名：',
         notCertified: '未认证',
         underReview: '审核中',
         modifyNickname: '修改您的昵称',
         inputNickname: '请输入您的昵称',
         Submit: '提交',
         bind: '绑定',
         identityTitle: '您已完成身份验证，现入金交易享高额赠金福利哦~',
         bindBankTitle1: '您已完成银行账户/卡绑定，如需修改请点击修改按钮进行修改',
         bindBankTitle2: '银行名称',
         bindBankTitle3: '银行代码',
         bindBankTitle4: '分行名称',
         bindBankTitle5: '银行账户号码',
         modifyUserHead: '修改头像',
         bindEmailTitle1: '您当前未绑定电子邮箱，我们会通过邮件给您发送优惠活动和行情周刊。',
         bindEmailTitle2: '电子邮箱绑定',
         bindEmailTitle3: '电子邮箱绑定成功',
         bindEmailTitle4: '电子邮箱修改成功',
         bindEmailTitle5: '绑定电子邮箱以保证您账户安全， 以及接收我们的服务邮件',
         bindPhoneTitle1: '您当前未绑定手机号码，我们会通过短信给您发送优惠活动和服务通知',
         bindPhoneTitle2: '绑定手机号码以保证您账户安全， 以及接收我们的短信服务通知。',
         bindPhoneTitle3: '手机号码绑定成功',
         bindPhoneTitle4: '手机号码修改成功',
         bindPhoneTitle5: '手机号码绑定',
         modifyPassTips1: '输入当前密码',
         modifyPassTips2: '新的6-15位字母数字组合密码',
         modifyPassTips3: '再次输入新的密码',
         modifyPassTips4: '密码修改成功',
    },
    footer: {
        navText1:'关于我们',
        navText2:'客户协议',
        navText3:'风险披露',
        navText4:'免责声明',
        navText5:'隐私条款',
        navText6:'资金安全性',
        navText7:'帮助中心',
        copyright:'Copyright © 2023 CM Trade版权所有，不得转载',
        suText1:'免费开户',
        suText2:'在线咨询'
    },
    appH5: {
        regSuccessText1:'恭喜您，注册成功',
        regSuccessText2:'您的新手注册奖励金已到账',
        regSuccessText3:'立即入金领取',
        regSuccessText4:'完成身份认证 ，获取更多特权'
    }   
}